import { helper } from '@/modules/core';

const Filter = {
    install(Vue, options = {}) {
        const defaultOptions = {
            name: 'currency',
            thousandsDelimiter: ',',
            fractionDelimiter: '.',
            decimal: 2,
        };

        const config = { ...options, ...defaultOptions };

        Vue.filter(config.name, filterCurrency(config));
    },
};

function filterCurrency(config) {
    return function (value, format, decimal) {
        if (typeof value === 'string') {
            return value;
        }
        if (typeof value !== 'number') {
            return value;
        }

        const amount = helper.numberFormat(
            value,
            Number.isInteger(decimal) ? decimal : config.decimal,
            config.fractionDelimiter,
            config.thousandsDelimiter
        );

        if (typeof format !== 'string' && format.symbol) {
            const symbol = format.symbol;
            return { symbol, amount };
        }
        return format ? format.replace(/%s(.*?)/g, amount) : amount;
    };
}

export default Filter;
