export const AUTH_ERRORS = [1020, 'ACCOUNT_NOT_VERIFIED', 1021, 'WRONG_USERNAME_OR_PASSWORD'];
export const VERIFICATION_ERRORS = [1020, 'ACCOUNT_NOT_VERIFIED'];
export const TOKEN_ERRORS = [11100, 'TOKEN_NOT_PROVIDED'];
export const GLOBAL_ERRORS_REQUIRING_TIME_FORMATTING = [
    'GENERIC_ATTEMPTS_EXCEEDED',
    'LOGINS_ATTEMPTS_EXCEEDED',
    'PASSWORD_RESET_ATTEMPTS_EXCEEDED',
    'KYC_ATTEMPTS_EXCEEDED',
];

export const GLOBAL_ERRORS_REQUIRING_DATE_FORMATTING = ['ACCOUNT_SELF_EXCLUDED'];

export const GLOBAL_ERRORS_CASTING_PARAMETERS_TO_NUMBERS = ['AUTO_CASHOUT_ACCEPTABLE_AMOUNT_IS_OUT_OF_RANGE'];

export const GTM_EVENT_SERVER_ERROR_MESSAGES = {
    component_data: 'component_data_load_error',
    get_event_prices_by_id: 'event_prices_by_id_load_error',
    400: 'bad_request',
    500: 'internal_server_error',
};

export const ERRORS_404 = ['SHARING_BET_SLIP_NOT_FOUND', 'SHARING_UNKNOWN_SHARING_KEY', 'SHARING_UNKNOWN_BETSLIP'];

export const WRONG_CREDENTIALS_CODE = 'WRONG_USERNAME_OR_PASSWORD';
