import 'flag-icons/css/flag-icons.min.css';

import CountryFlag from '@/modules/platform/components/CountryFlag.vue';

const VuePlugin = {
    install(Vue) {
        if (VuePlugin.installed) return;
        VuePlugin.installed = true;
        Vue.component('CountryFlag', CountryFlag);
    },
};

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(VuePlugin);
}

export default VuePlugin;
