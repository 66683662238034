<template>
    <p class="casino-no-favourites">
        {{ $t('ui.casinoLobby.noSavedFavourites') }}<br />
        <strong>{{ $t('ui.casinoLobby.hereAreOtherGames') }}</strong>
    </p>
</template>

<script>
export default {
    name: 'CasinoNoFavourites',
};
</script>

<style scoped lang="scss">
.casino-no-favourites {
    text-align: center;
    padding: 0 12px;
    @extend %small-details-text;
}
</style>
