<template>
    <div class="account-menu-item-wrapper">
        <a
            :id="menuItem.id"
            :href="menuItem.url"
            :target="menuItem.target"
            :class="['account-menu-item', menuItem.style]"
            @click.prevent="$emit('menuItemClicked')"
        >
            <div>
                <SvgIcon v-if="menuItem.icon" class="icon-account-menu" :icon-id="menuItem.icon" />
                <span :style="{ ...(menuItem.linkColor && { color: menuItem.linkColor }) }">{{ menuItem.name }}</span>
                <Badge
                    v-if="menuItem.badge && menuItem.badgeType === badgeType.NEW"
                    :text="menuItem.badge"
                    :type="menuItem.badgeType"
                    :bg-color="menuItem.badgeColor"
                    class="menu-badge"
                />
            </div>
            <div v-if="menuItem.curFlagIcon && menuItem.nextFlagIcon" class="lang-flag-icons">
                <SvgIcon :icon-id="`${menuItem.nextFlagIcon}`" class="icon-menu-flag icon-size-small" />
                <SvgIcon :icon-id="`${menuItem.curFlagIcon}`" class="icon-menu-flag icon-size-small" />
            </div>
            <Badge
                v-else-if="menuItem.badge && menuItem.badgeType === badgeType.NEW_MESSAGES"
                :text="menuItem.badge"
                :type="menuItem.badgeType"
                :bg-color="menuItem.badgeColor"
                mode="circle"
                class="menu-badge circle-badge"
            />
            <div v-else :class="['icon-wrapper', { hidden: menuItem.hideArrow }]">
                <SvgIcon icon-id="arrow_right" class="icon-size-medium icon-arrow-right" />
            </div>
        </a>
    </div>
</template>

<script>
import { badgeType } from '@/modules/core/badge-type-const';
const Badge = () => import('@/components/Badge.vue');

export default {
    name: 'MyAccountMenuItem',
    components: { Badge },
    props: {
        menuItem: {
            type: Object,
        },
    },
    data() {
        return {
            badgeType,
        };
    },
};
</script>

<style lang="scss" scoped>
@import 'my-account.mixin';

.account-menu-item-wrapper {
    @extend %menu-item-border-radius;
    background: $white-bg;
    &:not(:last-child) {
        border-bottom: 2px solid $light-grey;
    }
}
.account-menu {
    &-item {
        @extend %my-account-menu-item;
        height: 42px;

        ::v-deep .type-new .badge-text {
            @extend %small-details-font-400;
            vertical-align: unset;
            font-style: normal;
            &::after {
                content: unset;
            }
        }

        .menu-badge {
            margin: 0 0 0 8px;
            border-radius: 2px;
            padding: 2px 6px;
        }

        .circle-badge {
            border-radius: 100%;
            margin: 0;
            color: $white-bg;
            display: flex;
            align-items: center;
        }

        .lang-flag-icons {
            display: flex;
            column-gap: 8px;
        }

        .icon-wrapper {
            width: 13px;
        }
        .icon-arrow-right {
            fill: $main-text;
        }
        .icon-menu-flag {
            border-radius: 100%;
            position: relative;
            &:first-child {
                opacity: 0.3;
            }
        }
        span {
            vertical-align: middle;
            color: $main-text;
        }

        .icon-account-menu {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            fill: $dark-grey-2;
        }
    }
}
</style>
