import { helper } from '@/modules/core';
import { formatKeys } from '@/modules/platform/store/utils/formatKeys';

/**
 * @typedef {Object} TOCItem
 * @property {String} id
 * @property {String} href
 * @property {Number} level
 * @property {String} title
 * @property {String} parentId
 */

/**
 * updates RichText content to work with TOC
 *
 *
 * @param {String} content - RichText content
 * @param {Boolean} parentIndex - RichText component index on the page (needed for uniqueness of the generated id in the presence of identical headings in different RichText on the page)
 * @param {String[]} parents - contains id of parent elements for levels (the index of an element in an array is equal to level - 1, for example, the 0th element of the array contains the id of the parent for the 1st nesting level)
 * @returns { {content: String, tocArr: TOCItem[] , parentsMap: String[]} } array of integers (represented by string or digits, depends on the convertToNumbers parameter)
 */
const updateContentForToc = (content, parentIndex, parents) => {
    const richTextContent = JSON.parse(content);

    const parentsMap = parents || [];
    const tocArr = [];
    const findParentId = ({ lvl, parentsMap }) => {
        const parentsArr = parentsMap.filter((i, index) => index + 1 < lvl && i);
        return parentsArr[parentsArr.length - 1];
    };

    richTextContent.content.forEach((i, index) => {
        if (i.type === 'heading') {
            const title = (i.content || []).reduce((acc, val) => {
                return acc + val.text;
            }, '');

            if (title) {
                const idTitle = title.replace(/\s+/g, '_');
                const id = `id-${parentIndex}-${index}-${idTitle}`.replace(/[^\w|-]/g, ''); // need to start from letter (document.queryselector() not allow selectors started from numbers https://stackoverflow.com/a/20306237)
                i.attrs.id = id;

                tocArr.push({
                    id,
                    href: `#${id}`,
                    level: i.attrs.level,
                    title,
                    parentId: findParentId({ lvl: i.attrs.level, parentsMap }),
                });

                parentsMap[i.attrs.level - 1] = id;
                if (parentsMap.length > i.attrs.level) {
                    parentsMap.splice(i.attrs.level);
                }
            }
        }
    });

    return { content: JSON.stringify(richTextContent), tocArr, parentsMap };
};

/**
/**
 * Converts a flat list of nodes into a tree structure.
 *
 * Each node is expected to have an `id` and an optional `parentId`.
 * Nodes with a `parentId` will be nested under their parent node.
 *
 * @param {Object[]} list - The flat list of nodes to convert.
 * @param {Number|String} list[].id - The unique identifier for the node.
 * @param {Number|String} [list[].parentId] - The identifier of the parent node (if any).
 * @returns {Object[]} - An array of root nodes, each containing their children.
 *
 */
export const listToTree = (list) => {
    const map = {};
    let node;
    const roots = [];
    let i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].children = [];
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== undefined) {
            list[map[node.parentId]].children.push(node);
        } else {
            roots.push(node);
        }
    }

    return roots;
};

export const updateStrapiContent = (page) => {
    const hasToc = page.content.some(({ __component, isTocEnabled }) => __component === 'content.toc' && isTocEnabled);

    let tocData = [];
    let tocParentsMap = [];

    const updatedContent = page.content.map((component, index) => {
        const formattedComponent = formatKeys(component);
        const { __component, visibility } = formattedComponent;
        const type = __component
            .slice(__component.lastIndexOf('.') + 1)
            .replace(/[^a-zA-Z ]/g, ' ')
            .split(' ')
            .map((name) => helper.capitalize(name))
            .join('');

        if (hasToc && __component.includes('rich-text') && formattedComponent.content) {
            const { content: newContent, tocArr, parentsMap } = updateContentForToc(formattedComponent.content, index, tocParentsMap);

            tocData.push(...tocArr);
            tocParentsMap = parentsMap;
            formattedComponent.content = newContent;
        }

        return { content: formattedComponent, type, visibility };
    });

    const tocTree = listToTree(tocData);

    return { content: updatedContent, tocTree };
};
