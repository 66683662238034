<template>
    <span>
        <span class="fee-label" @click.prevent="onFeeLabelClick">
            <renderer :input="feeInfo.feeAmount" />
            <span class="info">
                <SvgIcon class="icon-size-small" icon-id="icon-info" vertical-align="text-top" />
            </span>
        </span>
        <Modal class="fee-info" name="fee-info" width="100%" :avoid-collision-with-footer="true" @close="$modal.hide(`fee-info`)">
            <renderer :input="feeInfo.modalText" />
        </Modal>
    </span>
</template>

<script>
import { mapState } from 'vuex';
import { helper } from '@/modules/core';

const FEE_IN_PERCENT = 'PERCENTAGE';

export default {
    name: 'DepositFeeLabel',
    props: {
        providerName: {
            type: String,
        },
        compensationAmount: {
            type: Number,
        },
        providerChargeableFee: {
            type: Object,
        },
    },
    computed: {
        ...mapState({
            currencyFormat: (state) => state.platform.settings.currency.format,
            currencySymbol: (state) => state.platform.settings.currency.symbol,
        }),
        formattedCompensationAmount() {
            return this.$options.filters.currency(this.compensationAmount, this.currencyFormat, 0);
        },
        fee() {
            const { chargeableFeeStructure, chargeableFeeAmount } = this.providerChargeableFee;
            return chargeableFeeStructure === FEE_IN_PERCENT
                ? `${helper.rounded(chargeableFeeAmount * 100, 2, Math.round)}%`
                : chargeableFeeAmount;
        },
        feeInfo() {
            const feeAmount = this.providerName
                ? this.$t('ui.payment.deposit.chargeableFee.noFee', { amount: this.formattedCompensationAmount })
                : this.$t('ui.payment.deposit.chargeableFee.fee', { fee: this.fee });
            const modalText = this.providerName
                ? this.$t('ui.payment.deposit.chargeableFee.compensationInfo', {
                      providerName: this.providerName,
                      amount: this.formattedCompensationAmount,
                  })
                : this.$t('ui.payment.deposit.chargeableFee.feeInformation', { fee: this.fee, currency: this.currencySymbol });
            return { feeAmount, modalText };
        },
    },
    methods: {
        onFeeLabelClick() {
            this.$modal.show('fee-info');
            this.$gtm.query({ event: 'compensation_bonus_info_click' });
        },
    },
};
</script>

<style lang="scss" scoped>
.fee {
    &-label {
        cursor: pointer;
    }
    &-info {
        bottom: 0;
    }
}
.info {
    vertical-align: middle;
    margin-left: 5px;
}
</style>
