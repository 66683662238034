import { render, staticRenderFns } from "./BetlineList.vue?vue&type=template&id=72f1bb91&scoped=true"
import script from "./BetlineList.vue?vue&type=script&lang=js"
export * from "./BetlineList.vue?vue&type=script&lang=js"
import style0 from "./BetlineList.vue?vue&type=style&index=0&id=72f1bb91&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f1bb91",
  null
  
)

export default component.exports