<template>
    <div v-if="isVisible" class="notifications">
        <div
            v-for="(notification, index) in notifications"
            :key="`${index}-${notification.type}`"
            data-test-class="notification"
            :class="[notification.status]"
            class="notify"
        >
            <div v-if="notification.type === type.PAYMENT" class="section">
                <div data-test-id="paymentDepositSuccess">
                    <renderer
                        :input="
                            $t('ui.notifications.payment.deposit.success', {
                                amount: $options.filters.currency(
                                    Number(notification.data.GrossAmount) || Number(notification.data.Amount),
                                    currencyFormat
                                ),
                            })
                        "
                    />
                </div>
            </div>
            <div v-else-if="notification.type === type.TERMS" class="section">
                <div data-test-id="termsAccepted">
                    <renderer :input="$t('ui.notifications.terms')" />
                    <renderer
                        v-if="paymentProviderMigrationData.payBillNumber"
                        :input="$t('ui.termsAndConditions.newPayBillText', { payBillNumber: paymentProviderMigrationData.payBillNumber })"
                    />
                </div>
            </div>
            <div v-else-if="notification.type === type.RESET" class="section">
                <div data-test-id="pinResetCompleted">
                    <renderer :input="$t(`ui.notifications.reset.${pinOrPassword}`)" />
                </div>
            </div>
            <div v-else class="section">
                <div :data-test-id="notification.data.id">
                    <renderer :input="notification.data.message" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { mutation } from '@/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as paymentGetter } from '@/modules/payment/store/const';
import { getter as translationsGetter } from '@/store/modules/translations/const';

export default {
    name: 'Notifications',
    data() {
        return {
            type: {
                PAYMENT: 'payment',
                TERMS: 'terms',
                VERIFY: 'verify',
                RESET: 'reset',
            },
        };
    },
    computed: {
        ...mapState({
            notifications(state) {
                return state.notification.notifications.filter(({ type }) => this.types.includes(type));
            },
            notificationTrigger: (state) => state.notification.trigger,
            settings: (state) => state.platform.settings,
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
        types() {
            return Object.entries(this.type).map(([, type]) => type);
        },
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            paymentProviderMigrationData: paymentGetter.GET_PAYMENT_PROVIDER_MIGRATION_DATA,
            pinOrPassword: translationsGetter.PIN_OR_PASSWORD,
        }),
        isVisible() {
            const isExcludedRoute = this.notifications.some((notification) =>
                notification.excludedRoutes.find((route) => this.$route.path.indexOf(route) >= 0)
            );
            return this.notifications.length > 0 && !isExcludedRoute;
        },
    },
    watch: {
        isAuthenticated(isAuthenticated) {
            if (!isAuthenticated) {
                this.removeNotifications();
            }
        },
    },
    created() {
        // SFE doesn't listen $route change when child component has requests
        // Application's trigger routeChanged in afterEach hook
        this.$root.$on('routeChanged', () => {
            if (!this.notificationTrigger) {
                this.removeNotifications();
            } else {
                this.resetNotificationTrigger();
            }
        });
    },
    methods: {
        ...mapMutations({
            removeNotifications: mutation.CLEAR_NOTIFICATIONS,
            resetNotificationTrigger: mutation.TOGGLE_NOTIFICATION,
        }),
    },
};
</script>

<style lang="scss" scoped>
.notifications {
    margin: 10px;
    width: auto;

    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>
