<template>
    <ImageIcon v-if="imgSrc || imgAltSrc" :src="imgSrc" :alt-src="imgAltSrc" />
    <span v-else class="fi" :class="flagIconClass" :title="title || isoCode" />
</template>

<script>
export default {
    name: 'CountryFlag',
    props: {
        isoCode: { type: String, default: 'xx' },
        title: { type: String, default: null },
        isSquared: { type: Boolean, default: false },
        imgSrc: {
            type: String,
            required: false,
        },
        imgAltSrc: {
            type: String,
            required: false,
        },
    },
    computed: {
        flagIconClass() {
            return `fi-${this.isoCode.toLowerCase()} ${this.isSquared ? 'fis ' : ''}`;
        },
    },
};
</script>
