<template>
    <div data-test-id="casinoCollectionTitle" class="title-container pointer" @click="$emit('casino-title-click')">
        <h2 v-if="title">
            <SvgIcon v-if="iconId" class="icon-size-small" :icon-id="iconId" />
            <div>{{ title }}</div>
        </h2>
        <div v-if="showViewAll || gamesCount > gamesLimit">
            <span class="pointer more-title">
                <span>{{ showViewAll ? $t('ui.casinoLobby.viewAll') : $t('ui.casinoLobby.view') }}</span>
                <span class="bold">
                    {{ showViewAll ? ` ${allGamesCount} ` : ` ${gamesCount} ` }}
                    <SvgIcon icon-id="arrow_right" class="icon-size-very-small" vertical-align="baseline" />
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getter as casinoGetter } from '@/store/modules/casino/const';

export default {
    name: 'CasinoCollectionTitle',
    props: {
        gamesCount: Number,
        gamesLimit: Number,
        showViewAll: Boolean,
        title: String,
        iconId: String,
    },
    computed: {
        ...mapGetters({
            games: casinoGetter.GET_GAMES,
            allGamesCount: casinoGetter.GET_GAMES_COUNT,
        }),
    },
};
</script>

<style scoped lang="scss">
.more-title {
    @extend %body-normal-font-400;
}

.title-container {
    display: flex;
    flex-direction: row;

    h2 {
        flex: 1;
        @extend %body-big-font-700;
        align-items: center;
        display: flex;
        flex-direction: row;
        margin: 0;

        svg {
            margin-right: 4px;
        }
    }
}
</style>
