<template>
    <div v-if="matchId">
        <SRWidget
            v-if="!isLive && isStatisticOpen"
            name="headToHead.standalone"
            :match-id="matchId"
            :theme="widgetTheme"
            :options="h2hOptions"
        />
        <template v-if="isLive">
            <SRWidget
                v-show="!isStatisticOpen && !isLMTError"
                name="match.lmtCompact"
                :theme="widgetTheme"
                :match-id="matchId"
                :options="lmtOptions"
                class="pointer"
                @click.native="onLMTClick"
            />
            <SRWidget
                v-show="!isLMTError || (isLMTError && isStatisticOpen)"
                name="match.lmtPlus"
                :theme="widgetTheme"
                :match-id="matchId"
                :options="lmtOptions"
                @onFooterClick="onLMTClick"
            />
        </template>
        <div v-if="isStatisticOpen" class="disclaimer">
            {{ $t('ui.eventPage.disclaimer.sportsRadar') }}
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { mutation as sportMutation } from '@/modules/sport/store/const';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { LOGO_IMAGES } from '@/components/content/content-const';

const themType = {
    LIGHT: 'light',
    DARK: 'dark',
};

export default {
    name: 'SportRadar',
    props: {
        matchId: {
            type: Number,
        },
        isLive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLMTError: false,
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            country: platformGetter.GET_COUNTRY,
        }),
        ...mapState({
            isStatisticOpen: (state) => state.sport.isStatisticOpen,
        }),
        widgetTheme() {
            return themType.DARK;
        },
        h2hOptions() {
            return {
                theme: this.widgetTheme,
                layout: 'inline',
            };
        },
        lmtOptions() {
            return {
                branding: {
                    tabs: {
                        variant: 'standard',
                    },
                },
                layout: 'double',
                scoreboard: 'extended',
                detailedScoreboard: 'momentum',
                momentum: 'compact',
                collapseTo: 'scoreboard',
                customBrandColor: '#8DC63F',
                pitchLogo: LOGO_IMAGES.logoDark,
                // goalBannerImage - SIDELINE LOGO
                goalBannerImage: LOGO_IMAGES.logoWhiteGreen,
                // logo - PANELS LOGO
                theme: this.widgetTheme,
                logo: this.widgetTheme === themType.LIGHT ? [LOGO_IMAGES.logoBlackGreen] : [LOGO_IMAGES.logoWhiteGreen],
                expanded: this.isStatisticOpen,
                onTrack: this.onLMTTrack,
            };
        },
    },
    methods: {
        ...mapMutations({
            toggleStatistic: sportMutation.TOGGLE_STATISTIC,
        }),
        onLMTTrack: (param1, param2) => {
            this.isLMTError = !!param2.error;
        },
        onLMTClick() {
            this.$gtm.query({
                event: 'sport_radar_live_match_tracker_click',
                status: this.isStatisticOpen ? 'Closed' : 'Open',
                jurisdiction: this.country,
                isAuthenticated: this.isAuthenticated,
            });

            this.toggleStatistic();
        },
    },
};
</script>
