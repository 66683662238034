<template>
    <div>
        <slot />
        <div class="casino-empty-list-container">
            <CasinoCollectionSlider :games="filteredGames" :games-count="gamesCount" title="" :column-count="columnCount" :rows-count="1" />
        </div>
    </div>
</template>

<script>
import { getter as casinoGetter } from '@/store/modules/casino/const';
import { mapGetters } from 'vuex';
import CasinoCollectionSlider from '@/modules/casino/strapiComponents/components/CasinoCollectionSlider.vue';
import { getCasinoGamesLimitForGridPerRow, sortGamesByPopular } from '@/modules/casino/utils/CasinoCollection-utils';
import { getter as platformGetter } from '@/modules/platform/store/const';

export default {
    name: 'CasinoEmptyList',
    components: { CasinoCollectionSlider },
    props: {
        data: Object,
    },
    computed: {
        ...mapGetters({
            games: casinoGetter.GET_GAMES,
            sideBarDisabledPages: platformGetter.GET_SIDE_BAR_DISABLED_PAGES,
        }),
        isRightSideBarHidden() {
            return this.sideBarDisabledPages.includes(this.$route.path);
        },
        filteredGames() {
            return [...this.games.data].sort(sortGamesByPopular).slice(0, this.gamesLimit);
        },
        gamesCount() {
            return this.games.data.length;
        },
        columnCount() {
            return getCasinoGamesLimitForGridPerRow(this.$mq, this.isRightSideBarHidden);
        },
        gamesLimit() {
            return this.columnCount * 3; // 3 slides
        },
    },
};
</script>

<style scoped lang="scss">
.casino-empty-list-container {
    margin-left: -12px;
    margin-right: -12px;
}
</style>
