export const category = {
    ALL: 'all',
    POPULAR: 'popular',
    GOALS: 'goals',
    PLAYERS: 'players',
    HALVES: 'halves',
    COMBOS: 'combos',
    BOOKINGS: 'bookings',
    CORNERS: 'corners',
    SPECIALS: 'specials',
    POINTS: 'points',
    QUARTERS: 'quarters',
    SETS: 'sets',
    GAMES: 'games',
};

export const EXCLUDED_MARKET_NAMES = ['Specials', 'Others'];

export const DEFAULT_EVENT_QUERY_AMOUNT = 20;
export const MAX_LIVE_EVENT_QUERY_AMOUNT = 100;
export const LIVE_EVENT_TYPE = 'LIVE';
