<template>
    <ol class="toc-tree">
        <li v-for="item in toc" :key="item.id" class="toc-tree-item" :class="getClassName">
            <div>
                <a class="underline" :href="item.href">{{ item.title }}</a>

                <TocTree v-if="item.children && item.children.length" :toc="item.children" :level="level + 1" :is-flat="isFlat" />
            </div>
        </li>
    </ol>
</template>

<script>
export default {
    name: 'TocTree',
    props: {
        toc: {
            type: Array,
            default: () => [],
        },
        level: {
            type: Number,
            default: 1,
        },
        isFlat: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getClassName() {
            return `toc-tree-item-level-${this.level > 3 ? 'basic' : this.level}${this.isFlat ? ' toc-tree-item-flat' : ''}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.toc-tree {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;

    &-item {
        font-weight: bold;
        margin: 6px 0;

        &:before {
            content: counters(item, '.') '. ';
            display: list-item;
            padding-right: 0.4em;
        }

        &-level {
            &-basic {
                @extend %body-normal-text;
            }
            &-1 {
                @extend %h2;
            }
            &-2 {
                @extend %body-big-text;
            }
            &-3 {
                @extend %body-normal-text;
            }
        }

        &-flat {
            @extend %body-big-text;
            color: $green;
            font-weight: normal;

            &:before {
                content: none;
            }
        }
    }

    li > & {
        margin: 0 0 0 1.5em;
    }

    > li {
        counter-increment: item;
        margin-bottom: 0.2em;
        display: flex;
    }
}
</style>
