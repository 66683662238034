<template>
    <div v-if="cutPlusState !== CUT_PLUS_STATES.NONE" class="cut-plus-block">
        <div class="cut-plus-header">
            <SvgIcon class="cut-plus-icon" icon-id="cut-plus" />
        </div>
        <div class="cut-plus-body" :class="cutPlusState">
            <template v-if="cutPlusState === CUT_PLUS_STATES.ACTIVE && !stake && !cutPlusInfo">
                <span>
                    {{ $t('ui.cutPlus.cutPlusActiveWithoutStake') }}
                    <a data-test-id="cutPlusLearnMore" @click="openCutPlusModal">{{ $t('ui.cutPlus.findOutMore') }}</a>
                </span>
            </template>
            <template v-else-if="cutPlusState === CUT_PLUS_STATES.ACTIVE">
                <div v-for="offer in cutPlusOffers" :key="offer.misses" class="cut-plus-offer">
                    <span class="payout">{{ $t('ui.cutPlus.payout', { count: offerCount(offer) }) }}</span>
                    <Currency class="value" :amount="offerAmount(offer)" :format="currencyFormat" />
                </div>
            </template>
            <template v-else-if="cutPlusState === CUT_PLUS_STATES.INACTIVE">
                <span>
                    {{ $t('ui.cutPlus.inactive') }}
                    <a data-test-id="cutPlusLearnMore" @click="openCutPlusModal">{{ $t('ui.cutPlus.findOutMore') }}</a>
                </span>
            </template>
            <template v-else-if="cutPlusState === CUT_PLUS_STATES.FALLBACK">
                <span>{{ $t('ui.cutPlus.fallback') }}</span>
            </template>
        </div>
        <CutPlusModal />
    </div>
</template>

<script>
import { getter as platformGetter } from '@/modules/platform/store/const';
import { mapGetters, mapState } from 'vuex';
import { getter as betslipGetter } from '@/modules/sport/store/modules/betslip/const';
import Currency from '@/modules/core/components/molecules/Currency.vue';
import CutPlusModal from '@/modules/sport/components/Fragments/CutPlusModal.vue';
import { betStatus } from '@/modules/sport';

const CUT_PLUS_STATES = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    FALLBACK: 'fallback',
    NONE: 'none',
};

export default {
    name: 'CutPlusInfoBlock',
    components: { CutPlusModal, Currency },
    props: {
        betType: {
            type: String,
        },
        cutPlusInfo: {
            type: [Object, null],
            default: null,
        },
        betslipStatus: {
            type: [String, null],
            default: null,
        },
    },
    data() {
        return {
            CUT_PLUS_STATES,
        };
    },
    computed: {
        ...mapState({
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
        ...mapGetters({
            stake: betslipGetter.GET_STAKE,
            cutPlusEnabled: platformGetter.CUT_PLUS_ENABLED,
            cutPlusConfigurationsEnabled: platformGetter.CUT_PLUS_CONFIGURATIONS_ENABLED,
            cutPlusCalculation: betslipGetter.CUT_PLUS_CALCULATION,
        }),

        cutPlusState() {
            const isActiveAfterBetting = this.cutPlusInfo && this.betslipStatus === betStatus.PENDING;
            const isActive =
                this.cutPlusConfigurationsEnabled[this.betType] &&
                this.cutPlusEnabled &&
                this.cutPlusCalculation &&
                this.cutPlusCalculation.allOffers.length > 0;

            const isInActive = this.cutPlusConfigurationsEnabled[this.betType] && this.cutPlusEnabled && !this.betslipStatus;
            const isFallback = this.cutPlusConfigurationsEnabled[this.betType] && !this.cutPlusEnabled && !this.betslipStatus;

            if (isActive || isActiveAfterBetting) {
                return CUT_PLUS_STATES.ACTIVE;
            } else if (isInActive) {
                return CUT_PLUS_STATES.INACTIVE;
            } else if (isFallback) {
                return CUT_PLUS_STATES.FALLBACK;
            } else {
                return CUT_PLUS_STATES.NONE;
            }
        },

        cutPlusOffers() {
            if (this.cutPlusInfo) {
                return this.cutPlusInfo.generatedCuts;
            } else if (this.cutPlusCalculation) {
                const preferred = this.cutPlusCalculation.allOffers.find((o) => o.preferred);
                return preferred ? preferred.amountForEachCut : [];
            } else {
                return [];
            }
        },
    },
    methods: {
        openCutPlusModal() {
            if (this.$mq.mdUp) {
                this.$router.push('/cut-plus');
            } else {
                this.$modal.show('cut-plus-modal');
            }
        },
        offerCount(offer) {
            return this.cutPlusInfo ? offer.miss : offer.misses;
        },
        offerAmount(offer) {
            return this.cutPlusInfo ? offer.gross : offer.offeredAmount;
        },
    },
};
</script>

<style scoped lang="scss">
.cut-plus-block {
    background: white;
    border: $cut-plus-border solid 1px;
    padding: 10px 12px;
    font-size: $body-normal-font-size;

    .cut-plus-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 16px;
        margin-bottom: 12px;

        .cut-plus-icon {
            height: 16px;
            width: 39px;
            margin-right: 4px;
        }
    }
    .cut-plus-body {
        display: flex;

        span {
            text-align: left;
        }

        &.active {
            flex-direction: column;

            .cut-plus-offer {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
                padding: 0 50px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        a {
            font-weight: 500;
            text-decoration-line: underline;
        }
    }
}
</style>
