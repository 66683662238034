<template>
    <div :class="['radio-item', { disabled }]">
        <RadioButton :id="radioId" :checked="checked" :disabled="disabled" />
        <span class="radio-item-label">{{ label }}</span>
        <slot name="extra"></slot>
    </div>
</template>

<script>
import RadioButton from '@/components/RadioButton.vue';

export default {
    name: 'EventRadioItem',
    components: { RadioButton },
    props: {
        radioId: {
            type: String,
            default: 'radio',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'radio',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.radio-item {
    display: flex;
    padding: 11px 12px;
    border-top: 1px solid $light-grey;
    cursor: pointer;

    &.disabled {
        .radio-item-label {
            color: $neutral-medium;
        }
    }

    ::v-deep {
        .radio-button-label {
            vertical-align: top;
        }
    }

    &-label {
        @extend %body-normal-font-400;
        flex-grow: 1;

        &.align-right {
            flex-grow: 0;
        }
    }
}
</style>
