<template>
    <div class="jackpots-list">
        <Spinner v-if="!bets" :visible="true" class="align-center inset viewport-padding" />
        <div v-else-if="bets.length === 0" class="no-data-text">
            <EmptyBets :tab-name="tabType.JACKPOT" />
        </div>
        <template v-else>
            <div
                v-for="bet in bets"
                :key="bet.id"
                class="bet"
                :data-test-id="`bet-jackpot-${bet.id}`"
                data-test-class="bet-jackpot"
                @click="openJackpotTicket(bet)"
            >
                <div class="bet-line bet-header">
                    <div class="header-container">
                        <div>
                            <span class="time">{{ formatTime(bet.purchaseDate) }}, </span>
                            <span class="date">{{ formatDate(bet.purchaseDate) }}</span>
                        </div>
                        <div class="id" data-test-id="bet-id">{{ $t('ui.myBets.id').toUpperCase() }}: #{{ bet.id }}</div>
                    </div>
                </div>
                <div class="bet-line status-wrapper">
                    <div class="result-container">
                        <span class="result-text">{{ bet.pool.name }} </span>
                        <div class="status">
                            <span v-if="bet.isWinner" class="positive status-text">
                                {{ $t('ui.myBets.won').toUpperCase() }}
                                <Badge class="badge-result" type="win" mode="medium" />
                            </span>
                            <span v-else-if="bet.isOngoing" class="neutral status-text">
                                {{ $t('ui.myBets.pending').toUpperCase() }}
                                <Badge class="badge-result" type="pending" mode="medium" />
                            </span>
                            <span v-else class="negative status-text">
                                {{ $t('ui.myBets.lost').toUpperCase() }}
                                <Badge class="badge-result" type="lose" mode="medium" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="bet-status-line" />
                <div class="bet-line">
                    <div class="bet-detail">
                        <span class="label">{{ $t('ui.myBets.stake').toUpperCase() }}</span>
                        <Currency class="value" :amount="bet.rows * bet.pool.fee" />
                    </div>
                    <div class="bet-detail">
                        <span class="label">{{ $t('ui.myBets.tickets').toUpperCase() }}</span>
                        <span class="value">{{ formatNumber(bet.rows, 0) }}</span>
                    </div>

                    <div v-if="bet.isWinner" class="bet-detail success end">
                        <span class="label">{{ $t('ui.myBets.jackpot').toUpperCase() }}</span>
                        <div class="currency-container">
                            <Currency class="value" :amount="bet.payoutDetails.payout" :format="currencyFormat" />
                            <span v-if="bet.payoutDetails.tax" class="value">&nbsp;*</span>
                        </div>
                    </div>
                    <div v-else-if="bet.isOngoing" class="bet-detail pending end">
                        <span class="label">{{ $t('ui.myBets.jackpot').toUpperCase() }}</span>
                        <Currency class="value" :amount="bet.pool.maxPrize" :format="currencyFormat" />
                    </div>
                    <div v-else class="bet-detail end">
                        <div>
                            <span class="label">{{ $t('ui.myBets.hit').toUpperCase() }}: </span>
                            <span class="value positive">{{ bet.payoutDetails.hitCountMax }}</span>
                        </div>
                        <div>
                            <span class="label">{{ $t('ui.myBets.miss').toUpperCase() }}: </span>
                            <span class="value negative">{{ bet.pool.rowCount - bet.payoutDetails.hitCountMax }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <p v-if="taxEnabled" class="tax-message">{{ $t('ui.myBets.taxEnabled') }}</p>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Spinner, Currency } from '@/modules/core/components';
import { helper } from '@/modules/core';
import { getter as sportGetter } from '@/modules/sport/store/const';
import { myBetsType } from '@/modules/sport';

import { getter } from '@/modules/platform/store/const';
import { routeName } from '@/router/const-name';
import Badge from '@/components/Badge.vue';
import EmptyBets from '@/modules/sport/components/Fragments/EmptyBets.vue';

export default {
    name: 'JackpotBet',
    components: { EmptyBets, Badge, Spinner, Currency },
    props: {
        hasMore: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tabType: myBetsType,
        };
    },
    computed: {
        ...mapState({
            decimalBetPrecision: (state) => state.platform.settings.betting.real.precision,
            currencyFormat: (state) => state.platform.settings.currency,
        }),
        ...mapGetters({
            dateOptions: getter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            jackpot: sportGetter.GET_JACKPOT_BETS,
            taxEnabled: getter.IS_POOL_TAX_ENABLED,
        }),
        bets() {
            return this.jackpot && this.jackpot.items;
        },
    },
    methods: {
        openJackpotTicket(bet) {
            this.$router.push({ name: routeName.JACKPOT_TICKET, params: { ticketId: bet.id } });
        },
        formatDate(dateTime) {
            return helper.formatDateTime(dateTime, {
                ...this.dateOptions,
                isUtc: true,
                needDate: true,
                needTime: false,
            });
        },
        formatTime(dateTime) {
            return helper.formatDateTime(dateTime, {
                ...this.dateOptions,
                isUtc: true,
                needDate: false,
                needTime: true,
                hour12: true,
            });
        },
        formatNumber(number, decimals = this.decimalBetPrecision) {
            return helper.numberFormat(number, decimals, '.', ',');
        },
    },
};
</script>

<style lang="scss" scoped>
.tax-message {
    @extend %caption-font-400;
    padding: 8px 12px 0;
}

.no-data-text {
    @extend %body-big-font-400;
    text-align: center;
}

.show-more {
    margin-top: 10px;
    text-align: center;
}

.jackpots-list {
    padding: 20px 12px;
}

.bet {
    @extend %small-details-font-400;
    border: $single-betslip-border;
    margin-bottom: 12px;
    color: $main-text;

    .bet-status-line {
        border-bottom: $single-betslip-border;
        margin-right: 12px;
        margin-left: 12px;
    }

    .bet-line {
        display: flex;
        justify-content: space-between;
        padding: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &.bet-header {
            align-items: flex-start;
            @extend %body-normal-font-500;
            background-color: $light-grey;
            border-bottom: 1px solid $medium-grey;
            padding: 10px 12px;

            .header-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                flex-grow: 1;
            }

            .date {
                @extend %body-normal-font-500;
            }

            .time {
                @extend %body-normal-font-400;
            }

            .id {
                @extend %body-normal-font-500;
                color: $disabled-text;
            }
        }

        &.status-wrapper {
            color: $betline-header-status-color;
            font-weight: 700;
            text-align: right;
            white-space: nowrap;
            fill: $betline-header-status-color;
            display: flex;
            padding: 10px 12px;
            justify-content: space-between;
            .result-container {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
                flex-wrap: wrap;
                .result-text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    color: $dark-green;
                    line-height: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .outcome {
                @extend %body-normal-font-700;
            }
        }

        .bet-detail {
            display: flex;
            flex-direction: column;
            gap: 1px;
            font-weight: 700;
            padding-right: 4px;

            &.end {
                align-items: flex-end;
                padding-right: 0px;
                @include mq-xxs {
                    width: 100%;
                    margin-top: 8px;
                    align-items: flex-start;
                }
            }

            &.pending,
            &.success {
                .value {
                    @extend %body-normal-font-700;
                }
            }

            .label {
                @extend %small-details-font-500;
                color: $disabled-text;
            }

            .value {
                @extend %body-normal-font-400;
            }

            .currency {
                &-container {
                    display: flex;
                    align-items: center;
                }

                &.value {
                    display: flex;
                    align-items: center;

                    ::v-deep {
                        .symbol {
                            font-size: $small-link-font-size;
                            color: $dark-grey-2;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }

        .positive {
            color: $green-success;
        }

        .neutral {
            color: $disabled-text;
        }

        .negative {
            color: $error-red;
        }
    }

    .status-text {
        @extend %body-normal-font-700;
    }
}
</style>
