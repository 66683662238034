<template>
    <div>
        <div class="header">
            <div class="back" @click="goBack">
                <SvgIcon class="icon-size-small" icon-id="arrow_left" />
                <span>{{ $t('ui.singleBetslip.back') }}</span>
            </div>
            <div class="matchday-title">
                <span v-if="round[$route.params.roundId]" class="matchday-title-label">
                    {{ $t('ui.virtualSports.standings.matchday') }} {{ round[$route.params.roundId].gameRoundName }}
                </span>
            </div>
        </div>
        <div v-for="league in leagueArray" :key="'matchdayleague' + league.league.name" class="separator-wrapper">
            <div class="separator" @click="toggleLeagueById(league.league.id)">
                <div class="league-name">
                    <ImageIcon class="icon-region icon-size-small" :src="`img/flags/${virtualLeagueMap[league.league.id]}.png`" />
                    {{ league.league.name }}
                </div>
                <SvgIcon class="icon-arrow icon-size-small" :icon-id="showLeague(league.league.id) ? 'arrow_down' : 'arrow_right'" />
            </div>
            <transition name="slide">
                {{ league.matchUps[0] }}
                <div v-show="showLeague(league.league.id)">
                    <VirtualGame
                        v-for="event in league.matchUps"
                        :key="'mday' + league.league.name + event.id"
                        :event="insertTeam(event)"
                        :season="$route.params.seasond"
                        :live="true"
                        :show-h-t-score="showHTScore(event)"
                    />
                </div>
            </transition>
        </div>
        <div v-if="error" class="notify error">
            {{ $t('ui.virtualSports.error.virtualRoundsUnavailable') }}
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { VIRTUAL_LEAGUE_MAP } from '@/modules/sport';
import { action as sportAction } from '@/modules/sport/store/const';

import BackButtonMixin from '@/components/Pages/BackButton.mixin';
import PageMixin from '@/components/Pages/Page.mixin';

import VirtualGame from './VirtualGame';

const matchdayStatus = {
    UPCOMING: 'NOT_STARTED',
    LIVE: 'IN_PLAY',
    FINISHED: 'FINISHED',
};

export default {
    name: 'VirtualMatchday',
    components: { VirtualGame },
    mixins: [BackButtonMixin, PageMixin],
    data() {
        return {
            expandedLeagues: [],
            initialLeagueIndex: 0,
            virtualLeagueMap: VIRTUAL_LEAGUE_MAP,
        };
    },
    computed: {
        ...mapState({
            leagueArray: (state) => state.sport.virtualSports.matchups.leagues,
            teams: (state) => state.sport.virtualSports.matchups.teams,
            round: (state) => state.sport.virtualSports.matchups.rounds,
            error: (state) => state.sport.virtualSports.error,
        }),
    },
    created() {
        this.$store.dispatch(sportAction.GET_VIRTUAL_MATCHUPS, {
            seasonId: this.$route.params.seasonId,
            roundId: this.$route.params.roundId,
        });
        this.unwatchEvents = this.$watch('leagueArray', (matchups) => {
            if (matchups.length) {
                this.toggleLeagueById(matchups[this.initialLeagueIndex].league.id);
                this.unwatchEvents();
            }
        });
    },
    methods: {
        toggleLeagueById(id) {
            const leagueIndex = this.expandedLeagues.indexOf(id);
            leagueIndex < 0 ? this.expandedLeagues.push(id) : this.expandedLeagues.splice(leagueIndex, 1);
        },
        showLeague(id) {
            return this.expandedLeagues.includes(id);
        },
        insertTeam(event) {
            return {
                ...event,
                homeTeam: {
                    id: this.teams[event.homeId].id,
                    name: this.teams[event.homeId].name,
                },
                awayTeam: {
                    id: this.teams[event.awayId].id,
                    name: this.teams[event.awayId].name,
                },
            };
        },
        showHTScore(event) {
            return [matchdayStatus.FINISHED, matchdayStatus.LIVE].includes(event.status) && ![event.homeHT, event.awayHT].includes(null);
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0.64rem 0 0.64rem 1.2rem;
    flex-flow: row;
    background-color: $white-bg;
    border-bottom: 1px solid $single-betslip-header-border-bottom-color;

    .back {
        @extend %body-normal-font-400;
        color: $main-text;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;

        svg {
            position: absolute;
            top: 2px;
        }

        span {
            padding-left: 15px;
        }
    }

    .matchday-title {
        flex: 1;
        text-align: center;
        text-transform: uppercase;

        .matchday-title-label {
            @extend %body-big-font-400;
            color: $grey-text;
        }
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.4s, opacity 0.4s;
    max-height: 500px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    max-height: 0;
}

.separator {
    @extend %body-normal-font-400;
    background: white;
    color: black;
    padding: 15px 12px;
    margin: 0;
    cursor: pointer;
    border-top: 1px solid;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .icon-region {
        vertical-align: bottom;
        margin-right: 5px;
    }
}

.separator-wrapper:last-child {
    border-bottom: 1px solid;
}
</style>
