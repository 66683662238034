<template>
    <div v-if="url" :class="{ 'full-width': isSimplePageFullWidth, margins: isMarginEnabled }">
        <template v-if="href">
            <router-link v-if="isInternal" :to="href">
                <img class="responsive-image" :src="url" />
            </router-link>
            <a v-else :href="href">
                <img class="responsive-image" :src="url" />
            </a>
        </template>
        <img v-else class="responsive-image" :src="url" />
    </div>
</template>
<script>
import { helper } from '@/modules/core';

export default {
    name: 'Banner',
    props: {
        data: Object,
    },
    data() {
        return {
            url: '',
        };
    },
    computed: {
        href() {
            return helper.getObjectField(this.data.content, 'link') || '';
        },
        isMarginEnabled() {
            return helper.getObjectField(this.data.content, 'isMarginEnabled');
        },
        isSimplePageFullWidth() {
            return helper.getObjectField(this.data.content, 'simplePageFullWidth');
        },
        defaultImageDetails() {
            const [image] = helper.getObjectField(this.data.content, 'image.data', []);
            const formats = image.attributes.formats;
            return {
                url: image.attributes.url,
                formats,
            };
        },
        isInternal() {
            return this.$isInternalLink(this.href);
        },
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        $isInternalLink: helper.isInternalLink,
        handleCustomImageFormat() {
            const { url } = this.defaultImageDetails;
            const { image = {} } = this.data.content?.imageFormats?.find((item) => item.breakpoints === this.$mq.size) || {};
            const [item = {}] = image?.data || [];
            const attribute = item.attributes || {};
            this.url = attribute.url || url;
        },
        handleImageFormat() {
            const { formats, url } = this.defaultImageDetails;
            this.url = formats[this.$mq.size]?.url || url;
        },
        handleResize() {
            if (this.data.content.isCustomImageFormat) {
                this.handleCustomImageFormat();
            } else {
                this.handleImageFormat();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.responsive-image {
    width: 100%;
    vertical-align: top;
}
.full-width {
    margin: 0 -20px;

    &:first-child {
        margin-top: -20px;
    }
    &:last-child {
        margin-bottom: -20px;
    }
}
.margins {
    margin: 10px;
}
</style>
