<template>
    <div class="tournament-item">
        <BackToAllButton data-test-id="backToAllButton" :totalEventCount="totalEventCount" @click.native="$emit('resetFilters')" />
        <TournamentsList v-if="filteredTournamentsByEventCount.length" class="light-panel-header">
            <FilterCheckboxItem
                checkbox-id="all-tournaments"
                :checked="isAllCompetitionsChecked({ competitions: filteredTournamentsByEventCount })"
                :label="$t('menus.allPopularLeagues')"
                :counter="tournamentsTotalCount"
                @onCheck="onAllCompetitionsCheck({ checked: $event, competitions: filteredTournamentsByEventCount })"
            />
            <FilterCheckboxItem
                v-for="(tournament, index) in filteredTournamentsByEventCount"
                :key="`tournament-${tournament.id}-${index}`"
                :checkbox-id="tournament.id"
                :checked="isCompetitionChecked({ competition: tournament })"
                :label="tournament.name"
                :counter="getEventsCount(tournament)"
                @onCheck="onCompetitionCheck({ checked: $event, competition: tournament })"
            >
                <template slot="icon">
                    <CountryFlag
                        :iso-code="tournament.icon.isoCode"
                        :img-src="tournament.icon.src"
                        :img-alt-src="tournament.icon.altSrc"
                        class="rounded-flag icon-flag icon-size-small"
                        is-squared
                    />
                </template>
            </FilterCheckboxItem>
        </TournamentsList>
        <CountryMenus class="light-panel-header">
            <template #default="{ menu }">
                <MenuItem
                    v-for="(country, index) in getFilteredCompetitionsByEventCount(menu.data)"
                    :key="`${menu.slug}-country-${country.region.id}-${index}`"
                    class="sub-menu"
                    :selected="isExpandedCountry(country.region.id)"
                    :item="{
                        text: country.region.name,
                        control: {
                            text: getEventsCount(country),
                            icon: isExpandedCountry(country.region.id) ? 'icon-arrow-up' : 'arrow_down',
                        },
                    }"
                    @onItemClick="toggleCountry(country.region.id)"
                >
                    <template slot="icon">
                        <CountryFlag
                            :iso-code="country.icon.isoCode"
                            :img-src="country.icon.src"
                            :img-alt-src="country.icon.altSrc"
                            class="rounded-flag icon-flag icon-size-small"
                            is-squared
                        />
                    </template>
                    <template slot="content">
                        <ExpansionPanel v-if="country.competitions" :expanded="isExpandedCountry(country.region.id)">
                            <FilterCheckboxItem
                                :checkbox-id="`all-${country.region.name}-${country.region.id}`"
                                :checked="isAllCompetitionsChecked({ competitions: country.competitions })"
                                :label="$t('menus.allCountryLeagues', { country: country.region.name })"
                                :counter="getEventsCount(country)"
                                @onCheck="onAllCompetitionsCheck({ checked: $event, competitions: country.competitions })"
                            />
                            <FilterCheckboxItem
                                v-for="(competition, competitionIndex) in getFilteredCompetitionsByEventCount(country.competitions)"
                                :key="`${menu.slug}-country-filter-${competition.slug}-${competitionIndex}`"
                                :checkbox-id="`competition-${competitionIndex}-${country.region.id}`"
                                :checked="isCompetitionChecked({ competition })"
                                :label="competition.name"
                                :counter="getEventsCount(competition)"
                                @onCheck="onCompetitionCheck({ checked: $event, competition })"
                            />
                        </ExpansionPanel>
                    </template>
                </MenuItem>
            </template>
        </CountryMenus>
    </div>
</template>

<script>
import { FilterCheckboxItem, TournamentsList, CountryMenus } from '@/modules/sport/components';
import { MenuItem, ExpansionPanel } from '@/modules/core/components';
import { EventCountKeysByPage } from '@/modules/sport/const/events-const';
import CountryFlag from '@/modules/platform/components/CountryFlag.vue';
import { getObjectField } from '@/modules/core/utils/helper';
import BackToAllButton from '@/modules/sport/components/Fragments/EventFilter/BackToAllButton.vue';

export default {
    name: 'FilterByTournament',
    components: { BackToAllButton, CountryFlag, TournamentsList, FilterCheckboxItem, CountryMenus, MenuItem, ExpansionPanel },
    props: {
        tournaments: {
            type: Array,
            required: true,
        },
        competitions: {
            type: Array,
            required: true,
        },
        totalEventCount: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            expandedCountries: [],
        };
    },
    computed: {
        tournamentsTotalCount() {
            return this.filteredTournamentsByEventCount.reduce(
                (accumulator, current) => accumulator + (this.getEventsCount(current) || 0),
                0
            );
        },
        filteredTournamentsByEventCount() {
            return this.tournaments.filter((tournament) => this.getEventsCount(tournament) > 0);
        },
    },
    methods: {
        isAllCompetitionsChecked({ competitions }) {
            return !!competitions?.every((i) => this.competitions.includes(i.id));
        },
        onAllCompetitionsCheck({ checked, competitions }) {
            this.$emit('allCheck', { checked, competitions });
        },
        isCompetitionChecked({ competition }) {
            const { id } = competition;
            return this.competitions.includes(id);
        },
        onCompetitionCheck({ checked, competition }) {
            this.$emit('check', { checked, competition });
        },
        isExpandedCountry(countryRegionId) {
            return this.expandedCountries.includes(countryRegionId);
        },
        toggleCountry(countryRegionId) {
            if (this.isExpandedCountry(countryRegionId)) {
                this.expandedCountries = this.expandedCountries.filter((i) => i !== countryRegionId);
            } else {
                this.expandedCountries.push(countryRegionId);
            }
        },
        getEventsCount(event) {
            return getObjectField(event, EventCountKeysByPage[this.$route.name], 0);
        },
        getFilteredCompetitionsByEventCount(competitions) {
            return competitions.filter((competition) => this.getEventsCount(competition) > 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.tournament-item {
    .light-panel-header:first-child {
        &::v-deep {
            .title {
                border-top: none;
            }
        }
    }
}

.icon-flag {
    margin-right: 4px;
}

.light-panel-header {
    &::v-deep {
        &.expansion-panel,
        & > .expansion-panel {
            .title {
                background: $white-bg;
                padding: 12px;

                h3 {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}

.sub-menu {
    &::v-deep {
        &.menu-item.selected > .link {
            font-weight: 500;
            color: inherit;

            svg.svg-icon {
                fill: $main-text;
            }
        }
    }
}
</style>
