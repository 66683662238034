<template>
    <div class="video-stream-spinner">
        <div class="video-stream-spinner-container">
            <div class="video-stream-spinner-image" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoStreamSpinner',
};
</script>

<style scoped lang="scss">
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.video-stream-spinner {
    align-items: center;
    &-image {
        display: block;
        background: transparent url('./../../assets/video-stream-spinner.svg') no-repeat 50%;
        width: 32px;
        height: 32px;
        animation: spin 1s infinite linear;
    }
}
</style>
