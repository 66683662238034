var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"request-offer",class:_vm.getRequestOfferWrapperClass},[_c('div',{staticClass:"offer-message"},[(_vm.isNetworkError)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('ui.common.error.networkError'))+" ")]):(_vm.showCashoutInProgress && !_vm.isCashOutRefreshing)?_c('Spinner',{staticClass:"justify-center",attrs:{"visible":"","type":"bp-spinner"}},[_vm._v(" "+_vm._s(_vm.offerInProgressMessage)+" ")]):(_vm.isCashOutLocked)?_c('p',[_c('renderer',{attrs:{"input":_vm.$t('ui.cashout.cashoutLocked')}})],1):(_vm.requestOfferError.message)?_c('p',[(_vm.isGenericError)?[_vm._v(" "+_vm._s(_vm.$t('ui.common.error.genericError'))+" ")]:[_c('renderer',{attrs:{"input":_vm.requestOfferError.message}})]],2):(!_vm.isNetworkError && _vm.isCashoutOfferLoadingOrReady && _vm.isConfirmCashOutReady)?_c('div',{staticClass:"cashout-offer"},[_c('p',[_vm._v(_vm._s(_vm.$t('ui.cashout.cashoutOffer')))]),_c('Currency',{staticClass:"offer-amount",attrs:{"data-test-id":"cashoutOffer","contrast":false,"amount":_vm.cashOutOffer,"format":_vm.settings.currency,"is-loading":_vm.isCashOutOfferCurrencyLoading}})],1):_c('p',[_c('SvgIcon',{staticClass:"cashout-icon icon-size-medium",attrs:{"icon-id":"icon-cashoutable"}}),_vm._v(" "+_vm._s(_vm.$t('ui.cashout.cashout'))+" ")],1)],1),_c('div',[(_vm.showConfirmCashout)?_c('PromptAction',{class:{ 'offer-button offer-button-prompt-action': _vm.$mq.isXSmall },attrs:{"disabled":_vm.confirmOfferIsLoading || !!_vm.pollingCounter,"left":Object.assign({}, {text: _vm.$t('ui.cashout.acceptOffer'),
                    color: 'primary'},
                    _vm.promptActionDisabledState.leftAction),"right":Object.assign({}, {isLoading: _vm.isCashOutRefreshing,
                    icon: _vm.isOldCashOut ? 'icon-close' : 'icon-reload',
                    iconCssClass: _vm.isOldCashOut ? 'icon-size-small icon-white-color' : 'icon-size-small',
                    buttonCssClass: _vm.promptRightBtnClasses},
                    _vm.promptActionDisabledState.rightAction)},on:{"leftResolvePrompt":_vm.promptLeftBtnAction,"rightResolvePrompt":_vm.promptRightBtnAction}}):_c('button',{staticClass:"button button-accent offer-button",attrs:{"disabled":_vm.disableRequestCashout},on:{"click":function($event){return _vm.requestOffer()}}},[_vm._v(" "+_vm._s(_vm.requestCashoutButtonText)+" ")])],1)]),_c('Modal',{staticClass:"cashout-modal",attrs:{"name":"cashout-modal","options":{ overlay: true },"width":"312px","fixed":true},on:{"close":_vm.closeModal}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('ui.cashout.cashOutNowModalTitle')))]),_c('p',{staticClass:"description"},[_c('renderer',{attrs:{"input":_vm.$t('ui.cashout.cashOutNowModalDescription', {
                        newOffer: _vm.numberFormat(_vm.cashOutOffer),
                        oldOffer: _vm.acceptableOffer,
                        currency: _vm.currencySymbol,
                    })}})],1),_c('button',{staticClass:"button button-primary button-full button-accept-cashout",on:{"click":_vm.confirmOffer}},[_vm._v(" "+_vm._s(_vm.$t('ui.cashout.acceptOffer'))+" ")]),_c('button',{staticClass:"button button-accent button-full",on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('ui.cashout.cancelOffer'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }