<template>
    <div id="app" v-onmedia="handleMenus" :class="[...deviceTypesArray, { 'full-page': canDisplayLandingPage || canDisplaySplashPage }]">
        <AppErrorPage v-if="settings && !!settings.error" />
        <LoadingPage v-else :listen="spinnerTrigger" v-bind="{ ...loadingPageAttr }" />
        <LandingPage v-if="canDisplayLandingPage" />
        <SplashPage v-else-if="canDisplaySplashPage" />
        <template v-else-if="appIsReadyToDisplay">
            <AppLayout
                v-sniffer="{ handler: onSnifferChangeLayout, anyDistance: true }"
                :is-center-overlay-active="false"
                :disable-scroll="!$mq.mdUp && isLeftSidebarOpen"
                :hide-right-side-bar="hideRightSideBar"
            >
                <template #section:top>
                    <Header v-if="shouldShowElement(AppElements.HEADER)" />
                    <ProductNav v-if="shouldShowElement(AppElements.PRODUCT_NAV)" />
                </template>
                <template #section:left>
                    <SideBar
                        :width="$mq.mdUp ? '360px' : '100vw'"
                        :class="{ mobile: !$mq.mdUp }"
                        class-name="leftMenu"
                        is-full-screen
                        :is-mobile="!$mq.mdUp"
                        is-overlaps
                        :is-open="isLeftSidebarOpen"
                        :has-transition-delay="isBottomNavigationVisible"
                        can-close
                        @onRouteChange="leftMenuRouteChange"
                    >
                        <LeftMenu />
                    </SideBar>
                </template>
                <template #section:center:top>
                    <EventFilter v-if="showEventFilters" :hide="isHiddenOnScroll" />
                    <ChipsHeader v-if="showChipsHeader" />
                    <div v-for="({ type, active }, index) in displayedProgressiveJackpots" :key="index">
                        <ProgressiveJpProvider v-if="active" :type="type">
                            <template #default="{ progressiveJackpot }">
                                <ProgressiveJpPreviewBarCollapsible v-bind="{ progressiveJackpot }" />
                            </template>
                        </ProgressiveJpProvider>
                    </div>
                </template>
                <template #section:center:middle>
                    <div v-if="isGlobalLoadingInProgress" class="view-wrapper-overlay">
                        <Spinner class="align-center transparent" :visible="true" />
                    </div>
                    <OnSiteMessages v-if="isAuthenticated && !$mq.mdUp" />
                    <Notifications />
                    <SelfExclusionModal />
                    <Announcement />
                    <OnBoarding v-if="isHomePage" />
                    <ChipsNotification v-if="showChips && !toppedUpChips" />
                    <ChipsModal v-if="showChips" />
                    <BetSharingModal />
                    <SessionExpiredModal />
                    <TermsAndConditions v-if="appIsReady && showTermsAndConditions" />
                    <div class="main-content-2">
                        <template v-if="settings && !settings.error">
                            <router-view class="router-view" :show-event-filters="showEventFilters" />
                        </template>
                    </div>
                    <Footer v-if="shouldShowElement(AppElements.FOOTER)" />
                    <VersionApp class="version-app-footer" />
                    <BackToTop :is-hidden="isHiddenBackToTop" />
                </template>
                <template #section:right>
                    <SideBar
                        is-full-screen
                        :width="$mq.mdUp ? '384px' : '100vw'"
                        :right="true"
                        :is-overlaps="true"
                        :is-open="isAccountMenuOpen"
                    >
                        <MyAccount />
                    </SideBar>
                    <SideBar
                        v-if="!hideRightSideBar"
                        class=""
                        :right="true"
                        :class="{ mobile: !$mq.mdUp }"
                        :is-full-screen="!$mq.mdUp"
                        :is-mobile="!$mq.mdUp"
                        :is-overlaps="!$mq.mdUp"
                        :is-open="isRightSidebarOpen"
                        :has-transition-delay="isBottomNavigationVisible"
                        :can-close="!$mq.mdUp"
                    >
                        <BetSideBar />
                        <BetSlipBigWinNotificationModal v-if="showBigWinNotification && $mq.mdUp" />
                    </SideBar>
                </template>
                <template #section:bottom>
                    <BetSlipBigWinNotificationModal v-if="showBigWinNotification && !$mq.mdUp" />
                    <BottomNavigation
                        v-if="shouldShowElement(AppElements.BOTTOM_NAVIGATION) && isBottomNavigationVisible"
                        :hide="isRightSidebarOpen || isLeftSidebarOpen || isAccountMenuOpen"
                    />
                </template>
            </AppLayout>
        </template>
        <component
            :is="liveChat"
            v-if="liveChatEnabled && brandIdentifier"
            :brand-identifier="brandIdentifier"
            :mode="liveChatMode"
            :phone="phone"
            username=""
        />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { routeName } from '@/router/const-name';
import { routePath } from '@/router/const-path';

import { action as storeAction, getter as generalGetter } from '@/store/const';

import { android, ios, config, deviceType, deviceTypesArray } from '@/modules/core';
import { getObjectField } from '@/modules/core/utils/helper';
import { Spinner } from '@/modules/core/components';
import { getter as translationGetter } from '@/store/modules/translations/const';
import { getter as coreGetter } from '@/modules/core/store/const';

import { BetSideBar, EventFilter } from '@/modules/sport/components';
import { action as betslipAction } from '@/modules/sport/store/modules/betslip/const';
import { action as sportAction, mutation as sportMutation } from '@/modules/sport/store/const';

import { isSibling } from '@/modules/platform';
import { getter as authGetter, mutation as authMutation, action as authAction } from '@/modules/platform/store/modules/auth/const';
import { getter as platformGetter, action as platformAction, mutation as platformMutation } from '@/modules/platform/store/const';
import { progressiveJpType } from '@/modules/platform/const/progressive-jackpot';
import { AppElements } from '@/const/app-elements';

import AppLayout from '@/components/Layout/AppLayout.vue';
import Header from '@/components/Header.vue';
import ProductNav from '@/components/ProductNav.vue';
import LandingPage from '@/components/Pages/LandingPage.vue';
import LoadingPage from '@/components/Pages/LoadingPage.vue';
import AppErrorPage from '@/components/Pages/AppErrorPage.vue';
import Footer from '@/components/Fragments/Footer.vue';
import LeftMenu from '@/components/Fragments/LeftMenu.vue';
import SideBar from '@/components/Fragments/SideBar.vue';
import Notifications from '@/components/Notifications.vue';
import VersionApp from '@/components/VersionApp';
import OnBoarding from '@/components/OnBoarding.vue';
import ChipsHeader from '@/components/Fragments/Chips/ChipsHeader.vue';
import ChipsModal from '@/components/Fragments/Chips/ChipsModal.vue';
import ChipsNotification from '@/components/Fragments/Chips/ChipsNotification.vue';
import ErrorPage from '@/components/Pages/ErrorPage';
import TermsAndConditions from '@/components/TermsAndConditions';
import BottomNavigation from '@/components/Fragments/BottomNavigation';
import BackToTop from '@/components/Fragments/BackToTop';

import { Announcement, OnSiteMessages } from '@/modules/platform/components';
import BetSharingModal from '@/modules/sport/components/Fragments/BetSharingModal.vue';
import ProgressiveJpProvider from '@/modules/platform/components/ProgressiveJpProvider.vue';
import ProgressiveJpPreviewBarCollapsible from '@/components/Fragments/ProgressiveJackpot/ProgressiveJpPreviewBarCollapsible.vue';
import scrollSniffer from '@/js/directives/ScrollSniffer';
import { scrollDirection } from '@/js/scroll-direction-const';
import BetSlipBigWinNotificationModal from '@/modules/sport/components/Fragments/Betslip/BetSlipBigWinNotificationModal.vue';
import SessionExpiredModal from '@/modules/platform/components/content/SessionExpiredModal.vue';
import { isNumber } from '@/modules/core/utils/number/isNumber';
import SplashPage from '@/components/Pages/SplashPage.vue';
import SelfExclusionModal from '@/modules/platform/components/Verification/SelfExclusionModal';
import MyAccount from '@/components/Fragments/MyAccount/MyAccount.vue';

const INITIAL_LOAD_SLOTS = ['LOGO_SUBTEXT', 'FOOTER_COMPONENT'];

export default {
    name: 'App',
    directives: {
        sniffer: scrollSniffer,
    },
    components: {
        MyAccount,
        SplashPage,
        SessionExpiredModal,
        ProgressiveJpPreviewBarCollapsible,
        ProgressiveJpProvider,
        BetSharingModal,
        AppLayout,
        Header,
        ProductNav,
        LandingPage,
        Footer,
        BetSideBar,
        LeftMenu,
        SideBar,
        Spinner,
        Notifications,
        SelfExclusionModal,
        Announcement,
        VersionApp,
        OnBoarding,
        TermsAndConditions,
        OnSiteMessages,
        LoadingPage,
        AppErrorPage,
        ErrorPage,
        BottomNavigation,
        ChipsHeader,
        ChipsModal,
        ChipsNotification,
        EventFilter,
        BetSlipBigWinNotificationModal,
        BackToTop,
    },
    data() {
        return {
            AppElements,
            deviceTypesArray: deviceTypesArray(),
            appIsReady: null,
            agiSpinnerTrigger: platformAction.LOAD_AGI_SETTINGS,
            strapiSpinnerTrigger: platformAction.GET_CONFIG,
            isIos: deviceType.isIos(),
            canonicalLinkRef: null,
            isHiddenOnScroll: false,
            updatePendingBetslipsInterval: undefined,
            isBackToTopHidden: true,
            hiddenElementsByUrl: {
                [AppElements.HEADER]: [routePath.RESET, routePath.VERIFICATION_CODE],
                [AppElements.PRODUCT_NAV]: [routePath.RESET, routePath.VERIFICATION_CODE],
                [AppElements.BOTTOM_NAVIGATION]: [routePath.RESET, routePath.VERIFICATION_CODE],
                [AppElements.FOOTER]: [routePath.RESET, routePath.VERIFICATION_CODE],
            },
        };
    },
    computed: {
        liveChat() {
            return () => {};
            // return () => import('@betpawa/live-chat')
        },
        ...mapState({
            sidebarOpen: (state) => state.ui.sidebarOpen,
            isAccountMenuOpen: (state) => state.ui.isAccountMenuOpen,
            betslipOpen: (state) => state.ui.betslipOpen,
            isGlobalLoadingInProgress() {
                return this.isLoading(storeAction.LOADING);
            },
            countries: (state) => state.countries,
            mobileSearchOpen: (state) => state.ui.mobileSearchOpen,
        }),
        ...mapGetters({
            getContentGetter: platformGetter.GET_CONTENT_SLOTS,
            isLoading: coreGetter.IS_LOADING,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            preference: platformGetter.GET_PREFERENCE,
            user: platformGetter.GET_USER_SETTINGS,
            country: platformGetter.GET_COUNTRY,
            settings: platformGetter.GET_SETTINGS,
            brandDetails: platformGetter.GET_BRAND_DETAILS,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            socketsUrl: platformGetter.GET_SOCKETS_URL,
            lastWinInfo: platformGetter.GET_LAST_WIN_INFO,
            isBigWinNotificationEnabled: platformGetter.IS_BIG_WIN_NOTIFICATION_ENABLED,
            lastSeenBigWinBetId: platformGetter.GET_LAST_SEEN_WIN_BET_ID,
            progressiveSportJackpots: platformGetter.GET_SPORT_PROGRESSIVE_JP_FROM_SETTINGS,
            sideBarDisabledPages: platformGetter.GET_SIDE_BAR_DISABLED_PAGES,
            isSplashPageEnabled: platformGetter.IS_SPLASH_PAGE_ENABLED,
            isLandingPage: platformGetter.IS_LANDING_PAGE,
            lang: translationGetter.LANGUAGE,
        }),
        loadingPageAttr() {
            return (!this.canDisplaySplashPage && !this.canDisplayLandingPage && { visible: this.appIsLoading }) || {};
        },
        spinnerTrigger() {
            return this.canDisplaySplashPage || this.canDisplayLandingPage ? this.strapiSpinnerTrigger : this.agiSpinnerTrigger;
        },
        isTranslationReady() {
            return !!this.lang && !!this.$t;
        },
        appIsReadyToDisplay() {
            return this.settings && this.appIsReady;
        },
        appIsLoading() {
            return !this.appIsReady || this.isBookingCodeLoading;
        },
        canDisplayLandingPage() {
            return this.isTranslationReady && this.isLandingPage;
        },
        canDisplaySplashPage() {
            return this.isTranslationReady && this.isSplashPageEnabled;
        },
        isRightSidebarOpen() {
            return this.betslipOpen || this.$mq.mdUp;
        },
        isLeftSidebarOpen() {
            return this.sidebarOpen;
        },
        isBottomNavigationVisible() {
            return this.bottomNavigationEnabled && !this.$mq.mdUp;
        },
        isHomePage() {
            return this.$route.name === routeName.HOMEPAGE;
        },
        isCasinoPage() {
            return [routeName.CASINO, routeName.GAMES].includes(this.$route.name);
        },
        toppedUpChips() {
            return this.preference.topped_up_chips;
        },
        showChips() {
            return this.isAuthenticated && this.settings.chips && this.isCasinoPage;
        },
        showChipsHeader() {
            return this.showChips && this.toppedUpChips;
        },
        showTermsAndConditions() {
            const termsConditionsActivationDate = new Date(getObjectField(this.brandPreference, 'termsConditionsActivationDate'));
            if (isNaN(termsConditionsActivationDate.getTime())) return false;
            const termsAcceptedDate = this.preference['terms_accepted_date'] ? new Date(this.preference['terms_accepted_date']) : null;

            const userSignedUpAfterTermsUpdate = new Date(this.user.signedUp).getTime() < termsConditionsActivationDate.getTime();
            const isAcceptedCurrentTerms = termsAcceptedDate && termsAcceptedDate.getTime() > termsConditionsActivationDate.getTime();
            const { displayTermsConditions } = this.brandPreference;

            return this.isAuthenticated && !isAcceptedCurrentTerms && userSignedUpAfterTermsUpdate && displayTermsConditions;
        },
        bottomNavigationEnabled() {
            const { enableBottomNavigation } = this.brandPreference;
            return !!enableBottomNavigation;
        },
        brandIdentifier() {
            return this.settings?.brand?.identifier;
        },
        phone() {
            return this.user?.phoneNumber;
        },
        liveChatMode() {
            return config.isProd() ? 'production' : 'stage';
        },
        liveChatEnabled() {
            const { liveChat } = this.brandPreference;
            return !!liveChat;
        },
        showEventFilters() {
            const { meta } = this.$route;
            const { isEventPageFiltersEnabled } = this.brandPreference;
            return !!isEventPageFiltersEnabled && getObjectField(meta, 'showEventFilters');
        },
        isBookingCodeLoading() {
            return this.isLoading(betslipAction.LOAD_BOOKING_CODE_BY_QUERY);
        },
        displayedProgressiveJackpots() {
            const hasSportProgressiveJackpot = !!this.progressiveSportJackpots;
            return [
                { type: hasSportProgressiveJackpot ? progressiveJpType.SPORT : progressiveJpType.CASINO, active: this.isHomePage },
                { type: progressiveJpType.CASINO, active: this.isCasinoPage },
            ];
        },
        shouldHideOnScrollBreakpoint() {
            return !this.$mq.mdUp;
        },
        showBigWinNotification() {
            return (
                this.$route.name !== routeName.BETSLIP &&
                this.lastWinInfo &&
                this.lastSeenBigWinBetId !== this.lastWinInfo.lastWinBetId &&
                this.isBigWinNotificationEnabled &&
                this.isAuthenticated
            );
        },
        hideRightSideBar() {
            return this.sideBarDisabledPages.some((disabledPage) => this.$route.path.includes(disabledPage)) && this.$mq.mdUp;
        },
        isHiddenBackToTop() {
            return (!this.$mq.mdUp && (this.isRightSidebarOpen || this.isLeftSidebarOpen)) || this.isBackToTopHidden;
        },
    },
    watch: {
        isAuthenticated() {
            this.pollPendingBetsCount();
        },
        socketsUrl(url) {
            if (url) {
                this.$rs.connect(url);
            }
        },
        settings() {
            if (!this.appIsReady) this.appIsReady = !this.appIsReady;

            if (!this.countries?.length) {
                this.$store.dispatch(storeAction.GET_CATEGORY_ACTIVE_REGIONS, this.currentCategoryId);
            }
        },
        appIsReady(val) {
            if (val) {
                android.siteRdy();
            }
        },
        $route(newRoute, oldRoute) {
            this.$store.commit('closeMobileSearch');
            this.canonicalLinkRef.href = this.getProdUrl(newRoute.path);
            if (!isSibling(newRoute.matched, oldRoute.path)) {
                const slotId = newRoute.matched[0].props.default && newRoute.matched[0].props.default.slotId;
                const slotIds = Array.isArray(slotId) ? slotId : (slotId && [slotId]) || [];
                this.$store.dispatch(platformAction.GET_CONTENT, [...INITIAL_LOAD_SLOTS, ...slotIds]);
                this.$store.commit(platformMutation.CLEAR_ACTIVE_PROGRESSIVE_JACKPOT_POLLING);
                this.isHiddenOnScroll = false;
            }
        },
    },
    created() {
        this.$http.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error && error.statusCode === 401) {
                    if (this.isAuthenticated) {
                        this.resetUser();
                    }
                }
                return Promise.reject(error);
            }
        );
        this.setCanonicalLinkTag();
        if (this.isIos) {
            ios.setup();
        }
    },
    beforeMount() {
        window.addEventListener('beforeunload', this.disconnectSocket);
    },
    beforeDestroy() {
        this.clearPendingBetslipsInterval();
    },
    methods: {
        clearPendingBetslipsInterval() {
            if (this.updatePendingBetslipsInterval) {
                clearInterval(this.updatePendingBetslipsInterval);
            }
        },
        leftMenuRouteChange() {
            this.$store.dispatch(storeAction.SET_SIDEBAR_STATE, false);
        },
        handleMenus(alias, matches) {
            if (matches) {
                this.$store.dispatch(storeAction.SET_BETSLIP_STATE, alias === 'mdUp');
            }

            if (!this.shouldHideOnScrollBreakpoint && matches) {
                this.isHiddenOnScroll = false;
            }
        },
        setCanonicalLinkTag() {
            this.canonicalLinkRef = document.createElement('link');
            this.canonicalLinkRef.rel = 'canonical';
            this.canonicalLinkRef.href = this.getProdUrl();
            document.head.appendChild(this.canonicalLinkRef);
        },
        getProdUrl(path = '/') {
            return window.location.origin + path;
        },
        disconnectSocket() {
            this.$rs.disconnect();
        },
        resetUser() {
            this.$store.dispatch(authAction.RESET_USER).then(() => {
                this.$store.commit(authMutation.SET_SESSION_EXPIRED, true);
                if (this.$route.name !== routeName.HOMEPAGE) {
                    this.$router.push({ name: routeName.HOMEPAGE }).finally(() => {
                        this.$modal.show('session-expired-modal');
                    });
                }
            });
        },
        onSnifferChangeLayout(direction, topScroll) {
            if (topScroll >= window.innerHeight) {
                if (direction === scrollDirection.DOWN) {
                    this.isBackToTopHidden = false;
                } else {
                    this.isBackToTopHidden = true;
                }
            } else if (!this.isBackToTopHidden) {
                this.isBackToTopHidden = true;
            }

            if (this.shouldHideOnScrollBreakpoint && this.showEventFilters) {
                if (direction === scrollDirection.DOWN && !this.isHiddenOnScroll) {
                    this.isHiddenOnScroll = true;
                } else if (direction === scrollDirection.UP && this.isHiddenOnScroll) {
                    this.isHiddenOnScroll = false;
                }
            }
        },
        getPendingBetsCount() {
            return this.$store.dispatch(sportAction.GET_PENDING_BETS_COUNT);
        },
        pollPendingBetsCount() {
            if (this.isAuthenticated) {
                const { openBetsCountIntervalInSeconds } = this.brandPreference;
                if (isNumber(openBetsCountIntervalInSeconds) && openBetsCountIntervalInSeconds > 0) {
                    this.getPendingBetsCount().then(() => {
                        this.clearPendingBetslipsInterval();
                        this.updatePendingBetslipsInterval = setInterval(
                            () => this.getPendingBetsCount(),
                            openBetsCountIntervalInSeconds * 1000
                        );
                    });
                }
            } else {
                this.clearPendingBetslipsInterval();
                this.$store.commit(sportMutation.UPDATE_PENDING_BETS_COUNT, { pendingBetsCount: 0 });
            }
        },
        shouldShowElement(element) {
            const urlsWhereElementIsHidden = getObjectField(this.hiddenElementsByUrl, element);

            if (!urlsWhereElementIsHidden) return true;

            return urlsWhereElementIsHidden.every((url) => !this.$route.path.includes(url));
        },
    },
};
</script>

<style src="./styles/styles.scss" lang="scss"></style>

<style lang="scss">
#app {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.view-wrapper-overlay {
    position: fixed;
    background: rgb(0 0 0 / 60%);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    flex: 1 1 auto;
    z-index: 999;
}

.stop-scroll {
    overflow: hidden;
}

.main-content {
    width: 100%;
    display: flex;
    flex: 1 0 83%;
    &-2 {
        flex: 1 0 $main-content-min-height;
    }
}

.router-view {
    position: relative;
}

.center-view {
    position: relative;
    overflow: hidden;
    flex: 1;
    width: 100%;
}

.side-bar.leftMenu {
    flex: 1 0 17%;
    background-color: $left-menu-background-color;
}
</style>
