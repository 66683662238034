<template>
    <img
        v-if="src && !isNotLoaded"
        v-show="isLoaded"
        :class="imgClass"
        :src="getSrc(src)"
        @load="isLoaded = true"
        @error="isNotLoaded = true"
    />
    <img v-else-if="altSrc && isNotLoaded" v-show="isLoaded" :class="imgClass" :src="getSrc(altSrc)" @load="isLoaded = true" />
</template>

<script>
import { helper } from '@/modules/core';
import { ICON_SIZES } from '@/const/icon-sizes.js';

export default {
    name: 'ImageIcon',
    props: {
        src: {
            type: String,
            required: true,
        },
        altSrc: {
            type: String,
            required: false,
        },
        iconSize: {
            type: String,
            required: false,
            validator(value) {
                return Object.values(ICON_SIZES).includes(value);
            },
        },
    },
    data() {
        return {
            isLoaded: null,
            isNotLoaded: null,
        };
    },
    computed: {
        imgClass() {
            return `image-icon${this.iconSize ? ` icon-size-${this.iconSize}` : ''}`;
        },
    },
    methods: {
        getSrc(src) {
            const srcDoesntStartsWithSlash = /^[^/]/.test(src);
            return `${helper.isInternalLink(src) && srcDoesntStartsWithSlash ? '/' : ''}${src}`;
        },
    },
};
</script>

<style scoped lang="scss">
img {
    width: 32px;
    height: 32px;
    vertical-align: middle;

    &.icon-status {
        width: 14px;
        height: 14px;
        min-width: 14px;
        transform: translateY(-6%);
        fill: $icon-primary;
    }

    @extend %icon-sizes;
}
</style>
