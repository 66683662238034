export const action = {
    GET_BALANCE: 'platform/auth/getBalance',
    GET_CHIPS_BALANCE: 'platform/auth/getChipsBalance',
    LOGIN: 'platform/auth/login',
    LOGOUT: 'platform/auth/logout',
    REGISTER: 'platform/auth/register',
    VERIFY_ACCOUNT_BY_HASH: 'platform/auth/verifyAccountByHash',
    SET_PASSWORD: 'platform/auth/setPassword',
    SET_ERROR: 'platform/auth/setError',
    RESET_ERROR: 'platform/auth/resetError',
    CHANGE_PASSWORD: 'platform/auth/changePassword',
    RESET_USER: 'platform/auth/resetAuth',
    VERIFY_KYC: 'platform/auth/kycVerification',
    ENABLE_ACCESS: 'platform/auth/enableAccess',
    SELF_EXCLUDE: 'platform/auth/selfExclude',
    GENERATE_RESET_PASSWORD_TRACKING_ID: 'platform/auth/generateResetPasswordTrackingId',
    NEED_UPDATE_SECURED_TOKEN: 'platform/auth/needUpdateSecuredToken',
    PRE_REGISTER: 'platform/auth/preRegister',
    REQUEST_CODE: 'platform/auth/requestCode',
    RESET_USER_OTP_OPTIONS: 'platform/auth/resetUserOtpOptions',
    VERIFY_OTP_CODE: 'platform/auth/verifyOtpCode',
    VERIFY_VERIFICATION_CODE: 'platform/auth/verifyVerificationCode',
    UPDATE_AND_AUTHENTICATE: 'platform/auth/updateAndAuthenticate',
};

export const mutation = {
    SET_ERROR: 'platform/auth/setError',
    RESET_ERROR: 'platform/auth/resetError',
    SET_PHONE_NUMBER: 'platform/auth/setPhoneNumber',
    SET_AUTH: 'platform/auth/setAuth',
    RESET_AUTH: 'platform/auth/resetAuth',
    SET_SECURITY_TOKEN: 'platform/auth/setSecurityToken',
    SET_CAPTCHA_ERROR: 'platform/auth/setCaptchaError',
    RESET_CAPTCHA_ERROR: 'platform/auth/resetCaptchaError',
    SET_RESET_PASSWORD_TRACKING_ID: 'platform/auth/setResetPasswordTrackingId',
    NEED_UPDATE_SECURED_TOKEN: 'platform/auth/needUpdateSecuredToken',
    SET_SESSION_EXPIRED: 'platform/auth/setSessionExpired',
    SET_USER_OTP_OPTIONS: 'platform/auth/setUserOtpOptions',
    SET_SELECTED_OTP_OPTION: 'platform/auth/setSelectedOtpOption',
    SET_GLOBAL_LIMIT_REACHED: 'platform/auth/setUserOtpOptionsError',
};

export const getter = {
    SECURED_TOKEN: 'platform/auth/securedToken',
    IS_CAPTCHA_ENABLED: 'platform/auth/isCaptchaEnabled',
    IS_AUTHENTICATED: 'platform/auth/isAuthenticated',
    IS_USER_VERIFIED: 'platform/auth/isUserVerified',
    GET_BALANCE: 'platform/auth/getBalance',
    GET_CHIPS_BALANCE: 'platform/auth/getChipsBalance',
    GET_CAPTCHA_ERROR: 'platform/auth/getCaptchaError',
    GET_AUTH_ERROR: 'platform/auth/getAuthError',
    GET_AUTH_ERROR_CODE: 'platform/auth/getAuthErrorCode',
    GET_PHONE_NUMBER: 'platform/auth/getPhoneNumber',
    GET_STORED_PHONE_NUMBER: 'platform/auth/getStoredPhoneNumber',
    GET_RESET_PASSWORD_TRACKING_ID: 'platform/auth/getResetPasswordTrackingId',
    NEED_UPDATE_SECURED_TOKEN: 'platform/auth/needUpdateSecuredToken',
    IS_SESSION_EXPIRED: 'platform/auth/isSessionExpired',
    GET_USER_OTP_OPTIONS: 'platform/auth/getUserOtpOptions',
    GET_SELECTED_OTP_OPTION: 'platform/auth/getSelectedOtpOption',
    GET_IS_GLOBAL_LIMIT_REACHED: 'platform/auth/isGlobalLimitReached',
};
