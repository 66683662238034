<template>
    <div class="menu-item">
        <div
            v-for="option of otpOptions"
            :key="`${option.name}-option`"
            :data-test-id="`${option.name}-otp-option`"
            class="otp-options-selector-item"
            :class="{ disabled: option.limitReached }"
            @click="changeOtpOptionMethod(option)"
        >
            <div class="otp-options-selector-content">
                <RadioButton
                    :id="option.name"
                    class="otp-options-selector-radio-button"
                    :checked="selectedOtpOption === option.name"
                    :disabled="option.limitReached"
                    icon-vertical-align="baseline"
                />

                <div v-if="getIconName(option.name)" class="otp-options-selector-icon">
                    <SvgIcon :icon-id="getIconName(option.name)" vertical-align="baseline" />
                </div>

                <span class="otp-options-selector-name">
                    {{ getOtpOptionName(option) }}

                    <span v-if="option.recommended" class="otp-options-selector-recommended">
                        {{ $t('ui.accountVerification.recommended') }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { mutation as authMutation } from '@/modules/platform/store/modules/auth/const';
import { otpOptionsNames, otpOptionsFields } from '@/modules/platform/const/verification';

import RadioButton from '@/components/RadioButton.vue';

const IconNames = {
    [otpOptionsFields.VOICE]: 'icon-help-menu',
    [otpOptionsFields.TELEGRAM]: 'icon-telegram',
    [otpOptionsFields.SMS]: 'icon-messages-menu',
    [otpOptionsFields.USSD]: 'icon-calculator',
    [otpOptionsFields.WHATSAPP]: 'icon-whatsapp',
};

export default {
    name: 'OtpOptionsSelector',
    components: { RadioButton },
    props: {
        otpOptions: {
            type: Array[Object],
            required: true,
        },
        selectedOtpOption: {
            type: String,
            required: true,
        },
    },
    methods: {
        ...mapMutations({
            setSelectedOtpOption: authMutation.SET_SELECTED_OTP_OPTION,
        }),
        changeOtpOptionMethod(method) {
            if (method.limitReached) return;

            this.$emit('change', method.name);
        },
        getIconName(optionName) {
            return IconNames[optionName] || '';
        },
        getOtpOptionName({ name, limitReached }) {
            const optionName = this.$t(`ui.accountVerification.otpOptionNames.${otpOptionsNames[name]}`);

            if (limitReached) return `${optionName} (${this.$t('ui.accountVerification.limitReached')})`;

            return optionName;
        },
    },
};
</script>

<style lang="scss" scoped>
.otp-options-selector-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 12px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &.disabled {
        color: $neutral-medium;
        fill: currentColor;
        cursor: default;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.otp-options-selector-content {
    display: flex;
    align-items: center;
    padding: 0;
    @extend %body-normal-font-400;
}

.otp-options-selector-radio-button {
    display: flex;
    align-items: center;
}

.otp-options-selector-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    svg {
        width: 100%;
        height: 100%;
    }
}

.otp-options-selector-name {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 18px;
}

.otp-options-selector-recommended {
    background-color: $green;
    padding: 2px 6px;
    border-radius: 2px;
    @extend %small-details-text;
}
</style>
