<template>
    <div class="casino-search">
        <CasinoPills class="casino-pills" />
        <div class="casino-search-container">
            <CasinoSearchInput ref="searchInput" :value="searchValue" @focus="showRecent" @blur="hideRecent" @input="startSearch" />
            <CasinoRecentSearches
                v-if="!isRecentSearchesHidden && recentSearches.length > 0"
                :recent-searches="recentSearches"
                class="casino-recent-searches"
                @select="startSearch"
                @remove="removeRecentSearch"
            />
            <CasinoCollectionGrid
                v-if="filteredGames.length > 0"
                :games="filteredGames"
                :games-count="filteredGames.length"
                :title="$t('ui.casinoLobby.search.result')"
                :column-count="columnCount"
                @open-casino-game="openCasinoGame"
            />
            <CasinoEmptyList v-else-if="gamesTotalCount" class="casino-empty-list">
                <CasinoSearchTips />
                <CasinoCollectionTitle :title="$t('ui.casinoLobby.search.popularGamesTitle')" />
            </CasinoEmptyList>
        </div>
    </div>
</template>

<script>
import CasinoPills from '@/components/content/CasinoPills.vue';
import CasinoSearchInput from '@/modules/casino/components/CasinoSearchInput.vue';
import CasinoRecentSearches from '@/modules/casino/components/CasinoRecentSearches.vue';
import { mapActions, mapGetters } from 'vuex';
import { action as casinoAction, getter as casinoGetter } from '@/store/modules/casino/const';
import { getObjectField } from '@/modules/core/utils/helper';
import { getCasinoGamesLimitForGridPerRow, sortGamesByPopular } from '@/modules/casino/utils/CasinoCollection-utils';
import { getter as platformGetter } from '@/modules/platform/store/const';
import CasinoSearchTips from '@/modules/casino/components/CasinoSearchTips.vue';
import CasinoEmptyList from '@/modules/casino/components/CasinoEmptyList.vue';
import CasinoCollectionTitle from '@/modules/casino/strapiComponents/components/CasinoCollectionTitle.vue';
import { getter as coreGetter } from '@/modules/core/store/const';
import CasinoCollectionGrid from '@/modules/casino/strapiComponents/components/CasinoCollectionGrid.vue';

const HIDE_RECENT_TIMEOUT = 500;

export default {
    name: 'CasinoSearch',
    components: {
        CasinoCollectionGrid,
        CasinoCollectionTitle,
        CasinoEmptyList,
        CasinoSearchTips,
        CasinoRecentSearches,
        CasinoSearchInput,
        CasinoPills,
    },
    data() {
        return {
            isRecentSearchesHidden: true,
            skipHideRecentSearches: false,
            filteredGames: [],
            searchValue: '',
        };
    },
    computed: {
        ...mapGetters({
            sideBarDisabledPages: platformGetter.GET_SIDE_BAR_DISABLED_PAGES,
            recentSearches: casinoGetter.GET_CASINO_RECENT_SEARCHES,
            isLoading: coreGetter.IS_LOADING,
            games: casinoGetter.GET_GAMES,
        }),
        isRightSideBarHidden() {
            return this.sideBarDisabledPages.includes(this.$route.path);
        },
        columnCount() {
            return getCasinoGamesLimitForGridPerRow(this.$mq, this.isRightSideBarHidden);
        },
        gamesTotalCount() {
            return getObjectField(this.games, 'data', []).length;
        },
    },
    mounted() {
        if (!this.isLoading(casinoAction.GET_CASINO_GAMES_NEW)) {
            this.$store.dispatch(casinoAction.GET_CASINO_GAMES_NEW);
        }
    },
    methods: {
        ...mapActions({
            addToCasinoRecentSearch: casinoAction.ADD_TO_CASINO_RECENT_SEARCH,
            removeFromCasinoRecentSearch: casinoAction.REMOVE_FROM_CASINO_RECENT_SEARCH,
        }),
        showRecent() {
            this.isRecentSearchesHidden = false;
        },
        hideRecent() {
            setTimeout(() => {
                if (this.skipHideRecentSearches) {
                    this.skipHideRecentSearches = false;
                    return;
                }
                this.isRecentSearchesHidden = true;
            }, HIDE_RECENT_TIMEOUT);
        },
        startSearch(value) {
            this.isRecentSearchesHidden = true;
            this.searchValue = value;
            this.filteredGames =
                value.length >= 2
                    ? getObjectField(this.games, 'data', [])
                          .filter((game) => game.name.toLowerCase().includes(value.toLowerCase()))
                          .sort(sortGamesByPopular)
                    : [];
        },
        removeRecentSearch(value) {
            this.skipHideRecentSearches = true;
            this.removeFromCasinoRecentSearch(value);
            this.$refs.searchInput.focus();
        },
        openCasinoGame() {
            this.addToCasinoRecentSearch(this.searchValue);
        },
    },
};
</script>

<style scoped lang="scss">
.casino-search {
    .casino-pills {
        margin: 12px 0;
    }
    .casino-search-container {
        .casino-recent-searches {
            padding: 0 12px;
            margin-top: 8px;
        }
    }
    .casino-empty-list {
        padding: 0 12px;
    }
}
</style>
