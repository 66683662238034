<template>
    <div class="dob" :class="{ error: $v && $v.maxAge.$dirty && !$v.dob.$anyError && $v.maxAge.$error }">
        <label :for="`${formName}-${name}-dob`" class="form" :class="labelClass">
            {{ $t('ui.dob.label') }}
        </label>
        <div :id="`${formName}-${name}-dob`" class="dob-wrapper table">
            <div
                v-for="({ key, options, type }, index) in dobGroup"
                :key="`${formName}-${name}-${key}-${index}`"
                class="dob-field row-cell"
            >
                <select
                    :id="`${formName}-${name}-${key}`"
                    v-model="dob[key]"
                    :name="key"
                    :type="type"
                    v-bind="attrs"
                    :disabled="disabled"
                    class="global-select"
                    :class="[$v && $v.dob[key].$dirty && ($v.dob[key].$error ? 'error' : 'valid')]"
                    @blur="blurEventHandler($event)"
                    @change="onInputChange($event.target.value, key)"
                    v-on="handlers"
                >
                    <option :value="null">
                        {{ $t(`ui.dob.${key}`) }}
                    </option>
                    <option v-for="({ value, name }, index) in options" :key="`day-${value}-${index}`" :value="value">
                        {{ name || value }}
                    </option>
                </select>
            </div>
        </div>
        <div v-if="helpText" class="help-text">
            {{ helpText }}
        </div>
        <div v-if="$v && $v.$error" class="dob-error">
            <renderer :input="error" />
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

const MAX_DAYS = 31;
const MIN_AGE = 18;
const MAX_AGE = 100;

export default {
    props: {
        attrs: {
            default: () => ({}),
        },
        handlers: {
            default: null,
        },
        formName: { required: true },
        name: { required: true },
        helpText: {
            type: [String, Boolean],
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        labelClass: {
            type: String,
            default: '',
        },
        disableOnInputValidation: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        dob: {
            day: null,
            month: null,
            year: null,
        },
    }),
    validations: {
        dob: {
            day: { required },
            month: { required },
            year: { required },
        },
        maxAge: {
            maxAge() {
                if (!this.dobIso) {
                    return false;
                }
                const [year, month, date] = this.dobIso.split('-');
                return new Date(+year + MIN_AGE, +(month - 1), +date).valueOf() <= Date.now();
            },
        },
    },
    computed: {
        dobGroup() {
            return [
                {
                    type: 'text',
                    key: 'day',
                    options: this.days,
                },
                {
                    type: 'text',
                    key: 'month',
                    options: this.months,
                },
                {
                    type: 'text',
                    key: 'year',
                    options: this.years,
                },
            ];
        },
        days() {
            const { month, year } = this.dob;
            const days = month && year ? new Date(year, month, 0).getDate() : MAX_DAYS;
            return Array.from(Array(days), (_, i) => ({ value: i + 1 }));
        },
        months() {
            const monthsOfTheYear = this.$t('monthsOfTheYear').split(',');
            return Array.from(monthsOfTheYear, (name, i) => ({ name, value: i + 1 }));
        },
        years() {
            const now = new Date().getFullYear();
            const from = now - MAX_AGE;
            const to = now - MIN_AGE;
            return Array.from({ length: (from - to) / -1 + 1 }, (_, i) => to + i * -1).map((value) => ({ value }));
        },
        dobIso() {
            const { day, month, year } = this.dob;
            if (day && month && year) {
                const prefix = (it) => (it < 10 ? '0' : '') + it;
                return `${year}-${prefix(month)}-${prefix(day)}`;
            } else {
                return null;
            }
        },
        error() {
            for (const param of Object.keys(this.$v.$params)) {
                if (this.$v[param].$invalid) {
                    return (
                        {
                            dob: this.$t('ui.dob.error.dob'),
                            maxAge: this.$t('ui.dob.error.maxAge'),
                        }[param] || this.$t('ui.common.form.error.required')
                    );
                }
            }
            return '';
        },
    },
    watch: {
        days(value) {
            if (!value.find(({ value }) => value === this.dob.day)) {
                this.dob.day = null;
            }
        },
        dobIso(date) {
            if (date) {
                this.$v.maxAge.$touch();
            }
        },
    },
    methods: {
        blurEventHandler() {
            this.$attrs.touchOnBlur && this.$v && this.$v.$touch();
        },
        onInputChange() {
            this.$v.dob.$touch();
            this.$emit('value', !this.$v.$invalid ? this.dobIso : '');
        },
    },
};
</script>
<style lang="scss" scoped>
.dob {
    margin-bottom: 16px;

    label {
        width: 100%;
    }

    &-field.row-cell {
        @include mq-xxs {
            padding: 10px 10px 0 0;
            display: block;

            &:last-child {
                display: table-cell;
            }
        }

        &:not(:last-child) {
            padding-right: 10px;
        }
    }

    &-field {
        // TODO: create SelectOptions component
        select {
            &.error {
                border-color: $error-red;
                border-width: $input-focus-error-border-width;

                &.warn {
                    background: $message-warning;
                }
            }

            &.valid {
                .error & {
                    border-color: $error-red;
                    border-width: $input-focus-error-border-width;
                }

                border-color: $greenish;
                border-width: $input-border-width;
            }

            &[disabled] {
                background: $light-grey;
                color: $grey-text;
            }

            option {
                background: $white-bg;
            }
        }
    }

    &-wrapper {
        table-layout: fixed;
        position: relative;
    }

    &-error {
        @extend %form-validation-error;
    }

    .help-text {
        font-size: 0.75rem;
        color: $disabled-text;
        margin-top: 4px;
    }
}
</style>
