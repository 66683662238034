<template>
    <div class="stepper-wrapper">
        <div
            v-for="({ id, titleKey }, index) in steps"
            :key="`Step-${id}`"
            class="stepper-item"
            :class="{ completed: id < activeStepId, next: id > activeStepId }"
        >
            <div>
                <SvgIcon v-if="index" icon-id="icon-line" class="stepper-line" />
            </div>

            <div class="stepper-pin">
                <template v-if="id >= activeStepId">
                    {{ id }}
                </template>
                <template v-else>
                    <SvgIcon icon-id="check" />
                </template>
            </div>

            <span class="stepper-title">{{ titleKey }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Stepper',
    props: {
        steps: {
            default: () => [],
            type: Array[Object],
        },
        activeStepId: {
            default: 1,
            type: Number,
        },
    },
};
</script>

<style scoped lang="scss">
.stepper-wrapper {
    @extend %small-details-font-500;

    display: flex;
    justify-content: center;
    background-color: $light-grey;
    padding: 12px 0;
    width: 100%;
}

.stepper-item {
    display: flex;
    align-items: center;
    border-radius: 0.5px;

    &.completed {
        .stepper-pin {
            background-color: $green;
        }
    }

    &.next {
        .stepper-pin {
            background-color: $medium-grey;
        }
        .stepper-title {
            color: $neutral-medium;
        }
    }
}

.stepper-line {
    height: 16px;
    margin: 0 8px;
}

.stepper-pin {
    width: 16px;
    height: 16px;
    background-color: $surface-success;
    border-radius: 8px;
    font-size: 10px;
    text-align: center;

    & svg {
        width: 100%;
        height: 100%;
        margin-bottom: 2px;
    }
}

.stepper-title {
    margin-left: 6px;
    @extend %small-details-font-400;
}
</style>
