<template>
    <div class="menu-contents" :class="menuClasses" :style="`max-height: ${maxHeight}px`">
        <LanguageSelector parent="menu" :horizontal="$mq.xxs" />
        <div
            v-for="(menuItem, index) in hasStrapiMenu ? topMenuLinks : menuContents"
            :key="menuItem.name + '-menu-item-' + index"
            :class="[isCurrentMenuItem(menuItem) && isHighlightEnabled(menuItem) ? 'selected' : '']"
        >
            <a
                :id="menuItem.id"
                :href="menuItem.url"
                :target="menuItem.target"
                :class="['menu-item', menuItem.style, isCurrentMenuItem(menuItem) ? 'bold' : '']"
                @click.prevent="onMenuItemSelect(menuItem)"
            >
                <SvgIcon v-if="menuItem.icon" class="icon-top-menu" :icon-id="menuItem.icon" />
                <span>{{ hasStrapiMenu ? menuItem.name : $t(menuItem.key, { pinOrPassword }) }}</span>
                <Badge v-if="menuItem.badge" :text="menuItem.badge" :type="menuItem.badgeType" :bg-color="menuItem.badgeColor" />
            </a>
        </div>
        <VersionApp class="version-app-topmenu" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { helper, deviceType, processMenu } from '@/modules/core';

import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as messagingGetter } from '@/modules/platform/store/modules/messaging/const';

import { getter as transGetter } from '@/store/modules/translations/const';

import LanguageSelector from '@/components/Fragments/LanguageSelector';
const VersionApp = () => import('@/components/VersionApp');
const Badge = () => import('@/components/Badge');

const DOWNLOAD_MENU_ITEM = 'android-app';

export default {
    name: 'MainMenu',
    components: { VersionApp, Badge, LanguageSelector },
    props: {
        menus: {
            type: Array,
            required: true,
        },
        config: {
            type: Object,
            required: false,
        },
        isBottom: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            routeName: '',
            isWebOrAndroidWeb: deviceType.isWebOrAndroidWeb(),
            maxHeight: null,
        };
    },
    computed: {
        ...mapGetters({
            pinOrPassword: transGetter.PIN_OR_PASSWORD,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            settings: platformGetter.GET_SETTINGS,
            currentUserStatus: platformGetter.GET_CURRENT_USER_STATUS,
            newMessagesNumber: messagingGetter.GET_NOTSEEN,
        }),
        hasStrapiMenu() {
            return !!this?.config;
        },
        menuContents() {
            return this.menus
                .map((item) =>
                    processMenu.processDefaultItemBadge({
                        item,
                        newMessagesNumber: this.newMessagesNumber,
                    })
                )
                .filter((item) => {
                    if (item.key === 'menus.changePassword') {
                        return;
                    }
                    if (item.enabler && !helper.pathChecker(item.enabler, this.settings)) {
                        return;
                    }
                    if (item.name === DOWNLOAD_MENU_ITEM && !this.isWebOrAndroidWeb) {
                        return;
                    }
                    return helper.processMenuAuth(item.requireAuth, this.isAuthenticated);
                });
        },
        topMenuLinks() {
            if (!this.hasStrapiMenu) return [];
            return helper.processStrapiMenuLinks({
                links: helper.getObjectField(this.config, 'links', []),
                userStatus: this.currentUserStatus,
                isAuthenticated: this.isAuthenticated,
                messagesCount: this.newMessagesNumber,
                router: this.$router,
            });
        },
        menuClasses() {
            return {
                bottom: this.isBottom,
            };
        },
    },
    mounted() {
        this.setMaxHeight();
        window.addEventListener('resize', this.setMaxHeight);
        window.addEventListener('orientationchange', this.setMaxHeight);

        if (this.isBottom) {
            this.$nextTick(() => {
                this.$el.scrollTop = this.$el.scrollHeight;
            });
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setMaxHeight);
        window.removeEventListener('orientationchange', this.setMaxHeight);
    },
    methods: {
        isCurrentMenuItem(menuItem) {
            if (this.hasStrapiMenu) {
                const page =
                    this.$route.path === '/'
                        ? '/'
                        : this.$route.path
                              .split('/')
                              .filter((segment) => !!segment)
                              .pop();

                return menuItem.links?.includes(page);
            }

            if (this.$route.path === '/') {
                return menuItem.url === this.$route.path;
            }
            return menuItem.url.includes(this.$route.path);
        },
        onMenuItemSelect(menuItem) {
            if (menuItem.url === this.$route.path) {
                return;
            }
            if (menuItem.name === 'My Bets') {
                this.$gtm.query({ event: this.isBottom ? 'bottom_nav_my_bets' : 'top_nav_my_bets' });
            }
            this.$gtm.query({
                event: this.isBottom ? 'bottom_menu_click' : 'top_nav',
                click_text: menuItem.name,
            });
            if (menuItem.externalUrl) {
                helper.openUrl(menuItem.externalUrl, menuItem.target);
                return;
            }
            this.$router.push({ path: menuItem.url });
        },
        setMaxHeight() {
            this.maxHeight = this.$scroll.getScroll().getBoundingClientRect()?.height || null;
        },
        isHighlightEnabled(menuItem) {
            return (this.hasStrapiMenu && !menuItem.disableHighlight) || !this.hasStrapiMenu;
        },
    },
};
</script>

<style lang="scss" scoped>
.menu-contents {
    background: $top-menu-dropdown-background-color;
    position: absolute;
    color: $main-text;
    right: 15px;
    width: 215px;
    z-index: 1001; /* Over sidebar */
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.33);
    overflow-y: auto;

    > div:not(:last-child) {
        border-bottom: $top-menu-dropdown-border;
    }

    &:not(.bottom, .menu-contents-large) {
        top: 100%;
    }

    &.bottom {
        bottom: 90px;
    }
}

.menu-item {
    @extend %menu_item;
    width: 100%;
    text-align: left;
    padding: 16px 16px 16px $menu-item-padding-left;

    &.language-switch {
        padding: 13px 5px 8px;
        display: block;
    }

    &:hover,
    &:active {
        background: $menu-over;
        svg.svg-icon {
            fill: $menu-item-color-selected;
        }
    }

    span {
        vertical-align: middle;
    }

    .badge {
        margin-left: 4px;
    }

    .selected & {
        border-left: $menu-item-border-width solid $menu-item-border-color;
        background: $menu-item-background-selected;
        color: $menu-item-color-selected;
        padding-left: ($menu-item-padding-left - $menu-item-border-width);

        &:hover {
            color: $menu-item-color-selected-hover;
            background: $menu-item-background-selected-hover;
        }

        svg.svg-icon {
            fill: $menu-item-color-selected;
        }
    }

    .icon-top-menu {
        max-width: 16px;
        max-height: 16px;
        margin-right: 8px;
        fill: $dark-green;
    }
}

.menu-contents-large {
    border-top: none;
    top: 66px;
}

.menu-contents-mobile {
    right: 0;
    width: 100%;
}
</style>
