<template>
    <ExpansionPanel
        :title="$t('ui.accountVerification.didNotGetVerificationCode')"
        :is-minimal="true"
        :expanded="expanded"
        class="otp-verification-expansion-wrapper"
    >
        <div class="otp-verification-expansion-change-method">
            <OtpOptionsSelector
                :otp-options="otpOptions"
                :selected-otp-option="selectedOtpOption"
                @change="$emit('change-option', $event)"
            />
            <button
                data-test-id="getVerificationCodeButton"
                class="button button-primary button-full"
                :disabled="isGetOtpCodeButtonDisabled"
                @click="$emit('resend-with-new-method')"
            >
                {{ $t('ui.accountVerification.getVerificationCode') }}
            </button>
        </div>
    </ExpansionPanel>
</template>

<script>
import ExpansionPanel from '@/modules/core/components/atoms/ExpansionPanel';
import OtpOptionsSelector from '@/modules/platform/components/Verification/OtpOptionsSelector';

export default {
    name: 'OtpVerificationExpansion',
    components: {
        ExpansionPanel,
        OtpOptionsSelector,
    },
    props: {
        expanded: Boolean,
        otpOptions: Array,
        selectedOtpOption: String,
        isGetOtpCodeButtonDisabled: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.otp-verification-expansion-wrapper {
    border-bottom: 1px solid $greyish;
}

.otp-verification-expansion-change-method {
    padding: 0 12px;

    & button {
        margin: 12px 0;
    }
}
</style>
