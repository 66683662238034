<template>
    <div
        v-click-outside:[true]="closeFilter"
        :class="[
            'event-filter',
            {
                open: activeFilterKey,
                'non-fullscreen-filter': activeFilter && !activeFilter.isFullscreen() && !isSortingOpened,
                'filter-hidden': hide,
                'event-filter-has-selected': hasSelectedItems,
            },
        ]"
    >
        <div class="event-filter-content">
            <div class="event-filter-head">
                <div
                    v-for="filter in filters"
                    :key="filter.key"
                    :data-test-id="`filters-${filter.key}`"
                    class="filter-item"
                    :class="{
                        'filter-item-grow': filter.text,
                        'filter-item-open': activeFilterKey === filter.key,
                        disabled: isFilterDisabled(filter.key),
                    }"
                    @click="changeFilter(filter)"
                >
                    <div v-if="filter.iconId" class="icon-wrapper">
                        <SvgIcon class="icon icon-size-medium" :icon-id="filter.iconId" />
                    </div>
                    <div v-if="filter.text" class="title">{{ filter.text }}</div>
                    <div class="state-icon-wrapper">
                        <SvgIcon
                            class="state-icon icon-size-very-small"
                            :icon-id="activeFilterKey === filter.key ? 'icon-arrow-up' : 'arrow_down'"
                        />
                    </div>
                </div>
                <div
                    v-if="isLive"
                    class="sort-item"
                    :class="{ 'sort-item-open': isSortingOpened, disabled: isFilterDisabled() }"
                    @click="toggleSorting"
                >
                    <div class="state-icon-wrapper">
                        <SvgIcon class="dropdown-icon icon-size-medium" icon-id="icon-sort" />
                    </div>
                </div>
            </div>
            <div v-if="activeSorting || activeFilterKey" class="event-filter-options-list event-filter-options-list-mobile">
                <div
                    class="options-list"
                    :class="{
                        'options-list-without-padding': activeFilter && !activeFilter.hasPaddings(),
                        'options-list-mobile': !desktopBehavior,
                    }"
                >
                    <template v-if="activeFilterKey === filterKeys.MARKET && marketGroups.length">
                        <EventRadioItem
                            v-for="(option, index) in marketGroups"
                            :key="index"
                            :data-test-id="`event-filter-market-${option.queryMarketId}`"
                            :radio-id="`options-${option.name}`"
                            :checked="option.isCurrent"
                            :label="option.label"
                            @click.native.prevent="handleMarketClick(option)"
                        />
                    </template>
                    <template v-if="activeFilterKey === filterKeys.DAY">
                        <EventRadioItem
                            v-for="(option, index) in eventFilterDaysList"
                            :key="index"
                            :data-test-id="`event-filter-day-${option.key}`"
                            :disabled="option.isDisabled"
                            :radio-id="`options-${option.key}`"
                            :checked="option.isCurrent"
                            :label="$t(`ui.common.days.${option.key}`)"
                            @click.native.prevent="handleDayClick(option)"
                        >
                            <template #extra>
                                <span class="radio-item-label align-right">{{ getEventsCountByDay(option.day) }}</span>
                            </template>
                        </EventRadioItem>
                    </template>
                    <FilterBySport
                        v-if="activeFilterKey === filterKeys.SPORT_TYPE"
                        :options="sportTypeList"
                        @click="handleSportTypeClick"
                    />
                    <FilterByTournament
                        v-if="activeFilterKey === filterKeys.TOURNAMENT"
                        :total-event-count="totalEventCount"
                        :tournaments="tournaments"
                        :competitions="competitions"
                        @resetFilters="resetFilters"
                        @allCheck="onAllCompetitionsCheck"
                        @check="onCompetitionCheck"
                    />
                    <EventSorting v-if="activeSorting" v-bind="sortingParams" @click="handleEventSortingClick" />
                </div>
                <div v-if="activeSorting || (activeFilter && activeFilter.hasFooter())" class="event-filter-actions">
                    <button v-if="activeSorting" class="button button-accent" @click="closeSorting">{{ $t('ui.common.cancel') }}</button>
                    <button v-else class="button button-accent" @click="resetFilters">{{ $t('ui.eventFilters.resetFilters') }}</button>
                    <button
                        :class="['button button-primary', { disabled: isApplyButtonDisabled }]"
                        :disabled="isApplyButtonDisabled"
                        @click="applyFilters"
                    >
                        {{ $t('ui.eventFilters.apply') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="event-filter-footer">
            <div class="event-filter-selected-list">
                <FilterSelectedItemList
                    :query-market="queryMarket"
                    @selectedItemsChanged="selectedItemsChanged"
                    @onRemove="removeCompetitions"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mutation, getter as generalGetter, action as storeAction } from '@/store/const';
import { FilterSelectedItemList, FilterBySport, FilterByTournament, EventSorting, EventRadioItem } from '@/modules/sport/components';
import { helper } from '@/modules/core';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { routeName } from '@/router/const-name';
import { getObjectField } from '@/modules/core/utils/helper';
import { getter as sportGetter } from '@/modules/sport/store/const';
import { DEFAULT_FILTER_BY_DAY, EventCountKeysByPage, EVENTS_SORTING_ACCEPTABLE_MAP } from '@/modules/sport/const/events-const';
import getEventFilterDaysList from '@/modules/sport/utils/event/get-event-filter-days-list';

export default {
    name: 'EventFilter',
    components: {
        EventRadioItem,
        EventSorting,
        FilterSelectedItemList,
        FilterBySport,
        FilterByTournament,
    },
    props: {
        desktopBehavior: {
            type: Boolean,
            required: false,
            default: false,
        },
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            filterKeys: {
                SPORT_TYPE: 'sport-type',
                TOURNAMENT: 'tournament',
                MARKET: 'market',
                DAY: 'day',
            },
            activeFilter: null,
            marketTypeId: '',
            selectedDay: DEFAULT_FILTER_BY_DAY,
            sorting: null,
            competitions: [],
            hasSelectedItems: false,
            isSortingOpened: false,
        };
    },
    computed: {
        ...mapState({
            tournaments: (state) => state.currentTournaments,
        }),
        ...mapGetters({
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            categories: generalGetter.GET_SORTED_CATEGORIES,
            marketTypeFilters: platformGetter.GET_MARKET_TYPE_FILTERS,
            eventsCountPerType: sportGetter.EVENTS_COUNT_PER_TYPE,
            eventsCountByDays: sportGetter.EVENTS_COUNT_BY_DAYS,
            eventsCountByCompetitionsByDays: sportGetter.EVENTS_COUNT_BY_COMPETITION_BY_DAYS,
            dateOptions: platformGetter.GET_DATE_OPTIONS,
        }),
        isApplyButtonDisabled() {
            return this.activeFilterKey === this.filterKeys.TOURNAMENT && !this.competitions.length;
        },
        eventCountKey() {
            return EventCountKeysByPage[this.$route.name];
        },
        sortingParams() {
            return {
                ...(this.sorting ? { sorting: this.sorting } : {}),
                categoryId: this.categoryId,
            };
        },
        eventFilterDaysList() {
            return getEventFilterDaysList(this.dateOptions.timeZoneOffset).map((item) => {
                item.isCurrent =
                    this.selectedDay === item.key ||
                    this.selectedDay === item.dayName ||
                    (!this.selectedDay && item.key === DEFAULT_FILTER_BY_DAY);
                item.isDisabled = !this.getEventsCountByDay(item.day);

                return item;
            });
        },
        filters() {
            return [
                {
                    iconId: this.$t(`project.sports.${this.categoryId}.icon`),
                    text: '',
                    key: this.filterKeys.SPORT_TYPE,
                    isFullscreen: () => false,
                    hasPaddings: () => true,
                    hasFooter: () => false,
                },
                {
                    iconId: '',
                    text: this.$t('ui.eventFilters.leagues'),
                    key: this.filterKeys.TOURNAMENT,
                    isFullscreen: () => true,
                    hasPaddings: () => false,
                    hasFooter: () => true,
                },
                {
                    iconId: '',
                    text: this.$t('ui.eventFilters.markets'),
                    key: this.filterKeys.MARKET,
                    isFullscreen: () => !this.$mq.mdUp,
                    hasPaddings: () => false,
                    hasFooter: () => true,
                },
                ...(this.isLive
                    ? []
                    : [
                          {
                              iconId: 'icon-calendar',
                              text: '',
                              key: this.filterKeys.DAY,
                              isFullscreen: () => this.$mq.isXMedium,
                              hasPaddings: () => false,
                              hasFooter: () => true,
                          },
                      ]),
            ];
        },
        isLive() {
            return this.$route.name === routeName.LIVE;
        },
        activeSorting() {
            return this.isLive && this.isSortingOpened;
        },
        marketGroups() {
            const currentFilters = this.marketTypeFilters.find(({ category }) => String(category) === String(this.categoryId));
            return currentFilters
                ? currentFilters.filters
                      .map((filter, index) => ({
                          name: 'filter-market',
                          label: filter.name,
                          queryMarketId: filter.name,
                          id: filter.marketType,
                          isCurrent: this.marketTypeId === filter.marketType,
                          index,
                          priority: filter.priority,
                      }))
                      .sort((a, b) => a.priority - b.priority)
                : [];
        },
        totalEventCount() {
            return this.eventsCount[this.categoryId];
        },
        eventsCount() {
            return getObjectField(this.eventsCountPerType, EventCountKeysByPage[this.$route.name], {});
        },
        sportTypeList() {
            return this.categories.map((category) => ({
                label: this.$t(`project.sports.${category.id}.text`),
                iconId: this.$t(`project.sports.${category.id}.icon`),
                id: category.id,
                isCurrent: String(category.id) === String(this.categoryId),
                count: this.eventsCount[category.id],
            }));
        },
        categoryId() {
            return this.$route.query?.categoryId || this.currentCategoryId;
        },
        queryMarket() {
            const { marketId } = this.$route.query;
            if (!marketId) {
                return this.marketGroups[0];
            }
            return (
                this.marketGroups.find(({ queryMarketId }) => {
                    return queryMarketId === marketId;
                }) || this.marketGroups[0]
            );
        },
        queryMarketId() {
            const { queryMarketId } =
                this.marketGroups.find(({ id }) => {
                    return String(id) === this.marketTypeId;
                }) || this.marketGroups[0];
            return queryMarketId;
        },
        isCompetitionsChanged() {
            const queryCompetitions = this.getCompetitionsFromUrl();
            const isSameCompetitions =
                this.competitions.length === queryCompetitions.length && this.competitions.every((i) => queryCompetitions.includes(i));

            return !isSameCompetitions;
        },
        activeFilterKey() {
            return this.activeSorting || (this.activeFilter && this.activeFilter.key);
        },
        disableUpcomingRedirect() {
            return getObjectField(this.$route, 'meta.disableUpcomingRedirect', false);
        },
    },
    watch: {
        '$route.query': {
            immediate: true,
            deep: true,
            handler({ marketId, day, sorting }) {
                if (marketId) {
                    const { id } =
                        this.marketGroups.find(({ queryMarketId }) => {
                            return queryMarketId === marketId;
                        }) || this.marketGroups[0];
                    this.marketTypeId = String(id);
                }
                this.selectedDay = day || DEFAULT_FILTER_BY_DAY;
                this.sorting = sorting || null;
                this.competitions = this.getCompetitionsFromUrl();
            },
        },
    },
    methods: {
        isFilterDisabled(key) {
            return key !== this.filterKeys.SPORT_TYPE && !this.totalEventCount;
        },
        selectedItemsChanged({ items }) {
            this.$emit('selectedItemsChanged', { items });
            this.hasSelectedItems = items.length > 0;
        },
        changeFilter(filter) {
            if (this.isFilterDisabled(filter.key)) {
                return;
            }
            if (filter.key !== this.activeFilterKey) {
                this.activeFilter = filter;
                this.isSortingOpened = false;
                this.$gtm.query({ event: 'event_filters_click', filter: filter.key });
            } else {
                this.closeFilter();
            }
        },
        toggleSorting() {
            if (this.isFilterDisabled()) {
                return;
            }

            this.isSortingOpened = !this.isSortingOpened;

            if (this.isSortingOpened) {
                this.closeFilter();
            }
        },
        handleMarketClick(item) {
            const { id } = item;
            this.marketTypeId = String(id);
        },
        handleSportTypeClick(item) {
            this.$router
                .push({ name: this.disableUpcomingRedirect ? this.$route.name : routeName.UPCOMING, params: { categoryId: item.id } })
                .then(() => {
                    this.$store.commit(mutation.SET_CURRENT_CATEGORY, item.id);
                    this.$store.dispatch(storeAction.GET_CATEGORY_ACTIVE_REGIONS, item.id);
                    this.closeFilter();
                });
        },
        handleEventSortingClick(sorting) {
            this.sorting = sorting;
        },
        countEvents(total, item) {
            if (this.$route.name === routeName.UPCOMING) {
                return total + getObjectField(item.eventCounts, 'upcoming', 0) + getObjectField(item.eventCounts, 'live', 0);
            }
            return total + getObjectField(item.eventCounts, this.eventCountKey, 0);
        },
        getEventsCountByDay(day) {
            if (this.competitions.length > 0) {
                if (day === DEFAULT_FILTER_BY_DAY) {
                    return Object.values(this.eventsCountByCompetitionsByDays)
                        .map((list) => list.filter((competition) => this.competitions.includes(competition.id)))
                        .reduce((total, item) => total + item.reduce(this.countEvents, 0), 0);
                }

                return getObjectField(this.eventsCountByCompetitionsByDays, day, [])
                    .filter((item) => this.competitions.includes(item.id))
                    .reduce(this.countEvents, 0);
            }
            return getObjectField(getObjectField(this.eventsCountByDays, day, {}), this.eventCountKey, 0);
        },
        handleDayClick(item) {
            if (this.getEventsCountByDay(item.day)) {
                this.selectedDay = item.key;
            }
        },
        onAllCompetitionsCheck({ checked, competitions }) {
            const countryCompetitions = competitions.map((i) => i.id);
            if (checked) {
                this.competitions = helper.removeArrayDuplicates(this.competitions, countryCompetitions);
            } else {
                this.competitions = this.competitions.filter((i) => !countryCompetitions.includes(i));
            }
        },
        onCompetitionCheck({ checked, competition }) {
            const { id } = competition;
            if (checked) {
                this.competitions.push(id);
            } else {
                this.competitions = this.competitions.filter((i) => i !== id);
            }
        },
        removeCompetitions({ competitions, isMarket, isDay, isSorting }) {
            if (isMarket) {
                this.handleMarketClick(this.marketGroups[0]);
            }
            if (competitions && competitions.length) {
                this.competitions = this.competitions.filter((i) => !competitions.includes(i));
            }
            if (isSorting) {
                this.sorting = '';
            }
            if (isDay) {
                this.selectedDay = '';
            }
            this.applyFilters();
        },
        resetFilters() {
            const competitions = this.competitions;
            if (this.activeFilterKey === this.filterKeys.MARKET) {
                this.removeCompetitions({ isMarket: true });
            } else if (this.activeFilterKey === this.filterKeys.TOURNAMENT) {
                this.removeCompetitions({ competitions });
            } else if (this.activeFilterKey === this.filterKeys.DAY) {
                this.removeCompetitions({ isDay: true });
            }

            this.closeFilter();
        },
        isSamePathAndQuery({ name, query }) {
            const { name: currentName, query: currentQuery } = this.$route;

            return name === currentName && JSON.stringify(currentQuery || {}) === JSON.stringify(query || {});
        },
        applyFilters() {
            const { name, query } = this.$route;
            const newQuery = {
                ...query,
                marketId: this.queryMarketId,
            };
            if (this.competitions.length) {
                newQuery.competitions = this.competitions.join(',');
            } else {
                delete newQuery.competitions;
            }
            if (this.selectedDay && this.selectedDay !== DEFAULT_FILTER_BY_DAY) {
                newQuery.day = this.selectedDay;
            } else {
                delete newQuery.day;
            }
            if (this.sorting) {
                newQuery.sorting = this.sorting;
            } else if (this.isSortingOpened) {
                newQuery.sorting = EVENTS_SORTING_ACCEPTABLE_MAP.competitionPriority_DESC;
            } else {
                delete newQuery.sorting;
            }
            if (this.isCompetitionsChanged) {
                delete newQuery.id;
            }

            if (!this.isSamePathAndQuery({ name, query: newQuery })) {
                const routeConfig = { query: newQuery };
                if (this.isCompetitionsChanged && !this.disableUpcomingRedirect) {
                    routeConfig.name = routeName.UPCOMING;
                }

                this.$gtm.query({
                    event: 'event_filters_request',
                    competitions: newQuery.competitions || '',
                    marketId: this.marketTypeId,
                    categoryId: newQuery.categoryId,
                    day: newQuery.day || '',
                });
                this.$router.replace(routeConfig);
            }

            this.closeFilter();
            this.closeSorting();
        },
        closeFilter() {
            this.activeFilter = null;
        },
        closeSorting() {
            this.isSortingOpened = false;
        },
        getCompetitionsFromUrl() {
            const queryParams = new URLSearchParams(window.location.search);
            const competitions = queryParams.get('competitions');
            let payloadCompetitions = [];
            if (competitions) {
                payloadCompetitions = Array.isArray(competitions) ? competitions : competitions.split(',');
            }
            return payloadCompetitions;
        },
    },
};
</script>

<style lang="scss" scoped>
$filter-height: 40px;
$filter-height-with-selected: 93px;

.event-filter {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    transition: margin-top 0.3s linear;
    overflow: hidden;
    height: $filter-height;

    @include mq-md-up {
        position: relative;
    }

    ::v-deep {
        & ~ .section-middle {
            transition: margin-top 0.3s linear;
            margin-top: #{$filter-height};

            @include mq-md-up {
                transition: none;
                margin-top: 0px;
            }
        }
    }

    &.filter-hidden {
        transition: margin-top 0.3s linear;
        margin-top: calc(-1 * #{$filter-height});

        ::v-deep {
            & ~ .section-middle {
                margin-top: 0px;
            }
        }
    }

    &-has-selected {
        height: $filter-height-with-selected;

        ::v-deep {
            & ~ .section-middle {
                transition: margin-top 0.3s linear;
            }
        }

        &:not(.filter-hidden) {
            ::v-deep {
                & ~ .section-middle {
                    margin-top: #{$filter-height-with-selected};
                    @include mq-md-up {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &-head {
        display: flex;
        flex: 0 0 40px;
        overflow: auto;
    }

    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &-actions {
        display: flex;
        padding: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: $white-bg;
    }

    &-options-list {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
        width: 100%;
        background-color: $white-bg;

        &-mobile {
            display: flex;
            flex-direction: column;
        }
    }

    &-selected-list {
        border-bottom: 1px solid #e6e7e2;
    }

    &.open {
        height: 100%;
        z-index: 10;
        &:not(.non-fullscreen-filter) .event-filter-content {
            height: 100%;
        }

        .event-filter-head {
            width: 100%;
        }

        .event-filter-options-list {
            flex: 1 1 auto;
            overflow: auto;
            box-shadow: none;
        }

        .event-filter-actions {
            flex: 0 0 50px;
        }
    }

    &.non-fullscreen-filter {
        height: auto;
        overflow: visible;

        .event-filter-options-list {
            position: absolute;
            top: 100%;
            z-index: 1;
        }
    }
}

.sort-item,
.filter-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f5f0;
    border: 1px solid #e6e7e2;
    padding: 10px 12px 10px 16px;
    cursor: pointer;
    line-height: 16px;

    @include mq-xxs {
        padding: 10px 8px;
    }

    &-grow {
        flex-grow: 1;
    }

    &-open {
        background-color: $white-bg;
        border-bottom-color: $white-bg;
    }
    &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        color: $dark-grey-3;
        fill: $dark-grey-3;
    }
}

.state-icon-wrapper {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.options-list {
    padding: 8px;
    overflow: auto;
    overscroll-behavior-y: none;

    &-without-padding {
        padding: 0;
    }

    &-mobile {
        flex-grow: 1;
    }
    &::v-deep {
        .radio-item:first-child {
            border-top: none;
        }
    }
}

.title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
}

.icon {
    margin-right: 4px;
    display: inline-block;
}

.button {
    width: 50%;
    max-width: none;

    & + & {
        margin-left: 8px;
    }
}
</style>
