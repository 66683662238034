import Vue from 'vue';
import { MQ } from './media-query';
import Scroll from './scroll';
import ClickOutside from './click-outside';
import Intersection from './intersection-observer';

Vue.use(MQ);
Vue.use(Scroll);
Vue.use(ClickOutside);
Vue.use(Intersection);
