// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Vuex from 'vuex';
import { filters } from '@/modules/core';
import { MQConstants } from '@/js/directives/media-query';

import App from './App.vue';
import router from './router';
import store from './store/store';
import 'intersection-observer/intersection-observer'; // Polyfill for IntersectionObserver
import './js/directives';
import './js/plugins';
import './js/globalComponents';
import './js/polyfills';

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(filters.Currency);
const UP_LABEL = 'Up';

const app = new Vue({
    el: '#app',
    store,
    router,
    components: { App },
    template: '<App />',
    mq: {
        [MQConstants.XXS]: '(min-width: 320px) and (max-width: 375px)',

        [MQConstants.XS]: '(min-width: 376px) and (max-width: 599px)',
        [MQConstants.XS + UP_LABEL]: '(min-width: 376px)',

        [MQConstants.SM]: '(min-width: 600px) and (max-width: 767px)',
        [MQConstants.SM + UP_LABEL]: '(min-width: 600px)',

        [MQConstants.MD]: '(min-width: 768px) and (max-width: 959px)',
        [MQConstants.MD + UP_LABEL]: '(min-width: 768px)',

        [MQConstants.LG]: '(min-width: 960px)',
        [MQConstants.X2RATIO]: '(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2)',
    },
});

export default app;
