<template>
    <div data-test-class="betSlipStatus" class="placed-betslip">
        <div class="placed-betslip-content">
            <SvgIcon icon-id="icon-circle-checked" class="check-icon" />
            <h2 class="content-title">
                {{ $t('ui.betslip.betPlacedTitle') }}
            </h2>
            <div class="content-bet-placed-text">
                {{ betPlacedText }}
            </div>
            <div class="open-details-container">
                <span>{{ $t('ui.betslip.betPlacedLinkOpen') }}&nbsp;</span>
                <router-link
                    :to="{
                        name: routeName.BETSLIP,
                        params: { id: actualBetslipId, betslipType: currentBetslipType },
                    }"
                    class="underline open-details-container-link"
                    @click.native="openPlacedBetslip"
                >
                    {{ $t('ui.betslip.betPlacedLink') }}
                </router-link>
            </div>
            <div v-if="sportProgressiveJackpot && !isVirtualBetslip" class="pjp-message">
                <span class="pjp-message-icon">
                    <SvgIcon icon-id="message-operational" class="icon-size-medium" vertical-align="text-top" />
                </span>
                <renderer :input="$t('ui.pjp.placedBetslipMessage')" />
                <router-link class="underline pjp-message-link" :to="progressiveJackpotLink">
                    {{ $t('ui.betslip.readMore') }}
                </router-link>
            </div>
            <button class="button button-primary another-bet-button" data-test-class="placeAnotherBet" @click="placeAnotherBet">
                {{ $t('ui.betslip.placeAnotherBet') }}
            </button>
            <a v-if="!isVirtualBetslip" class="underline reuse-selection-link" @click="resetSlipStatus">
                {{ $t('ui.betslip.reUseSelections') }}
            </a>
            <div v-if="isAutoCashOutLinkVisible" class="auto-cashout-link">
                <Badge :text="$t('ui.common.new')" type="new" />
                <router-link
                    :to="{
                        name: routeName.BETSLIP,
                        params: { id: actualBetslipId, betslipType: currentBetslipType },
                        query: { activeTab: 'auto-cashout' },
                    }"
                    :class="['underline', isAnyBetNonCashOuTable ? 'disabled-link' : '']"
                    @click.native="trackAutoCashOutClick"
                >
                    <span>{{ $t('ui.cashout.autoCashout') }}</span>
                </router-link>
            </div>
        </div>
        <div v-if="isShareButtonAvailable" class="placed-betslip-footer">
            <button class="button button-accent row-cell align-middle" :disabled="sharingLoading" @click="openBetSharingModal">
                {{ sharingLoading ? $t('ui.common.loading') : $t('ui.betslip.shareYourBet') }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getter as coreGetter } from '@/modules/core/store/const';
import { betslipType } from '@/modules/sport';
import { action as betslipAction, getter as betslipGetter, mutation as betslipMutation } from '@/modules/sport/store/modules/betslip/const';
import { progressiveJpType, progressiveJpPagePath } from '@/modules/platform/const/progressive-jackpot';

import { action } from '@/store/const';

import { COPIED_LINK_NOTIFICATION_DURATION } from '@/const/notification';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { routeName } from '@/router/const-name';
import Badge from '@/components/Badge.vue';

export default {
    name: 'PlacedBetslip',
    components: { Badge },
    data() {
        return {
            betslipType,
            routeName,
            isCopiedLinkMessageVisible: false,
            showSpinner: true,
            sharingLoading: false,
        };
    },
    computed: {
        ...mapState({
            betslipId: (state) => state.sport.betslip.regular.status.betslipId, // getter BP-16141
            virtualBetslipId: (state) => state.sport.betslip.virtual.status.betslipId, // getter BP-16141
        }),
        ...mapGetters({
            bookingError: betslipGetter.GET_BETSLIP_BOOKING_ERROR,
            bookingCode: betslipGetter.GET_BETSLIP_BOOKING_CODE,
            shareLinks: betslipGetter.GET_SHARE_LINKS,
            isVirtualBetslip: betslipGetter.IS_VIRTUAL_BETSLIP,
            isLoading: coreGetter.IS_LOADING,
            isAutoCashOutEnabled: platformGetter.IS_AUTO_CASHOUT_ENABLED,
            user: platformGetter.GET_USER_SETTINGS,
            bets: betslipGetter.GET_BETS,
            isCashOutAvailable: platformGetter.IS_CASHOUT_AVAILABLE,
            selectionIds: betslipGetter.GET_SELECTION_IDS,
            settings: platformGetter.GET_SETTINGS,
            sportProgressiveJackpot: platformGetter.GET_SPORT_PROGRESSIVE_JP_FROM_SETTINGS,
            cutPlusCalculation: betslipGetter.CUT_PLUS_CALCULATION,
        }),
        isShareButtonAvailable() {
            return !this.isVirtualBetslip;
        },
        actualBetslipId() {
            return this.isVirtualBetslip ? this.virtualBetslipId : this.betslipId;
        },
        isAnyBetNonCashOuTable() {
            return this.bets.some((bet) => !bet.market.cashoutable);
        },
        isAutoCashOutLinkVisible() {
            return !this.isVirtualBetslip && this.isCashOutAvailable && this.isAutoCashOutEnabled;
        },
        progressiveJackpotLink() {
            return { path: progressiveJpPagePath[progressiveJpType.SPORT] };
        },
        currentBetslipType() {
            return this.isVirtualBetslip ? betslipType.VIRTUAL : betslipType.REGULAR;
        },
        betPlacedText() {
            if (!this.cutPlusCalculation || !this.cutPlusCalculation.allOffers.length > 0) {
                return this.$t('ui.betslip.betPlaced');
            }
            if (this.isVirtualBetslip) {
                return this.$t('ui.betslip.betPlacedCutPlusVirtual');
            } else {
                // should be another text in Cut+ for REAL implementation
                return this.$t('ui.betslip.betPlaced');
            }
        },
    },
    methods: {
        openPlacedBetslip() {
            // Pretend the betslip was never open so the router hook
            // won’t re-open it on <Back>, see BP-11731
            this.$store.dispatch(action.SET_BETSLIP_STATE, false); // rename sidebar action BP-16141

            this.$store.commit('sport/setAfterBetPlaced', true);

            this.$emit('resetBetslip');
        },
        placeAnotherBet() {
            this.$emit('resetBetslip');
            this.$store.dispatch(action.SET_BETSLIP_STATE, false); // rename sidebar action BP-16141
        },
        resetSlipStatus() {
            const type = this.isVirtualBetslip ? betslipType.VIRTUAL : betslipType.REGULAR;
            this.$store.commit(betslipMutation.RESET_BETSLIP_STATUS, type);
            this.$gtm.query({ event: 'bet_reuse' });
        },
        setCopiedLinkMessageVisible() {
            if (this.isCopiedLinkMessageVisible) return;
            this.isCopiedLinkMessageVisible = true;

            setTimeout(() => {
                this.isCopiedLinkMessageVisible = false;
            }, COPIED_LINK_NOTIFICATION_DURATION);
        },
        openBetSharingModal() {
            this.$gtm.query({ event: 'placed_bet_slip_booking_code_create' });
            this.sharingLoading = true;
            const shareTextKey = 'social.postBetShareText';
            this.$store
                .dispatch(betslipAction.LOAD_SHARE_LINKS, {
                    selectionIds: this.selectionIds,
                    shareText: this.$t(shareTextKey),
                })
                .finally(() => {
                    this.sharingLoading = false;
                    this.$modal.show('bet-sharing-modal', { shareTextKey });
                });
        },
        trackAutoCashOutClick() {
            this.$gtm.query({
                event: 'auto_cashout_offer',
                userUuid: this.user.userUuid,
                click: 'link_click_from_post_bet_screen',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$elements-vertical-spacing: 16px;

.placed-betslip {
    a {
        @extend %link-regular-font;
    }

    .placed-betslip-content {
        padding: $elements-vertical-spacing;
        text-align: center;
        background: linear-gradient(180deg, #fff 0%, rgba(217, 237, 178, 0.3) 100%);
        border-bottom: 1px solid #d9edb2;

        .check-icon {
            margin-bottom: $elements-vertical-spacing;
            width: 40px;
            height: 40px;
            fill: $green-success;
        }

        .content-title {
            margin-bottom: 0;
        }

        .content-bet-placed-text {
            @extend %body-normal-font-400;
            margin-bottom: $elements-vertical-spacing;
        }

        .open-details-container {
            @extend %body-normal-font-400;
            margin-bottom: $elements-vertical-spacing;

            &-link {
                display: inline-block;
            }
        }

        .another-bet-button {
            margin-bottom: $elements-vertical-spacing;
        }

        .reuse-selection-link {
            margin-bottom: $elements-vertical-spacing;
            display: block;
            cursor: pointer;
        }
    }

    .placed-betslip-footer {
        @extend %body-normal-font-400;
        padding: 12px;
        text-align: center;

        .get-booking-code {
            margin-top: 24px;
            display: block;
            cursor: pointer;
        }

        .copy-text-button {
            margin-top: 24px;
        }
    }

    .notify {
        margin: 12px;
    }

    .copied-link-message {
        margin: 16px 16px 0 16px;
        text-align: left;
    }

    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .placed-betslip-content {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: auto;

        @include max-height(500px) {
            display: block;
        }
    }
}

.button {
    text-align: center;
    white-space: pre-wrap;
}

.auto-cashout-link {
    svg {
        margin-right: 5px;
    }
}

.disabled-link {
    @include skip_element;
    color: $disabled-text;
}

.pjp-message {
    margin-bottom: $elements-vertical-spacing;
    @extend %body-normal-font-400;

    &-icon {
        margin-right: 4px;
        width: 16px;
        height: 16px;

        & svg {
            fill: $green-success;
        }
    }

    &-link {
        display: block;
        margin-top: 4px;
    }
}
</style>
