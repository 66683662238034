<template>
    <div>
        <ExpansionPanel
            v-for="(menu, countryIndex) in countryMenus"
            :key="`${menu.slug}-${countryIndex}`"
            :data-test-id="`menu-${menu.name}`"
            :title="$t(`ui.lMenu.${menu.name}`)"
            control
            :expanded="true"
            :is-dark="isDark"
        >
            <slot :menu="menu">
                <template v-for="(country, menuIndex) in menu.data">
                    <MenuItem
                        :key="`${menu.slug}-country-${country.region.id}-${menuIndex}`"
                        class="sub-menu"
                        :data-test-id="`country-menu-${country.region.id}`"
                        :selected="country.isActive"
                        :is-dark="isDark"
                        :item="{
                            text: country.region.name,
                            control: {
                                text: getEventsCount(country),
                                icon: isExpandedCountry(country.region.id) ? 'icon-arrow-up' : 'arrow_down',
                            },
                        }"
                        @onItemClick="toggleCountry(country.region.id, country.region.name, `${menu.slug}_country_nav_expand`)"
                    >
                        <template slot="icon">
                            <CountryFlag
                                :iso-code="country.icon.isoCode"
                                :img-src="country.icon.src"
                                :img-alt-src="country.icon.altSrc"
                                class="rounded-flag icon-size-large icon-flag"
                                is-squared
                            />
                        </template>
                        <template slot="content">
                            <ExpansionPanel v-if="country.competitions" :is-dark="isDark" :expanded="isExpandedCountry(country.region.id)">
                                <MenuItem
                                    class="sub-menu-item"
                                    :data-test-id="`country-${country.region.id}-all`"
                                    :selected="country.isSelected"
                                    :link="{ name: routeCountryName, params: { id: country.region.id } }"
                                    :item="{
                                        text: $t('menus.all'),
                                        control: { text: getEventsCount(country), iconDisabled: true },
                                    }"
                                    :is-dark="isDark"
                                    @click.native="
                                        onMenuItemClick({ element: `All ${country.region.name}`, event: `${menu.slug}_country_nav` })
                                    "
                                />
                                <MenuItem
                                    v-for="(competition, competitionIndex) in country.competitions"
                                    :key="`${menu.slug}-country-${competition.slug}-${competitionIndex}`"
                                    class="sub-menu-item"
                                    :data-test-id="`country-${country.region.id}-${competition.id}`"
                                    :selected="competition.isSelected"
                                    :link="{ name: routeGroupName, params: { id: competition.id } }"
                                    :item="{
                                        text: competition.name,
                                        control: { text: getEventsCount(competition), iconDisabled: true },
                                    }"
                                    :is-dark="isDark"
                                    @click.native="onMenuItemClick({ element: `${competition.name}`, event: `${menu.slug}_country_nav` })"
                                />
                            </ExpansionPanel>
                        </template>
                    </MenuItem>
                </template>
            </slot>
        </ExpansionPanel>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { MenuItem, ExpansionPanel } from '@/modules/core/components';
import { mutation } from '@/store/const';
import { routeName } from '@/router/const-name';
import CountryFlag from '@/modules/platform/components/CountryFlag.vue';
import { getObjectField } from '@/modules/core/utils/helper';
import { EventCountKeysByPage } from '@/modules/sport/const/events-const';

const EXCLUSIVE_COUNTRY_ICONS = ['africa', 'asia', 'south-africa', 'south-america', 'europe'];

const CONTINENT_FILTER = ['africa', 'asia', 'america'];

export default {
    name: 'CountryMenus',
    components: { CountryFlag, MenuItem, ExpansionPanel },
    props: {
        isDark: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            routeCountryName: routeName.COUNTRY,
            routeGroupName: routeName.GROUP,
            currentRoute: {
                name: '',
                id: null,
                category: null,
            },
        };
    },
    computed: {
        ...mapState({
            countries: (state) => state.countries,
            expandedCountries: (state) => state.ui.leftMenuExpandedCountries,
        }),
        countriesPopularMenu() {
            const popularCountries = Array.from(this.countries).filter(
                (country) => country.priority > 0 && this.hasEventsInCountry(country)
            );
            return this.updateCountriesList(popularCountries);
        },
        countriesOtherMenu() {
            const otherCountries = Array.from(this.countries).filter(
                (country) => country.priority === 0 && this.hasEventsInCountry(country)
            );
            return this.updateCountriesList(otherCountries);
        },
        countryMenus() {
            const list = [
                {
                    name: 'popularCountries',
                    data: this.countriesPopularMenu,
                    slug: 'popular',
                },
                {
                    name: 'otherCountries',
                    data: this.countriesOtherMenu,
                    slug: 'other',
                },
            ];
            return list.filter((item) => item.data.length);
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.$data.currentRoute = { name: to.name, id: to.params.id, category: to.query.category };
            },
        },
    },
    methods: {
        hasEventsInCountry(country) {
            return this.getEventsCount(country) > 0;
        },
        updateCountriesList(countries) {
            if (!countries?.length) {
                return [];
            }

            const currentRouteId = this.currentRoute.id;

            return countries
                .map((country) => {
                    const isSelected = currentRouteId === country?.region.id;
                    country = {
                        ...country,
                        isSelected,
                        isActive: isSelected,
                    };
                    const competitions = country.competitions.map((competition) => {
                        const isSelected = currentRouteId === competition.id;
                        if (isSelected) country.isActive = isSelected;
                        return { ...competition, isSelected };
                    });

                    country.competitions = [...competitions];

                    const parentSlug = country.region?.parentSlug;
                    const updatedParentSlug =
                        (parentSlug && !EXCLUSIVE_COUNTRY_ICONS.includes(parentSlug)) ||
                        (parentSlug && CONTINENT_FILTER.find((it) => parentSlug.includes(it))) ||
                        (parentSlug && parentSlug.includes('europe') && 'international') ||
                        parentSlug;

                    country.region = { ...country.region, updatedParentSlug };
                    return country;
                })
                .filter((country) => {
                    return this.hasEventsInCountry(country);
                });
        },
        toggleCountry(countryId, name, event) {
            this.trackMainNav({ element: `expand-${name}`, event });
            this.$store.commit(mutation.TOGGLE_LEFT_MENU_COUNTRY, countryId);
        },
        isExpandedCountry(countryId) {
            return this.expandedCountries.includes(countryId);
        },
        trackMainNav({ element, event = 'main_nav' }) {
            if (element) {
                this.$gtm.query({
                    event,
                    click_text: element,
                });
            }
        },
        getEventsCount(event) {
            return getObjectField(event, EventCountKeysByPage[this.$route.name] ?? EventCountKeysByPage[routeName.UPCOMING], 0);
        },
        onMenuItemClick(trackMainNav) {
            this.$emit('onMenuItemClick', trackMainNav.element);
        },
    },
};
</script>

<style></style>
