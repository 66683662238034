<template>
    <div>
        <CashOutTabs v-if="isAutoCashOutEnabled" :hide-tab-content="hideTabContent" @tabChanged="cashOutActiveTabKey = $event" />
        <OldCashOut v-else-if="!hideTabContent" />

        <!--   Common behaviour for both cashout tabs and old cashout -->
        <div v-if="hideTabContent" class="cashout-request-offer" :class="{ 'old-cashout-request-offer': !isAutoCashOutEnabled }">
            <div class="cashout-request-message">
                <renderer :input="cashoutLockedErrorMessage" />
            </div>
            <div class="cashout-request-action">
                <button disabled class="button button-primary offer-button button-full countdown-button">
                    <Countdown class="cashout-request-countdown" theme="number" :start="startCounter" :refresh-rate="countdownRate" />
                    <span>{{ $t('ui.cashout.retrievingUpdate') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import CashOutTabs from '@/modules/sport/components/Fragments/Cashout/CashOutTabs.vue';
import OldCashOut from '@/modules/sport/components/Fragments/Cashout/OldCashOut.vue';
import { mapGetters } from 'vuex';
import { getter as platformGetter } from '@/modules/platform/store/const';
import Countdown from '@/components/Countdown.vue';
import { cashOutType } from '@/modules/sport/const/cashout-const';
import { getter as sportGetter } from '@/modules/sport/store/const';

export default {
    name: 'CashoutWrapper',
    components: { Countdown, OldCashOut, CashOutTabs },
    data() {
        return {
            cashOutActiveTabKey: cashOutType.CASHOUT,
            startCounter: new Date(),
        };
    },
    computed: {
        ...mapGetters({
            isAutoCashOutEnabled: platformGetter.IS_AUTO_CASHOUT_ENABLED,
            requestOfferError: sportGetter.GET_CASHOUT_OFFER_ERRORS,
            offerPolling: sportGetter.GET_CASHOUT_OFFER_POLLING,
        }),
        countdownRate() {
            return this.offerPolling.pollingSeconds * this.offerPolling.initialRequestLimit;
        },
        cashoutLockedErrorMessage() {
            return this.cashOutActiveTabKey === cashOutType.CASHOUT
                ? this.$t('errors.CASHOUT_OFFLINE_PRICES')
                : this.$t('errors.AUTO_CASHOUT_OFFLINE_PRICES');
        },
        hideTabContent() {
            return this.offerPolling.pollingEnabled && !this.requestOfferError.dead && !this.requestOfferError.isNetworkError;
        },
    },
};
</script>

<style scoped lang="scss">
.cashout-request {
    &-offer {
        border: 1px solid $golden-brown;
        padding: 12px;
        background-color: $white-bg;
        flex-direction: column;
        align-items: flex-start;
    }

    &-countdown {
        margin-right: 8px;
        white-space: nowrap;
    }

    &-action {
        margin-top: 8px;
        .countdown-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 20px;
        }
    }

    &-message {
        @extend %body-normal-font-500;
    }
}

.old-cashout-request-offer {
    background-color: transparent;
    padding: 8px;
    margin-top: 8px;
}
</style>
