<template>
    <span v-if="isLocked" class="event-bet-wrapper" :class="{ 'event-bet-wrapper-hidden': price === 'placeholder' }">
        <span class="event-bet">
            <span class="anchor-wrap">
                <span class="event-selection event-selection_locked">{{ price }}</span>
                <span class="event-odds event-odds_locked">
                    <SvgIcon class="icon-size-medium" icon-id="icon-lock" />
                </span>
            </span>
        </span>
    </span>
    <span v-else-if="price" class="event-bet-wrapper" :data-test-id="'Odd-' + marketTypeId + '-' + price.typeId">
        <span :class="['event-bet', { selected: selected }]">
            <span v-if="!price.price" class="anchor-wrap">
                <span class="event-selection event-selection_locked" data-test-id="selection">
                    {{ eventSelection }}
                </span>
                <span class="event-odds event-odds_locked">
                    <SvgIcon class="icon-size-medium" icon-id="icon-lock" />
                </span>
            </span>
            <span
                v-else
                :id="'Bp-Price-' + marketTypeId + '-' + price.id"
                class="anchor-wrap"
                :class="[{ 'event-page': isEventRoute }, { multiline: isMultiline }]"
                @click="$emit('onPriceClick', price)"
            >
                <span class="event-selection" data-test-id="selection">
                    {{ eventSelection }}
                </span>
                <span class="event-odds" data-test-id="value">
                    <SvgIcon v-if="price.suspended" class="price-icon" icon-id="icon-lock" />
                    <template v-else>
                        <SvgIcon v-if="isHot" icon-id="icon-hot" class="price-icon icon-hot" />
                        <span>{{ $numberFormat(Number(price.price)) }}</span>
                    </template>
                </span>
            </span>
        </span>
    </span>
    <span v-else class="event-bet-wrapper event-bet-wrapper-hidden" />
</template>

<script>
import { helper } from '@/modules/core';

export default {
    name: 'BetPrice',
    props: {
        price: {
            type: [String, Object], // string for case: isLocked === true
            required: false,
        },
        isLocked: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        isEventRoute: {
            type: Boolean,
            default: false,
        },
        isMultiline: {
            type: Boolean,
            default: false,
        },
        marketTypeId: {
            type: String,
            default: '',
        },
        isHot: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        eventSelection() {
            return this.price.handicap ? `${this.price.name} (${this.price.handicap})` : this.price.name;
        },
    },
    methods: {
        $numberFormat: helper.numberFormat,
    },
};
</script>

<style lang="scss" scoped>
.event-bet {
    border: $betline-event-border;
    cursor: pointer;
    background: $betline-event-odds-background;
    color: $betline-event-odds-color;
    width: 100%;
    display: flex;

    &.selected {
        color: $betline-event-odds-color-selected;
        background-color: $betline-event-odds-background-selected;
        border: $betline-event-border-selected;
    }

    &-wrapper {
        flex: 1;
        padding: 0 4px 5px;
        display: flex;

        &-hidden {
            visibility: hidden;
        }
    }

    .anchor-wrap {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .price-icon {
            height: 14px;
            width: 14px;
            vertical-align: top;
            margin-top: -4px;
        }

        .icon-hot {
            margin-right: 4px;
            fill: $betline-icon-hot-fill;
        }
        &.multiline {
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            .event-selection {
                padding: 5px 10px 3px 10px;
                @include mq-xxs {
                    padding: 5px 7px 3px 7px;
                }
            }
            .event-odds {
                padding: 3px 10px 5px 10px;
                flex-grow: 0;
                padding-top: 0;
                @include mq-xxs {
                    padding: 3px 7px 5px 7px;
                }
            }
        }
    }

    .event-selection,
    .event-odds {
        padding: 10px 10px;
        @include mq-xxs {
            padding: 10px 7px;
        }
    }

    .event-selection {
        @extend %body-normal-font-400;
        border-radius: $border-radius-left;
        word-break: break-word;
        flex-basis: 55%;
        text-align: left;
    }

    .event-odds {
        @extend %body-normal-font-700;
        border-radius: $border-radius-right;
        white-space: nowrap;
        text-align: left;

        &_locked {
            height: 26px;
            padding: 8px 10px 8px 10px;
        }
    }
}
</style>
