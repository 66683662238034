<template>
    <div class="otp-verification-resend-wrapper">
        <!-- Loading  -->
        <Spinner
            v-if="isCodeResendInProgress || (isCodeVerificationInProgress && isFirstAttempt)"
            visible
            :zoom="60"
            class="otp-verification-resend-spinner justify-center"
        />

        <!-- Is global limit reached-->
        <div v-if="isGlobalLimitReached" class="otp-verification-resend-error">
            <renderer
                :input="$t('ui.accountVerification.errors.globalLimitReached')"
                class="notify error"
                @clickEvent="({ event }) => $emit('contact-us', event)"
            />
        </div>

        <!--Is selected otp option limit reached-->
        <div v-else-if="isSelectedLimitReached && !isUssdActiveMethod" class="otp-verification-resend-message">
            {{ $t('ui.accountVerification.dailyLimitReached') }}
        </div>

        <!-- Timer  -->
        <div v-else-if="codeResendTimeLeft" class="otp-verification-resend-message">
            {{ $t('ui.accountVerification.resendMessage') }} 00:{{ formattedResendCoolDown }}
        </div>

        <!-- Button -->
        <button
            v-else-if="!isCodeResendInProgress"
            data-test-id="resendOtpButton"
            class="button-text medium otp-verification-resend-button"
            @click="$emit('resend')"
        >
            <SvgIcon class="icon-size-medium" icon-id="icon-reload" />
            <span class="underline">
                {{ $t('ui.accountVerification.resendOtp') }}
            </span>
        </button>
    </div>
</template>

<script>
import Spinner from '@/modules/core/components/atoms/Spinner';

export default {
    name: 'OtpVerificationResend',
    components: {
        Spinner,
    },
    props: {
        isCodeResendInProgress: Boolean,
        isCodeVerificationInProgress: Boolean,
        isFirstAttempt: Boolean,
        isGlobalLimitReached: Boolean,
        isSelectedLimitReached: Boolean,
        isUssdActiveMethod: Boolean,
        codeResendTimeLeft: Number,
    },
    computed: {
        formattedResendCoolDown() {
            return String(this.codeResendTimeLeft).padStart(2, '0');
        },
    },
};
</script>

<style lang="scss" scoped>
.otp-verification-resend-wrapper {
    margin-bottom: 12px;

    .spinner {
        position: relative;
        background-color: transparent;
        padding: 0;
        max-height: 20px;
    }

    .otp-verification-resend-error {
        margin-bottom: 0;
    }

    & button {
        margin: 0 auto;
        padding: 1px 0;
    }
}

.otp-verification-resend-spinner {
    margin-bottom: 12px;
}

.otp-verification-resend-error {
    margin-bottom: 12px;

    & span {
        display: block;
    }
}

.otp-verification-resend-message {
    @extend %body-normal-font-400;
    text-align: center;
    color: $dark-grey-3;
}

.otp-verification-resend-button {
    display: flex;
    justify-content: center;
    gap: 8px;
    color: $betpawa-black;
    text-align: center;
}
</style>
