<template>
    <div v-if="data.content.isTocEnabled" class="toc">
        <h2 v-if="data.content.title">{{ data.content.title }}</h2>

        <slot />

        <TocTree :toc="toc" :is-flat="isFlat" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getter } from '@/modules/platform/store/const';
import { getObjectField } from '@/modules/core/utils/helper';
import TocTree from './TocTree.vue';

export default {
    name: 'TocComponent',
    components: { TocTree },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        visibleId: {
            type: String,
            default: '',
        },
        isFlat: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            getPages: getter.GET_PAGES,
        }),
        toc() {
            const pageName = getObjectField(this.$route, 'params.pathMatch', this.$route.path.toLowerCase()).split('/').pop();
            const tocTree = getObjectField(this.getPages[pageName], 'tocTree', []);

            if (this.visibleId) {
                const item = tocTree.find(({ id }) => id === this.visibleId);
                return item ? [item] : [];
            }

            return tocTree;
        },
    },
};
</script>
<style lang="scss" scoped>
.toc {
    margin: 20px 0;
}
</style>
