<template>
    <Checkbox :id="checkboxId" :checked="checked" :disabled="disabled" class="filter-checkbox" @onCheck="onCheck">
        <div class="checkbox-content">
            <div class="label">
                <slot name="icon" />
                <span>{{ label }}</span>
            </div>
            <div v-if="!!counter" class="counter">{{ counter }}</div>
        </div>
    </Checkbox>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';

export default {
    name: 'FilterCheckboxItem',
    components: { Checkbox },
    props: {
        checkboxId: {
            type: String,
            default: 'checkbox',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'checkbox',
        },
        counter: {
            type: [Number, undefined],
            required: false,
            default: undefined,
        },
    },
    methods: {
        onCheck(value) {
            this.$emit('onCheck', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.filter-checkbox {
    border-top: 1px solid $light-grey;
    display: block;
    margin: 0;
    ::v-deep {
        .checkbox-label {
            padding: 8px 16px;
            cursor: pointer;
            min-height: 40px;
            display: flex;
            align-items: center;
        }
    }
}

.checkbox-content {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-grow: 1;
}
.label {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: center;
}
.counter {
    flex-grow: 0;
    flex-shrink: 0;
}
</style>
