var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"page verify-or-forgot-page",attrs:{"data-test-id":"contentBlock"},on:{"submit":function($event){$event.preventDefault();}}},[(_vm.isAccountVerification)?[_c('h2',[_vm._v(_vm._s(_vm.$t('ui.accountVerification.verifyAccount')))]),_c('p',{staticClass:"sub-header verify-or-forgot-sub-header"},[_c('renderer',{attrs:{"input":_vm.$t('ui.accountVerification.yourMobileIs', {
                        phoneNumber: _vm.phoneNumber,
                        fullPhoneNumber: _vm.fullPhoneNumber,
                        routeName: _vm.changePhoneRoute,
                    })}})],1)]:[_c('h2',[_vm._v(_vm._s(_vm.$t(("ui.common." + _vm.pinOrPassword + ".forgotten"))))]),_c('p',{staticClass:"sub-header verify-or-forgot-sub-header"},[_vm._v(" "+_vm._s(_vm.$t("ui.resetPassword.enterYourMobileNumber", { brandName: _vm.$t('project.brand') }))+" ")]),_c('PhoneNumber',{attrs:{"data-test-id":"forgotPhoneNumber","form-name":"verify-or-forgot-form","value":_vm.phoneNumber,"v":_vm.$v.phoneNumber,"placeholder":_vm.phoneNumberValidation.placeholder,"error-messages":_vm.phoneNumberValidation.errorMessages},on:{"value":_vm.handlePhoneNumberChange}})],(_vm.currentOtpOptions.length > 1)?[_c('div',{staticClass:"verify-or-forgot-bottom-line"}),_c('h5',{staticClass:"verify-or-forgot-selector-title"},[_vm._v(_vm._s(_vm.$t('ui.accountVerification.verificationSelectorTitle')))]),_c('OtpOptionsSelector',{staticClass:"verify-or-forgot-page-verification-selector",attrs:{"data-test-id":"otpOptionsBlock","otp-options":_vm.currentOtpOptions,"selected-otp-option":_vm.selectedOtpOption},on:{"change":_vm.handleChangeOtpOption}})]:_vm._e(),(_vm.otpOptionsError || _vm.otpOptionsWarning)?_c('div',{staticClass:"verify-or-forgot-otp-error",class:{ warning: _vm.otpOptionsWarning }},[_c('renderer',{staticClass:"notify error",attrs:{"input":_vm.otpOptionsError || _vm.otpOptionsWarning},on:{"clickEvent":function (ref) {
                                        var event = ref.event;

                                        return _vm.trackVerifyOrForgotEvent(event);
}}})],1):_vm._e(),(_vm.showCaptcha)?_c('CaptchaWidget',{ref:"captchaWidget",staticClass:"verify-or-forgot-captcha-widget",attrs:{"name":_vm.$options.name},on:{"error":_vm.trackCloudflareFailures}}):_vm._e(),_c('Button',{attrs:{"data-test-id":"handleSentVerificationCode","disabled":_vm.isRequestButtonDisabled,"loading":_vm.isCodeVerificationProgress},on:{"click":_vm.handleSentVerificationCode}},[_vm._v(" "+_vm._s(_vm.$t('ui.accountVerification.getVerificationCode'))+" ")]),(_vm.error)?_c('renderer',{staticClass:"notify error verify-or-forgot-error",attrs:{"input":_vm.error}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }