import { convert, durationUnitsType } from '@/modules/core/utils/duration';
import { helper } from '@/modules/core';

export default function getEventFilterDaysList(timeZoneOffset = 0) {
    const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const now = new Date();
    const todayIndex = now.getDay();

    // Utility to get ISO date range for a specific day offset
    const getDayRange = (offset) => {
        const date = new Date(now);
        date.setDate(date.getDate() + offset);
        const {
            date: day,
            month,
            year,
        } = helper.formatDateTime(date, {
            timeZoneOffset,
            toObject: true,
        });
        const utcMidnight = Date.UTC(year, month - 1, day, 0, 0, 0, 0);
        const startOfDayUTC = utcMidnight - timeZoneOffset;
        const endOfDayUTC = startOfDayUTC + convert(1, durationUnitsType.DAY, durationUnitsType.MILLISECONDS) - 1;

        return {
            day: `${year}-${month}-${day}`,
            start: new Date(startOfDayUTC).toISOString(),
            end: new Date(endOfDayUTC).toISOString(),
        };
    };

    // Generate day data based on offset
    const getDayData = (offset) => {
        const dayIndex = (todayIndex + offset) % 7;
        const dayName = dayNames[dayIndex];
        const { day, start, end } = getDayRange(offset);

        return {
            key: offset === 0 ? 'today' : offset === 1 ? 'tomorrow' : dayName,
            dayName,
            day,
            range: { gte: start, lt: end },
        };
    };

    // Build the result array
    return [
        { key: 'all', day: 'all', range: null }, // "All" entry
        ...Array.from({ length: 7 }, (_, i) => getDayData(i)), // Today + Next 6 Days
    ];
}
