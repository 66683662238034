export const userVerificationStatuses = {
    VERIFICATION_STATUS_STARTED: 'verification_status_started',
    VERIFICATION_STATUS_INREVIEW: 'verification_status_inreview',
    VERIFICATION_STATUS_COMPLETED: 'verification_status_completed',
    VERIFICATION_STATUS_DECLINED: 'verification_status_declined',
    VERIFICATION_STATUS_CLOSED: 'verification_status_closed',
    VERIFICATION_STATUS_EXPIRED: 'verification_status_expired',
    VERIFICATION_STATUS_REVERTED: 'verification_status_reverted',
};

export const modalNames = {
    USSD_VERIFICATION_MODAL: 'ussd-verification-modal',
    SELF_EXCLUSION_MODAL: 'self-exclusion-modal',
};

export const otpOptionsFields = {
    VOICE: 'VOICE',
    TELEGRAM: 'TELEGRAM',
    SMS: 'SMS',
    USSD: 'USSD',
    WHATSAPP: 'WHATSAPP',
};

export const otpOptionsNames = {
    [otpOptionsFields.VOICE]: 'call',
    [otpOptionsFields.TELEGRAM]: 'telegram',
    [otpOptionsFields.SMS]: 'sms',
    [otpOptionsFields.USSD]: 'ussd',
    [otpOptionsFields.WHATSAPP]: 'whatsapp',
};

export const CODE_RESEND_COOL_DOWN_SECONDS = 59;

export const ACCOUNT_SELF_EXCLUDED_ERROR = 'ACCOUNT_SELF_EXCLUDED';

export const GtmVerificationPages = {
    ACCOUNT_VERIFICATION: 'account_verification',
    VERIFY_VERIFICATION_CODE: 'verify_verification_code',
    FORGOT_PASSWORD: 'forgot_password',
    VERIFY_OTP_CODE: 'verify_otp_code',
    RESET_PASSWORD: 'reset_password',
};
