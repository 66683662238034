<template>
    <div class="verification-and-reset-wrapper">
        <div class="verification-and-reset-header">
            <span><SvgIcon icon-id="logo" icon-fallback="logo" /></span>
        </div>
        <Stepper v-if="!isAccountVerification" :steps="steps" :active-step-id="activeStepId" />

        <div class="verification-and-reset-content">
            <!-- Step 1 - OTP Verification  -->
            <OtpVerificationStep
                v-if="activeStepId === VerificationSteps.OTP_VERIFICATION_STEP"
                :selected-otp-option="selectedOtpOption"
                :change-phone-route="changePhoneRoute"
                :phone-number="phoneNumber"
                :on-verification-success-callback="handleVerificationSuccess"
                :is-account-verification="isAccountVerification"
                @onNewOtpOption="(newOtpOption) => setSelectedOtpOption(newOtpOption)"
            />

            <!-- Step 2 - Reset Password -->
            <ResetPasswordStep
                v-if="activeStepId === VerificationSteps.RESET_PASSWORD_STEP"
                :verified-otp-code="verifiedOtpCode"
                :phone-number="phoneNumber"
            />

            <div v-if="!isAccountVerification" class="verification-and-reset-note">
                {{ $t(`ui.accountVerification.${pinOrPassword}.note`) }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { routeName } from '@/router/const-name';

import { getter as authGetter, action as authAction, mutation as authMutation } from '@/modules/platform/store/modules/auth/const';
import { getter as translationsGetter } from '@/store/modules/translations/const';

import Stepper from '@/components/Stepper';
import OtpVerificationStep from './OtpVerificationStep/OtpVerificationStep';
import ResetPasswordStep from './ResetPasswordStep';

const VerificationSteps = {
    OTP_VERIFICATION_STEP: 1,
    RESET_PASSWORD_STEP: 2,
};

export default {
    name: 'VerificationAndReset',
    components: {
        Stepper,
        OtpVerificationStep,
        ResetPasswordStep,
    },
    props: {
        isAccountVerification: {
            type: Boolean,
            default: false,
        },
        changePhoneRoute: String,
    },
    data() {
        return {
            VerificationSteps,
            steps: [],
            activeStepId: null,
            verifiedOtpCode: '',
        };
    },

    computed: {
        ...mapGetters({
            selectedOtpOption: authGetter.GET_SELECTED_OTP_OPTION,
            pinOrPassword: translationsGetter.PIN_OR_PASSWORD,
            storedPhoneNumber: authGetter.GET_PHONE_NUMBER,
        }),
        phoneNumber() {
            return this.$route.params.phoneNumber || this.storedPhoneNumber;
        },
    },
    mounted() {
        if (!this.selectedOtpOption && !this.$route.params.selectedOtpOption) {
            this.$router.push({
                name: this.isAccountVerification ? routeName.ACCOUNT_VERIFICATION : routeName.FORGOT_PASSWORD,
                params: { phoneNumber: this.phoneNumber },
            });

            return;
        }

        if (!this.selectedOtpOption) {
            this.setSelectedOtpOption(this.$route.params.selectedOtpOption);
        }

        this.activeStepId = VerificationSteps.OTP_VERIFICATION_STEP;
        this.steps = [
            { id: VerificationSteps.OTP_VERIFICATION_STEP, titleKey: this.$t('ui.accountVerification.firstStepName') },
            { id: VerificationSteps.RESET_PASSWORD_STEP, titleKey: this.$t(`ui.accountVerification.${this.pinOrPassword}.secondStepName`) },
        ];
    },
    methods: {
        ...mapMutations({
            setSelectedOtpOption: authMutation.SET_SELECTED_OTP_OPTION,
        }),
        handleVerificationSuccess(verifiedOtpCode) {
            // For the verification flow, send the user to the home screen.
            if (this.isAccountVerification) {
                this.$router.push({ name: routeName.HOMEPAGE }).then(() => {
                    this.$store.dispatch(authAction.GET_BALANCE, { force: true });
                });

                return;
            }

            // For the forgot password flow, send the user to the reset password step.
            this.activeStepId = VerificationSteps.RESET_PASSWORD_STEP;
            this.verifiedOtpCode = verifiedOtpCode;
        },
    },
};
</script>

<style lang="scss" scoped>
.verification-and-reset-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.verification-and-reset-header {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    width: 100%;
    background-color: $betpawa-black;

    & .svg-icon {
        width: $header-brand-logo-medium-width;
        height: $header-brand-logo-medium-height;
    }
}

.verification-and-reset-content {
    padding: 28px 16px;
    max-width: 400px;
    margin: 0 auto;
}

.verification-and-reset-note {
    margin-top: 20px;
    @extend %small-details-font-400;
    text-align: center;
    color: $neutral-medium;
}
</style>
