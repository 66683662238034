<script>
import { createNodes } from '@/js/utils/cms';
import { getObjectField } from '@/modules/core/utils/helper';

export default {
    name: 'Heading',
    functional: true,
    props: {
        data: {
            type: Object,
            default: () => ({ attrs: {}, content: [] }),
        },
    },
    render(h, context) {
        const {
            attrs: { level, id, textAlign },
            content,
        } = getObjectField(context, 'props.data', {});

        return h(
            `h${level}`,
            {
                attrs: { id },
                style: { textAlign },
            },
            createNodes(h, content)
        );
    },
};
</script>
