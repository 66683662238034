<template>
    <div v-if="currentLanguage" class="language-switcher">
        <div class="language-switcher-current" @click="toggleDropDown">
            <SvgIcon class="icon-size-medium lang-icon" :icon-id="`icon-language-menu-${currentLanguage.key}`" />
            <div class="language-switcher-current-locale">{{ currentLanguage.text }}</div>
            <SvgIcon icon-id="icon-arrow-down" class="caret-icon" />
        </div>

        <div v-if="isDropDownOpen && languages.length" class="language-switcher-dropdown">
            <div v-for="lang of languages" :key="lang.key" class="language-switcher-item" @click="onLanguageChange(lang.key)">
                <SvgIcon :icon-id="`icon-language-menu-${lang.key}`" class="icon-size-medium lang-icon" />
                <div class="language-switcher-item-label">{{ lang.text }}</div>
            </div>
        </div>
    </div>
    <div v-else><Loader /></div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getter as translationsGetter } from '@/store/modules/translations/const';
import Loader from '@/modules/core/components/atoms/Loader.vue';

export default {
    name: 'LanguageSwitcher',
    components: { Loader },
    data() {
        return {
            isDropDownOpen: false,
        };
    },
    computed: {
        ...mapGetters({
            languageList: translationsGetter.LANGUAGE_LIST,
            selectedLanguage: translationsGetter.GET_SELECTED_LANGUAGE,
        }),
        languages() {
            return this.languageList.filter((l) => l.key !== this.selectedLanguage);
        },
        currentLanguage() {
            return this.languageList.find((l) => l.key === this.selectedLanguage);
        },
    },
    methods: {
        toggleDropDown() {
            this.isDropDownOpen = !this.isDropDownOpen;
        },
        onLanguageChange(language) {
            this.$emit('languageChanged', language);
        },
    },
};
</script>

<style scoped lang="scss">
.language-switcher {
    position: relative;
    z-index: 99;

    &-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        border-top: 1px solid $light-grey;
    }

    &-current {
        min-width: 132px;
        display: flex;
        align-items: center;
        padding: 12px;
        border: 1px solid $light-grey;
        cursor: pointer;
        text-transform: uppercase;
        color: white;
        @extend %small-details-font-400;

        .caret-icon {
            width: 12px;
            height: 12px;
            fill: white;
            margin-left: auto;
        }
    }

    &-dropdown {
        position: absolute;
        background-color: white;
        width: 100%;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.24);
        margin-top: 8px;
        text-transform: uppercase;
        @extend %small-details-font-400;

        &::before {
            content: '';
            position: absolute;
            top: -12px;
            right: 9px;
            border-width: 8px;
            border-style: solid;
            border-color: transparent transparent white transparent;
        }
    }

    .lang-icon {
        margin-right: 4px;
    }
}
</style>
