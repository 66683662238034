<template>
    <div class="promo-banner landing" :class="[$mq.all]">
        <h1>{{ title }}</h1>
        <div v-if="subTitle">{{ subTitle }}</div>
        <div class="thumbnails-container">
            <div v-for="(item, index) of items" :key="item.icon + '-thumbnail-' + index" class="thumbnail">
                <SvgIcon :icon-id="item.icon" />
                <div class="text">{{ item.text }}</div>
            </div>
        </div>
        <a v-if="target" :href="link.url" class="button button-accent button-full" @click="$emit('button:click')">{{ link.text }}</a>
        <router-link v-else-if="button" :to="button.route" class="button button-accent button-full" @click.native="$emit('button:click')">
            {{ button.text }}
        </router-link>
    </div>
</template>
<script>
export default {
    name: 'PromoBanner',
    props: ['items', 'button', 'title', 'subTitle', 'target', 'link'],
};
</script>

<style scoped lang="scss">
.promo-banner {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    background-color: $light-grey-bg;
    padding: 15px 10%;
    text-align: center;
    border-bottom: solid $betpawa-black 2px;

    .thumbnails-container {
        overflow: hidden;
        position: relative;
        display: table;
        min-width: 65%;

        .thumbnail {
            display: table-cell;
            padding: 7px 10px;
            font-size: 13px;
            line-height: 14px;
            width: 33%;

            .text {
                padding: 7px 0;
                text-align: center;
            }
        }
    }

    &:not(.xxs) {
        .text {
            font-size: 13px;
        }

        svg {
            width: 60px;
            height: 60px;
        }
    }

    &.xxs {
        h1 {
            font-size: 134%;
        }
    }
}
</style>
