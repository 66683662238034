import { getObjectField } from '@/modules/core/utils/helper';
import * as Sentry from '@sentry/browser';
import { Vue as SentryVue } from '@sentry/integrations';
import { addExtensionMethods } from '@sentry/tracing';

const DEFAULT_CONFIG = {
    enabled: true,
    debug: false,
};

function initSentry(Vue, config) {
    // `errorHandler` should be defined before Sentry.init() - see GitHub Issue: https://github.com/vuejs/vue/issues/8433
    Vue.config.errorHandler = (err, vm, info) => {
        if (config.environment !== 'production') {
            Vue.util.warn(`Error in ${info}: "${err.toString()}"`, vm);
        }
        console.error(err);
    };
    Vue.prototype.$sentry = Vue.$sentry = Sentry;
    addExtensionMethods();
    Vue.$sentry.init({
        ...config,
        Vue,
        integrations: [
            new Sentry.Integrations.Breadcrumbs({ dom: false }),
            new SentryVue({
                Vue,
                attachProps: true,
                logErrors: true,
            }),
        ],
        beforeSend(event, hint) {
            if (!event.exception) return null;

            const isInternal = getObjectField(event.exception, 'values', []).some((exception) => {
                return getObjectField(exception, 'stacktrace.frames', []).some((frame) => {
                    const filename = frame.filename || '';
                    return filename.includes('/src/') || filename.includes('webpack:///src/') || filename.includes(window.location.origin);
                });
            });

            if (!isInternal) {
                return null;
            }

            const originalError = getObjectField(hint, 'originalException');
            if (originalError instanceof Event) {
                Sentry.withScope((scope) => {
                    const { currentTarget, isTrusted, target = {}, type } = originalError;
                    scope.setExtras({ currentTarget, isTrusted, target, type });
                    Sentry.captureException(new Error(`${target.src || 'Unknown '} Event Error`));
                });
                return null;
            }

            return event;
        },
    });
    Vue.$sentry.setTag('platform_type', window.isBridgeEnabled ? 'android' : 'web');
}

export default function install(Vue, config = DEFAULT_CONFIG) {
    try {
        initSentry(Vue, { ...DEFAULT_CONFIG, ...config });
    } catch (error) {
        Vue.util.warn(`Error in [vue-sentry]: ${error}`);
    }
}
