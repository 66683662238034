<template>
    <LoadingElementCore :text="text" />
</template>

<script>
import LoadingElementCore from '@/components/Fragments/LoadingElementCore.vue';

export default {
    name: 'LoadingElement',
    components: { LoadingElementCore },
    props: {
        text: {
            type: String,
            default: '',
        },
    },
};
</script>
