import { APPLICATION_JSON, MESSAGE_RSOCKET_ROUTING } from 'rsocket-core';
import Vue from 'vue';
import Vuelidate from 'vuelidate';

import cloudflare from '@/js/plugins/cloudflare';
import Cookies from '@/js/plugins/cookies';
import Flag from '@/js/plugins/flag';
import gtm from '@/js/plugins/gtm';
import http from '@/js/plugins/http';
import ModalWindow from '@/js/plugins/modal';
import { VueRSocket } from '@/js/plugins/rsocket';
import Sentry from '@/js/plugins/sentry';
import srw from '@/js/plugins/srwidget';
import TracingId from '@/js/plugins/tracingId';
import translations from '@/js/plugins/translations';
import { config, deviceType, helper } from '@/modules/core';
import { getter as platformGetter } from '@/modules/platform/store/const';
import router from '@/router';
import { getter, mutation } from '@/store/modules/translations/const';
import store from '@/store/store';

const isExternalPluginsEnabled =
    env.VUE_APP_ENABLE_EXTERNAL_PLUGINS || env.VUE_APP_ENV.includes('staging') || env.VUE_APP_ENV.includes('production');
const DEFAULT_ENV = 'local';
const DEFAULT_SAMPLE_RATE = 0.1;
const DEFAULT_TRACES_SAMPLE_RATE = 0.1;

Vue.use(Flag);

Vue.use(ModalWindow, { dynamic: true });

const isProd = env.VUE_APP_ENV.includes('production');

Vue.use(gtm, {
    enabled: isExternalPluginsEnabled,
    id: isProd ? 'GTM-TWK49GS' : 'GTM-MSQ2S5W',
});

Vue.use(Sentry, {
    ignoreErrors: [
        /Request aborted.*/,
        /NavigationDuplicated.*/,
        /Network Error.*/,
        /Cms internal error.*/,
        /fixedTimeID is not defined.*/,
        /Request failed with status code.*/,
        /timeout of 60000ms exceeded.*/,
        /Token is revoked.*/,
        /Cannot read property 'inProgress'*/, // TODO: remove in scope of BP-17727
        /Cannot read property 'virtualsEnabled'.*/, // TODO: remove in scope of BP-16745
        /Maximum call stack size exceeded'.*/, // TODO: BP-16534
        // TODO: remove bloated errors
        /'t\.\$destroy' is not a function.*/,
        /SyntaxError: Failed to execute 'querySelector' on 'Document'.*/,
        /Non-Error promise rejection captured with value: undefined.*/,
        /component failed to load with given props:.*/,
        // Errors below added from 25.10.2021
        /Unexpected end of JSON input.*/,
        /Identifier 'isMainframe' has already been declared.*/,
        /Block-scoped declarations (let, const, function, class) not yet supported outside strict mode.*/,
        /brandName is not defined.*/,
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extensions:\/\//i,
        /translate_http/,
        /getElemhideEmulationSelectors/,
        /Erreur de réseau/,
        /Hitilafu ya mtandao/,
        /No error message/,
    ],
    dsn: env.VUE_APP_SENTRY_DSN,
    sampleRate: Number(env.VUE_APP_SENTRY_SAMPLE_RATE) || DEFAULT_SAMPLE_RATE,
    tracesSampleRate: Number(env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE) || DEFAULT_TRACES_SAMPLE_RATE,
    release: `${process.env.VUE_APP_RELEASE_VERSION}`,
    environment: `${env.VUE_APP_ENV}` || DEFAULT_ENV,
    enabled: isExternalPluginsEnabled || env.VUE_APP_IS_SENTRY_ENABLED === 'true',
    autoSessionTracking: false,
});
Vue.use(Cookies);
Vue.use(TracingId);
Vue.use(Vuelidate);
const flatten = (input) => {
    let output;
    if (deviceType.isApp()) {
        output = { ...input, errors: { ...input.errors.global, ...input.errors.android } };
    } else {
        output = { ...input, errors: { ...input.errors.global } };
    }
    return output;
};
const translationOptions = {
    router,
    store,
    languageGetter: getter.LANGUAGE,
    setLocalesMutation: mutation.SET_LOCALE,
    interpolate: helper.interpolate,
    flatten,
};
Vue.use(translations, translationOptions);
Vue.use(http, {
    language: store.getters[getter.GET_SELECTED_LANGUAGE],
    dateOptionsGetter: platformGetter.GET_DATE_OPTIONS,
    store,
    langGetter: getter.LANGUAGE,
    enableSendApiErrorsToSentry: helper.getObjectField(
        store.getters[platformGetter.GET_BRAND_PREFERENCE],
        'enableSendApiErrorsToSentry',
        false
    ),
});
Vue.use(srw, { theme: false, language: store.getters[getter.GET_SELECTED_LANGUAGE] });
Vue.use(cloudflare, { sitekey: env.VUE_APP_TURNSTILE_KEY });

const DEFAULT_KEEP_ALIVE = 60000; // The client pings the server to check if there is a connection
const DEFAULT_LIFE_TIME = 150000; // If no success keepalive checks occur during this time, the channels will be closed
const rSocketConfig = {
    debug: !config.isProd(),
    keepAlive: DEFAULT_KEEP_ALIVE,
    lifetime: DEFAULT_LIFE_TIME,
    metadataMimeType: MESSAGE_RSOCKET_ROUTING.string,
    dataMimeType: APPLICATION_JSON.string,
};

Vue.use(VueRSocket, rSocketConfig);
