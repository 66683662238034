<template>
    <!--TODO: https://kazupon.github.io/vue-i18n/guide/number.html#custom-formatting-->
    <div class="currency">
        <slot />
        <span v-if="currency && currency.symbol && isPrefix" class="symbol" :class="{ contrast }">
            {{ currency.symbol }}
        </span>
        <Loader v-if="isLoading" />
        <span v-else class="amount"><slot name="amount" />{{ currency.amount }}</span>
        <span v-if="currency && currency.symbol && !isPrefix" class="symbol" :class="{ contrast }">
            {{ currency.symbol }}
        </span>
    </div>
</template>

<script>
import Loader from '@/modules/core/components/atoms/Loader.vue';

export default {
    components: { Loader },
    props: {
        format: {
            type: [String, Object],
            default: '',
        },
        decimal: {
            type: Number,
            default: null,
        },
        amount: {
            type: [String, Number],
            default: '',
            required: true,
        },
        contrast: {
            type: Boolean,
            default: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({}),
    computed: {
        currency() {
            if (typeof this.amount === 'number') {
                const currency = this.$options.filters.currency(this.amount, this.format, this.decimal);
                const { amount, symbol } = currency;
                return { amount: amount || currency, symbol };
            } else {
                const strAmount = this.format.replace(/%s(.*?)/g, this.amount);
                const currency = this.$options.filters.currency(strAmount, this.format, this.decimal);
                return { amount: currency, symbol: this.format?.symbol };
            }
        },
        isPrefix() {
            const { format, symbol } = this.format;
            return format.startsWith(symbol);
        },
    },
};
</script>
<style lang="scss" scoped>
.contrast {
    color: $disabled-text;
}
</style>
