import { helper } from '@/modules/core';
import CookieStorage from './storage/CookieStorage';
import Storage from './storage/Storage';

export default class SnapshotToStorage {
    /**
     * @constructor
     * @param {Object<sessionStorage>|Object<localStorage>} storage - localStorage/sessionStorage
     * @param {Function|null} reducer - specifies the data to save.
     * @param {Function|null} filter - filter data from mutations
     * @param {Function|null} immediate - skip timeout to save in store for given mutation
     * @param {string} storeName - key's name for storage
     * @param {boolean} isDev - ENV parameter
     */
    constructor({ storage, reducer = null, filter = null, immediate = null, storeName = 'vuexSnapshot', isDev = false }) {
        this.storeName = storeName;
        this.storage = this._getStorage(storage);
        this.reducer = reducer;
        this.filter = filter;
        this.immediate = immediate;
        this.isDev = isDev;

        // call from vuex
        this.plugin = this._plugin.bind(this);

        this._timeoutId = null;
        this._dataForSave = null;
    }

    /**
     * Plugin for Vuex
     * @param store
     * @private
     */
    _plugin(store) {
        this._initPlugin(store);
        store.subscribe(this._callbackSubscribeStore.bind(this));
    }

    /**
     * Initialization plugin
     * Call one time, after initialization vuex
     * @param {Object} store
     * @private
     */
    _initPlugin(store) {
        const restoreData = this.getFromStore();
        if (!restoreData) {
            return;
        }

        const mergedStore = helper.deepMerge(store.state, restoreData);
        store.replaceState(mergedStore);
    }

    /**
     * Event from store (vuex)
     * @param {Object} mutation
     * @param {Object} state
     * @private
     */
    _callbackSubscribeStore(mutation, state) {
        // use filter
        if (this.filter && !this.filter(mutation)) {
            return;
        }

        let dataForSave = {};

        // use reducer
        if (this.reducer) {
            dataForSave = this.reducer(state);
        } else {
            dataForSave = state;
        }

        // save data
        this.throttleSaveInStore(dataForSave, this.immediate(mutation));
    }

    /**
     * Throttle save data in store
     * @param {data} dataForSave
     * @param {Boolean|null} immediate - skip timeout to save in store
     */
    throttleSaveInStore(dataForSave, immediate) {
        this._dataForSave = dataForSave;
        const timeout = immediate ? 0 : 10;

        if (this._timeoutId) {
            return;
        }
        this._timeoutId = setTimeout(() => {
            this.saveInStore(this._dataForSave);
            this._timeoutId = null;
        }, timeout);
    }

    /**
     * Save data in store
     * @param {Object} data
     */
    saveInStore(data) {
        data.appVer = process.env.VUE_APP_RELEASE_VERSION;
        data.buildNumber = process.env.VUE_APP_BUILD_NUMBER;
        data.env = env;
        try {
            this.storage.setItem(this.storeName, data);
        } catch (e) {
            if (this.isDev) {
                console.error(e);
            }
        }
    }

    /**
     * Get data from store
     * @returns {*}
     */
    getFromStore() {
        try {
            var data = this.storage.getItem(this.storeName);
            if (env.VUE_APP_FLUSH_OLD_STORAGE && data && data.appVer !== process.env.VUE_APP_RELEASE_VERSION) {
                return;
            }
            if (!this._hasLS() && data.sport?.betslip) {
                data.sport.betslip.regular.bets = [];
                data.sport.betslip.regular.error = {};
                data.sport.betslip.virtual.bets = [];
                data.sport.betslip.virtual.error = {};
            }
            return data;
        } catch (e) {
            if (this.isDev) {
                console.error(e);
            }
            return {};
        }
    }

    packBets(data, type) {
        const bets = [...data];
        var i;
        for (i = 0; bets.length; i += 1) {
            this.storage.setBets(`${type}betchunk` + i, bets.splice(0, 25));
        }
        this.storage.setString(`${type}chunkNR`, i);
    }

    unpackBets(type) {
        var i = this.storage.getString(`${type}chunkNR`);
        var pool = [];
        for (var f = 0; f < i; f++) {
            pool = pool.concat(this.storage.getBets(`${type}betchunk` + f));
        }
        return pool;
    }

    /**
     * Get storage factory for current user
     * @param {*} storage
     */
    _getStorage(storage) {
        if (storage) {
            return storage;
        }
        return this._hasLS() ? new Storage(window.localStorage) : new CookieStorage();
    }

    _hasLS() {
        return window.localStorage;
    }
}
