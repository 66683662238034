export const action = {
    LOAD_SHARE_LINKS: 'sport/betslip/loadShareLinks',
    CREATE_BOOKING_CODE: 'sport/betslip/createBookingCode',
    GET_BOOKING_SELECTION: 'sport/betslip/getBookingSelection',
    LOAD_BOOKING_CODE_BY_QUERY: 'sport/betslip/loadBookingCodeByQuery',
    LOAD_EXTERNAL_PREFILL_IDS: 'sport/betslip/loadExternalPrefillIds',
    RESET_USER_BETS_DATA: 'sport/betslip/resetUserBetsData',
    COPY_BETS: 'sport/betslip/copyBets',
    PLACE_BET: 'sport/betslip/placeBet',
    SELECT_BETSLIP_TYPE: 'sport/betslip/selectBetslipType',
    DISABLE_BETSLIP_POLLING: 'sport/betslip/disableBetslipPolling',
    BETSLIP_POLLING_ATTEMPT: 'sport/betslip/startBetslipPolling',
    GET_BETSLIP_STATUS: 'sport/betslip/getBetslipStatus',
    REJECT_BETSLIP: 'sport/betslip/rejectBetslip',
    RESET_BETSLIP_STATUS: 'sport/betslip/resetBetslipStatus',
    SET_BETSLIP_ERROR: 'sport/betslip/setBetslipError',
    RESET_BETSLIP_ERROR: 'sport/betslip/resetBetslipError',
    REMOVE_BET: 'sport/betslip/removeBet',
    UPDATE_BETSLIP_PRICES: 'sport/betslip/updateBetslipPrices',
    REJECT_BETSLIP_PRICES: 'sport/betslip/rejectBetslipPrices',
    REMOVE_ALL_BETS: 'sport/betslip/removeAllBets',
    ADD_BETSLIP_BET: 'sport/betslip/addBetslipBet',
    SET_STAKE: 'sport/betslip/setStake',
    RESET_STAKE: 'sport/betslip/resetStake',
    SET_BETS: 'sport/betslip/setBets',
    TOGGLE_BET: 'sport/betslip/toggleBet',
    GET_SELECTIONS_BY_IDS: 'sport/betslip/getSelectionsByIds',
};

export const mutation = {
    SET_SHARE_LINKS: 'sport/betslip/setShareLinks',
    CLEAR_SHARE_LINKS: 'sport/betslip/clearShareLinks',
    SET_CREATED_BOOKING_CODE: 'sport/betslip/setCreatedBookingCode',
    RESET_CREATED_BOOKING_CODE: 'sport/betslip/resetCreatedBookingCode',
    SET_CREATED_BOOKING_CODE_ERROR: 'sport/betslip/setCreatedBookingCodeError',
    RESET_CREATED_BOOKING_CODE_ERRORS: 'sport/betslip/resetBookingCodeErrors',
    RESET_LOADED_BOOKING_CODE_ERRORS: 'sport/betslip/resetBookingCodeErrors',
    SET_BOOKING_SELECTION: 'sport/betslip/setBookingSelection',
    SET_BOOKING_SELECTION_ERROR: 'sport/betslip/setBookingSelectionError',
    TOGGLE_BOOKING_CODE_LOADING: 'sport/betslip/toggleBookingCodeLoading',
    SET_BETSLIP_CURRENT_INTERVAL: 'sport/betslip/setBetslipPollingInterval',
    SET_BETSLIP_TIMEOUT_ID: 'sport/betslip/setBetslipPollingTimeoutId',
    BETSLIP_POLLING_CLEAR_INTERVAL: 'sport/betslip/betslipPollingClearInterval',
    RESET_BETSLIP_POLLING: 'sport/betslip/resetBetslipPolling',
    UPDATE_BETSLIP_POLLING_TIMER: 'sport/betslip/updateBetslipPollingTimer',
    UPDATE_BETSLIP_POLLING_ATTEMPT: 'sport/betslip/updateBetslipPollingAttempt',
    SET_BETSLIP_POLLING_START_TIMESTAMP: 'sport/betslip/setBetslipPollingStartTimestamp',
    SET_BETSLIP_STATUS: 'sport/betslip/setBetslipStatus',
    SET_VIRTUAL_BETSLIP_STATUS: 'sport/betslip/setVirtualBetslipStatus',
    SET_BETSLIP_ERROR: 'sport/betslip/setBetslipError',
    SET_VIRTUAL_BETSLIP_ERROR: 'sport/betslip/setVirtualBetslipError',
    RESET_BETSLIP_ERROR: 'sport/betslip/resetBetslipError',
    RESET_VIRTUAL_BETSLIP_ERROR: 'sport/betslip/resetVirtualBetslipError',
    ADD_BETSLIP_BET: 'sport/betslip/addBetslipBet',
    UPDATE_SELECTED_PRICES: 'sport/betslip/updateSelectedPrices',
    ADD_VIRTUAL_BETSLIP_BET: 'sport/betslip/addVirtualBetslipBet',
    SET_BETSLIP_BETS: 'sport/betslip/setBetslipBets',
    SET_VIRTUAL_BETSLIP_BETS: 'sport/betslip/setVirtualBetslipBets',
    CHANGE_BETSLIP_BET: 'sport/betslip/changeBetslipBet',
    CHANGE_VIRTUAL_BETSLIP_BET: 'sport/betslip/changeVirtualBetslipBet',
    CLEAR_SELECTED_EVENT_ID: 'sport/betslip/clearSelectedEventId',
    RESET_BETSLIP_STATUS: 'sport/betslip/resetBetslipStatus',
    RESET_VIRTUAL_BETSLIP_STATUS: 'sport/betslip/resetVirtualBetslipStatus',
    REMOVE_BETSLIP_BET: 'sport/betslip/removeBetslipBet',
    REMOVE_VIRTUAL_BETSLIP_BET: 'sport/betslip/removeVirtualBetslipBet',
    REMOVE_ALL_BETSLIP_BETS: 'sport/betslip/removeAllBetslipBets',
    REMOVE_ALL_VIRTUAL_BETS: 'sport/betslip/removeAllVirtualBets',
    SET_BETSLIP_STAKE: 'sport/betslip/setBetslipStake',
    RESET_BETSLIP_STAKE: 'sport/betslip/resetBetslipStake',
    SET_BETSLIP_ACCEPT_ANY_PRICE: 'sport/betslip/setBetslipAcceptAnyPrice',
    SET_SELECTED_BETSLIP: 'sport/betslip/setActiveBetslip',
    SET_VIRTUAL_BETSLIP_SEASON: 'sport/betslip/setVirtualBetslipSeason',
    RESET_VIRTUAL_BETSLIP_SEASON: 'sport/betslip/resetVirtualBetslipSeason',
    GENERATE_BETSLIP_ID: 'sport/betslip/generateBetslipID',
    SET_EXTERNAL_PREFILL_ERROR: 'sport/betslip/setExternalPrefillError',
};

export const getter = {
    ALL_PRICES_OFFLINE: 'sport/betslip/allPricesOffline',
    GET_ACCEPT_ANY_PRICE: 'sport/betslip/getAcceptAnyPrice',
    GET_ALL_BETS: 'sport/betslip/getAllBets',
    GET_BETS: 'sport/betslip/getRealBets',
    GET_BETSLIP_BETS_COUNT: 'sport/betslip/getBetslipBetsCount',
    GET_BETSLIP_TYPE: 'sport/betslip/getBetslipType',
    GET_BETSLIP_BOOKING_CODE: 'sport/betslip/getBookingCode',
    GET_BETSLIP_BOOKING_ERROR: 'sport/betslip/getBookingError',
    GET_BETSLIP_BOOKING_LAST_SELECTIONS: 'sport/betslip/getLastSelections',
    GET_BETSLIP_POLLING_ATTEMPTS: 'sport/betslip/getBetslippollingAttempt',
    GET_BETSLIP_POLLING_START_TIMESTAMP: 'sport/betslip/getBetslipPollingStartTimestamp',
    GET_BETS_WITH_BONUS: 'sport/betslip/betsWithBonus',
    GET_BONUSES: 'sport/betslip/bonuses',
    GET_CURRENT_BONUS: 'sport/betslip/currentBonus',
    GET_CURRENT_BONUS_INDEX: 'sport/betslip/currentBonusIndex',
    GET_IS_TAX_ENABLED: 'sport/betslip/isTaxEnabled',
    GET_IS_WHT: 'sport/betslip/isWht',
    GET_IS_WIN_TAX_ENABLED: 'sport/betslip/isWinTaxEnabled',
    GET_LEG_INFO_DATA: 'sport/betslip/legInfoData',
    GET_LEG_LIMIT: 'sport/betslip/getLegLimit',
    GET_MAX_BONUS_PERCENTAGE: 'sport/betslip/getMaxBonusPercentage',
    GET_MULTIPLIER: 'sport/betslip/multiplier',
    GET_MULTIPLIER_TOTAL_ODDS: 'sport/betslip/multiplierTotalOdds',
    GET_OFFLINE_PRICES: 'sport/betslip/getOfflinePrices',
    GET_PLACED_TYPES: 'sport/betslip/getPacedTypes',
    GET_REGULAR_BETSLIP: 'sport/betslip/getRegularBetslip',
    GET_SELECTED_BETSLIP: 'sport/betslip/getSelectedBetslip',
    GET_SELECTION_IDS: 'sport/betslip/getSelectionIds',
    GET_SELECTION_IDS_BY_TYPE: 'sport/betslip/getSelectionIdsByType',
    GET_SHARE_LINKS: 'sport/betslip/getShareLinks',
    GET_SHOW_LEG_INFO: 'sport/betslip/showLegInfo',
    GET_STAKE: 'sport/betslip/stake',
    GET_STAKE_AFTER_TAX: 'sport/betslip/stakeAfterTax',
    GET_TAX_AMOUNT: 'sport/betslip/taxAmount',
    GET_TAX_TYPE: 'sport/betslip/taxType',
    GET_TOTAL_BONUS: 'sport/betslip/totalBonus',
    GET_TOTAL_ODDS: 'sport/betslip/getTotalOdds',
    // TODO: refactor in scope of BP-20682 ↓
    GET_TOTAL_WIN_BONUS: 'sport/betslip/totalWinBonus',
    GET_TOTAL_WINNINGS: 'sport/betslip/totalWinnings',
    GET_VIRTUAL_BETS: 'sport/betslip/getVirtualBets',
    GET_VIRTUAL_BETSLIP: 'sport/betslip/getVirtualBetslip',
    GET_VIRTUAL_BETSLIP_BETS_COUNT: 'sport/betslip/getVirtualBetslipBetsCount',
    HAS_BONUSES_DATA: 'sport/betslip/hasBonusesData',
    IS_VIRTUAL_BETSLIP: 'sport/betslip/isVirtualBetslips',
    CUT_PLUS_CALCULATION: 'sport/betslip/cutPlusCalculation',
};
