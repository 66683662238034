import { helper, NETWORK_ERROR } from '@/modules/core';
import { getObjectField } from '@/modules/core/utils/helper';
import { GLOBAL_ERRORS_REQUIRING_DATE_FORMATTING, GLOBAL_ERRORS_REQUIRING_TIME_FORMATTING } from '@/modules/platform';
import { GLOBAL_ERRORS_CASTING_PARAMETERS_TO_NUMBERS } from '@/modules/platform/const/errors';

const MULTI_EXCEPTION = 'MULTIPLE_EXCEPTION_WRAPPER';
const UNKNOWN_ERROR = 'UNKNOWN';

/**
 * Handles time formatting for errors requiring time conversion.
 * Updates data.params[1] with a formatted time value.
 * @param {Object} data - The error data object.
 */
function handleTimeFormatting(data) {
    if (GLOBAL_ERRORS_REQUIRING_TIME_FORMATTING.includes(data.error)) {
        data.params[1] = helper.formatMinutesToDHM(data.params[1]);
    }
}

/**
 * Handles date formatting for errors requiring date conversion.
 * Updates data.params[0] with a formatted date string (DD.MM.YYYY).
 * @param {Object} data - The error data object.
 * @param {Object} store - Vuex store instance.
 * @param {String} dateOptionsGetter - Getter for date formatting options.
 */
function handleDateFormatting(data, store, dateOptionsGetter) {
    if (GLOBAL_ERRORS_REQUIRING_DATE_FORMATTING.includes(data.error)) {
        const { date, month, year } = helper.formatDateTime(data.params[0], {
            ...store.getters[dateOptionsGetter],
            isUtc: true,
            toObject: true,
        });

        if (date && month && year) {
            data.params[0] = `${date}.${month}.${year}`;
        }
    }
}

/**
 * Casts parameters to numbers for errors requiring numeric parameters.
 * Updates data.params to an array of numbers.
 * @param {Object} data - The error data object.
 */
function handleParameterCasting(data) {
    if (GLOBAL_ERRORS_CASTING_PARAMETERS_TO_NUMBERS.includes(data.error)) {
        data.params = data.params.map((value) => Number(value));
    }
}

/**
 * Creates an error message using the translation function.
 * Combines data parameters and additional translation parameters.
 * @param {Object} data - The error data object.
 * @param {Object} translationParams - Additional parameters for translation.
 * @param {Function} processErrorMessage - Translation function for error messages.
 * @returns {String} - The translated error message.
 */
function createErrorMessage(data, translationParams, processErrorMessage) {
    return processErrorMessage(`errors.${data.error}`, {
        ...(data?.params?.length ? data.params : []),
        ...(translationParams || {}),
        indefinite: true,
    });
}

/**
 * Generates a detailed error message for MULTI_EXCEPTION errors from the payload.
 *
 * @param {Object} payload - The payload object containing error details.
 * @param {Object} translationParams - Additional translation parameters.
 * @param {Function} processErrorMessage - A function to generate localized error messages.
 * @returns {String} - The formatted error message.
 */
function generateMultiExceptionMessage(payload, translationParams, processErrorMessage) {
    return Object.entries(payload).reduce((acc, [_, val], index, entries) => {
        const { error, params } = val;
        const prefix = entries.length > 1 ? `${acc}${index ? '<br><br>' : ''}${index + 1}. ` : '';
        return `${prefix}${processErrorMessage(`errors.${error}`, {
            ...params,
            ...(translationParams || {}),
            indefinite: true,
        })}`;
    }, '');
}

/**
 * Creates an error handler for the rejected state of the axios interceptor
 * @param {Object} Vue vue instance
 * @param {Object} store vuex store
 * @param {String} langGetter get language getter
 * @param {Object} dateOptionsGetter date options getter
 */
export const createErrorHandler = (Vue, store, langGetter, dateOptionsGetter, enableSendApiErrorsToSentry) => {
    return (error) => {
        const processErrorMessage = Vue.$t || ((it) => it);
        const { code, response, config } = error;

        // Reject "network" errors
        if (!response && !code && code !== 'ECONNABORTED') {
            const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};

            Vue.$gtm.query({
                event: 'network_error',
                url: config.url,
                downlink: connection.downlink,
            });

            error.errorCode = NETWORK_ERROR;
            error.message = processErrorMessage('errors.NETWORK_ERROR', { indefinite: true });
            return Promise.reject(error);
        }
        const { locales } = store.getters[langGetter];
        const errors = locales[Object.keys(locales)[0]].errors.global;
        const api = response?.config?.url.split('/').join('_').toUpperCase() || UNKNOWN_ERROR;
        const hasDataObject = response.data && typeof response.data !== 'string';
        const { data, status } = hasDataObject ? response : { ...response, data: { error: `ERROR_${response.status}_${api}` } };
        const { translationParams } = config || {};
        error.message = processErrorMessage('errors.UNKNOWN', { indefinite: true });
        error.params = data.params || [];

        if (data.error in errors) {
            error.errorCode = data.error;

            handleTimeFormatting(data);
            handleDateFormatting(data, store, dateOptionsGetter);
            handleParameterCasting(data);

            error.message = createErrorMessage(data, translationParams, processErrorMessage);
        }

        if (data.error === MULTI_EXCEPTION) {
            error.errorCode = response.data.error;
            error.message = generateMultiExceptionMessage(data.payload, translationParams, processErrorMessage);
        }
        if (status) {
            error.statusCode = status;
        }
        if (data.payload) {
            error.payload = data.payload;
        }
        if (data.uuid) {
            error.uuid = data.uuid;
        }
        if (enableSendApiErrorsToSentry) {
            Vue.$sentry.withScope(() => {
                Vue.$sentry.addBreadcrumb({
                    message: 'RESPONSE_DATA',
                    data: { fullUrl: decodeURI(getObjectField(response.config, 'url')), status, ...data },
                });
                const errorName = `API ERROR: ${api.split('?')[0]}`;
                Vue.$sentry.captureException(new Error(errorName), (scope) => {
                    scope.setTags({ api_request: true });
                    scope.setTransactionName(errorName);
                    return scope;
                });
            });
        }
        return Promise.reject(error);
    };
};
