<template>
    <div class="container" :class="[imageAlign, cssClass]" @click="trackBannerClick">
        <ResponsiveImage v-if="isSingle" :single="images" />
        <router-link v-else-if="isInternal" :to="href" :target="linkTarget">
            <ResponsiveImage :images="images" />
        </router-link>
        <a v-else :href="href" :target="linkTarget">
            <ResponsiveImage :images="images" />
        </a>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { helper } from '@/modules/core';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { isValidAlignment } from '@/js/cms';
import { linkTarget } from '@/components/content/content-const';

const ResponsiveImage = () => import('@/components/ResponsiveImage');

export default {
    name: 'PackageImage',
    components: { ResponsiveImage },
    props: {
        data: Object,
        target: {
            type: String,
            default: linkTarget.EXTERNAL,
        },
    },
    data() {
        return {
            id: this.data.id,
            link: this.data.link,
            text: this.data.text,
            linkTarget: this.data.target || this.target,
            images: this.data.img,
            isSingle: this.data.subset === 'SINGLE',
            cssClass: this.data.cssClass,
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            country: platformGetter.GET_COUNTRY,
        }),
        href() {
            return this.$formulateLink(this.link);
        },
        imageAlign() {
            return (isValidAlignment(this.data.align) && this.data.align) || 'full-width';
        },
        isInternal() {
            return this.$isInternalLink(this.link);
        },
    },
    methods: {
        $formulateLink: helper.formulateLink,
        $isInternalLink: helper.isInternalLink,
        trackBannerClick() {
            this.$gtm.query({
                event: 'banner_click',
                id: this.id,
                link: this.link,
                text: this.text,
                jurisdiction: this.country,
                isAuthenticated: this.isAuthenticated,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.container {
    display: block;
    line-height: 0;

    img {
        max-width: 100%;
        align-self: center;
    }

    &.align-center {
        text-align: center;
    }

    &.align-left {
        text-align: left;
    }

    &.align-right {
        text-align: right;
    }

    &.full-width > :first-child {
        width: 100%;
        display: block;
    }
}
</style>
