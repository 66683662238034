<template>
    <span>
        <span>{{ text }}</span>
        <template v-if="isClipboardSupported">
            <button class="button button-accent" @click="copyToClipboard()">
                {{ copyButtonLabel }}
            </button>
        </template>
    </span>
</template>

<script>
import { NOTIFICATION_DURATION } from '@/const/notification';
import { isSupportedCopyIntoClipboard } from '@/modules/core/clipboard/const';
import { copyIntoClipboardDelay } from '@/modules/core/clipboard/copyIntoClipboardDelay';

export default {
    name: 'ClipboardCopyText',
    props: {
        text: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            isClipboardSupported: isSupportedCopyIntoClipboard,
            copyButtonLabel: null,
        };
    },
    created() {
        this.copyButtonLabel = this.$t('ui.common.copy');
    },
    methods: {
        copyToClipboard() {
            this.$emit('copy');

            copyIntoClipboardDelay(this.text, NOTIFICATION_DURATION, () => {
                this.$emit('copied');
            });

            this.copyButtonLabel = this.$t('ui.common.copied');
        },
    },
};
</script>

<style lang="scss" scoped>
.button {
    padding: 8px;
    margin-left: 10px;
}
</style>
