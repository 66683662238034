<template>
    <div class="search-tips">
        <p class="tips-title">{{ $t('ui.casinoLobby.search.searchTips') }}</p>
        <ul class="tips-list">
            <li>{{ $t('ui.casinoLobby.search.tipOne') }}</li>
            <li>{{ $t('ui.casinoLobby.search.tipTwo') }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'CasinoSearchTips',
};
</script>

<style scoped lang="scss">
.search-tips {
    margin: 4px 0 8px;
    padding: 12px 16px;
    text-align: center;

    .tips-title {
        @extend %body-normal-font-700;
        color: $main-text;
        margin-bottom: 8px;
    }

    .tips-list {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            gap: 6px;
            @extend %small-details-font-400;

            &::before {
                content: '•';
                @extend %small-details-font-400;
                color: $main-text;
            }
        }
    }
}
</style>
