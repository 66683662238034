<template>
    <div class="bottom-navigation-wrapper" :class="{ 'bottom-navigation-hidden': hide }">
        <div class="bottom-navigation">
            <div class="progress-bar" @click="openBetslipMenu">
                <div class="progress-bar-text">
                    <span class="progress-text truncate" data-test-id="bottomProgressInfo">
                        <template v-if="odds > 1">
                            <span class="bold">{{ $t('ui.openBetslip.odds') }}: </span>
                            <span class="total-odds truncate">{{ $numberFormat(odds) }}</span>
                            <span v-if="hasBonusesData" class="bold divider-symbol">&#183;</span>
                        </template>
                        <span v-if="winBonus">
                            <span class="bold">{{ bonusWinType }}:</span>
                            <span> {{ bonusInfoMessage }}</span>
                        </span>
                        <span v-else-if="hasBonusesData">
                            <renderer :input="legInfoMessage" />
                        </span>
                        <span v-if="cutPlus">
                            <span class="bold divider-symbol">&#183;</span>
                            <template v-if="stake">
                                <span class="bold open-betslip-text">
                                    {{ $t('ui.cutPlus.cutPlusActiveWithStake', { count: cutPlus.misses }) }}
                                </span>
                                <Currency class="cut-plus-currency" :amount="cutPlus.offeredAmount" :format="currencyFormat" />
                            </template>
                            <template v-else>
                                <span class="bold open-betslip-text">{{ $t('ui.cutPlus.cutPlusActive') }}</span>
                            </template>
                        </span>
                    </span>
                </div>
                <div class="progress-bar-fill" :style="{ width: bonusPercentage + '%' }" />
            </div>
            <div class="navigation-menu">
                <div
                    v-for="menuItem of menuItems"
                    :key="menuItem.name"
                    :data-test-id="`bottomMenu_${menuItem.name}`"
                    class="navigation-item"
                    :class="{ transparent: menuItem.name === 'betslip' }"
                >
                    <NavigationItem :ref="menuItem.name" :menu-item="menuItem" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { helper } from '@/modules/core';
import { getter as betslipGetter } from '@/modules/sport/store/modules/betslip/const';
import { getter as sportGetter } from '@/modules/sport/store/const';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { mapGetters, mapState } from 'vuex';
import NavigationItem from '@/components/Fragments/NavigationItem';
import { Currency } from '@/modules/core/components';

export default {
    name: 'BottomNavigation',
    components: { Currency, NavigationItem },
    props: {
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapState({
            rounds: (state) => state.sport.virtualSports.rounds,
            currencyFormat: (state) => state.platform.settings.currency,
        }),
        ...mapGetters({
            odds: betslipGetter.GET_TOTAL_ODDS,
            selectedBetslip: betslipGetter.GET_SELECTED_BETSLIP,
            legInfoData: betslipGetter.GET_LEG_INFO_DATA,
            hasBonusesData: betslipGetter.HAS_BONUSES_DATA,
            totalBonus: betslipGetter.GET_TOTAL_BONUS,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
            pendingBetsCount: sportGetter.GET_PENDING_BETS_COUNT,
            cutPlusCalculation: betslipGetter.CUT_PLUS_CALCULATION,
            stake: betslipGetter.GET_STAKE,
        }),
        menuList() {
            return [
                {
                    name: 'menu',
                    icon: 'icon-hamburger',
                    url: '',
                    action: 'openSidebar',
                    activeKey: 'sidebarOpen',
                    counter: 0,
                },
                {
                    name: 'sports',
                    icon: `icon-football`,
                    url: '/events',
                    counter: 0,
                },
                {
                    name: 'betslip',
                    icon: 'icon-betslip',
                    url: '',
                    action: 'openBetslip',
                    activeKey: 'betslipOpen',
                    counter: 0,
                },
                {
                    name: 'join',
                    icon: 'icon-join-now',
                    url: '/join-now',
                    requireAuth: '0',
                    counter: 0,
                },
                {
                    name: 'myBets',
                    icon: 'icon-mybets-menu',
                    url: '/bets',
                    requireAuth: '1',
                    counter: this.pendingBetsCount,
                },
                {
                    name: 'account',
                    icon: 'icon-top-menu',
                    url: '',
                    action: 'openBottomMenu',
                    activeKey: 'showBottomMenu',
                    counter: 0,
                },
            ];
        },
        menuItems() {
            return this.menuList.filter((item) => helper.processMenuAuth(item.requireAuth, this.isAuthenticated));
        },
        legInfoMessage() {
            const { currentSelections, nextTierPercentage, selectionsToNextTier } = this.legInfoData;
            if (!currentSelections) {
                return this.$t(`ui.openBetslip.${this.countryCodeIs.KE ? 'noLegsBoost' : 'noLegs'}`, {
                    legs: selectionsToNextTier,
                    totalBonus: this.totalBonus,
                });
            }

            return `${this.$t('ui.openBetslip.legs', {
                legs: selectionsToNextTier,
                bonuses: nextTierPercentage,
                winBonus: this.$t(`ui.openBetslip.${this.countryCodeIs.KE ? 'winBoost' : 'winBonus'}`),
                moreSelectionSuffix: selectionsToNextTier === 1 ? '' : 's ',
            })}`;
        },
        bonusWinType() {
            return this.$t(`ui.openBetslip.${this.countryCodeIs.KE ? 'winBoost' : 'winBonus'}`);
        },
        bonusInfoMessage() {
            if (this.winBonus === this.totalBonus) {
                return `${this.winBonus}%`;
            }
            return this.$t('ui.openBetslip.bonusPercentage', {
                currentBonus: this.winBonus,
                totalBonus: this.totalBonus,
            });
        },
        winBonus() {
            return this.legInfoData.currentWinBonus;
        },
        bonusPercentage() {
            return (this.winBonus / this.totalBonus) * 100;
        },
        cutPlus() {
            if (!this.cutPlusCalculation || !this.cutPlusCalculation.allOffers.length > 0) {
                return null;
            }
            const preferred = this.cutPlusCalculation.allOffers.find((o) => o.preferred);
            return preferred ? preferred.amountForEachCut[0] : null;
        },
    },
    methods: {
        $numberFormat: helper.numberFormat,
        openBetslipMenu() {
            const [betslipItem] = this.$refs['betslip'];
            betslipItem.toggleBetslip();
        },
    },
};
</script>

<style scoped lang="scss">
$full-height: 90px;
$menu-height: 65px;
$progress-text-height: $full-height $menu-height;

.bottom-navigation-wrapper {
    position: relative;
    height: $full-height;
    transition: height 0s 0.35s linear;
    z-index: 5;

    &.bottom-navigation-hidden {
        transition: height 0.3s 0s linear;
        height: 0;
    }
}

.bottom-navigation {
    position: absolute;
    top: calc(100% - #{$full-height});
    width: 100%;
    height: $full-height;
    transition: top 0.3s 0.35s ease-in-out, opacity 0s 0s linear, height 0s 0s linear;
    flex-shrink: 0;

    .bottom-navigation-hidden & {
        transition-delay: 0s, 0.35s, 0.6s;
        top: 100%;
    }

    .progress-bar {
        position: relative;
        height: inherit;
        background-color: $primary-color;

        &-text {
            @extend %small-details-font-400;
            position: absolute;
            padding: 4px 12px;
            z-index: 1;
            width: 100%;
            top: 0;
            left: 0;
            height: $progress-text-height;
            display: flex;
            align-items: center;
        }

        &-fill {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $navigation-progress-bar-fill-color;
        }
    }

    .navigation-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        bottom: 0;
        height: $menu-height;
    }

    .navigation-item {
        @extend %small-details-font-400;
        color: $navigation-menu-color;
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 10px 0 16px;
        background-color: $betpawa-black;
    }

    .transparent {
        background-color: transparent;
        padding: 0;
    }

    .divider-symbol {
        margin: 0 4px;
    }

    .total-odds {
        display: inline-block;
        max-width: calc(50% - 42px);
        margin-bottom: -3px;
    }

    .cut-plus-currency {
        display: inline-block;

        ::v-deep .contrast {
            color: inherit;
        }
    }
}

.progress-text {
    flex-shrink: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
}
</style>
