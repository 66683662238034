<template>
    <button class="button button-primary button-full" :disabled="disabled" @click="$emit('click')">
        <template v-if="loading">
            <Spinner visible :zoom="60" class="justify-center button-spinner" />
        </template>
        <template v-else>
            <slot />
        </template>
    </button>
</template>

<script>
import Spinner from '@/modules/core/components/atoms/Spinner';

export default {
    name: 'Button',
    components: {
        Spinner,
    },
    props: {
        loading: Boolean,
        disabled: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.button-spinner {
    position: relative;
    background-color: transparent;
    padding: 0;
    max-height: 17px;
}
</style>
