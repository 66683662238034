export const mutation = {
    SET_SPORT_TYPE: 'sport/setSportType',
    SET_IN_PLAY_DETAILS: 'sport/setInPlayDetails',
    SET_STATS_PERFORM: 'sport/setStatsPerform',
    SET_SPORT_RADAR_JWT: 'sport/setSportRadarJWT',
    UPDATE_SPORTS: 'sport/updateSports',
    SET_TOURNAMENTS: 'sport/setTournaments',
    SET_COUNTRIES: 'sport/setCountries',
    SET_SINGLE_EVENT: 'sport/setSingleEvent',
    CONTAINS_HOT: 'sport/containsHot',
    RESET_SINGLE_EVENT_ERROR: 'sport/resetSingleEventError',
    RESET_SPORTS_ERROR: 'sport/resetSportsError',
    SET_JACKPOT_SELECTED: 'sport/setJackpotSelected',
    RESET_JACKPOT_ERROR: 'sport/resetJackpotError',
    SET_VIRTUAL_ROUNDS: 'sport/setVirtualRounds',
    SET_VIRTUAL_EVENTS: 'sport/setVirtualEvents',
    RESET_VIRTUAL_EVENTS: 'sport/resetVirtualEvents',
    SET_VIRTUAL_STANDINGS: 'sport/setVirtualStandings',
    RESET_VIRTUAL_STANDINGS: 'sport/resetVirtualStandings',
    SET_VIRTUAL_SEASONS: 'sport/setVirtualSeasons',
    SET_VIRTUAL_SEASON: 'sport/setVirtualSeason',
    SET_VIRTUAL_SPORTS_ERROR: 'sport/setVirtualSportsError',
    SET_VIRTUAL_MATCHUPS: 'sport/setVirtualMatchups',
    RESET_VIRTUAL_MATCHUPS: 'sport/resetVirtualMatchups',
    SET_AUTO_CASHOUT_OFFER: 'sport/setAutoCashoutOffer',
    CLEAR_AUTO_CASHOUT_OFFER: 'sport/clearAutoCashoutOffer',
    CLEAR_AUTO_CASHOUT_OFFER_ERROR: 'sport/clearAutoCashoutOfferError',
    SET_CASHOUT_OFFER: 'sport/setRequestCashoutOffer',
    SET_CASHOUT_OFFER_ERROR: 'sport/setCashoutOfferError',
    SET_AUTO_CASHOUT_OFFER_ERROR: 'sport/setAutoCashoutOfferError',
    UPDATE_CASHOUT_POLLING: 'sport/updateCashoutPolling',
    SET_CASHOUT_STATUS: 'sport/setCashoutStatus',
    GENERATE_JACKPOT_UUID: 'sport/generateJackpotUUID',
    TOGGLE_STATISTIC: 'sport/toggleStatistic',
    SET_IS_LIVE_STREAM_AVAILABLE: 'sport/setIsLiveStreamAvailable',
    UPDATE_SINGLE_EVENT_ODDS: 'sport/updateSingleEventOdds',
    UPDATE_SINGLE_EVENT_SCOREBOARD: 'sport/updateSingleEventScoreboard',
    SET_SAVED_EVENT_FILTERS: 'sport/setSavedEventFilters',
    SET_SAVED_EVENT_SORTING: 'sport/setSavedEventSorting',
    SET_IS_CONFIRM_CASHOUT_READY: 'sport/setIsConfirmCashOutReady',
    SET_IS_CASHOUT_REFRESHING: 'sport/setIsCashOutRefreshing',
    CLEAR_EVENT_LIST: 'sport/clearFilteredEventList',
    UPDATE_MY_BETS: 'sport/updateMyBets',
    UPDATE_PENDING_BETS_COUNT: 'sport/updatePendingBetsCount',
    SET_MY_BETS_ERROR: 'sport/setMyBetsError',
};

export const action = {
    GET_WIDGET_BASED_EVENTS: 'sport/getWidgetBasedEvents',
    GET_EVENTS: 'sport/getEvents',
    AUTHORIZE_STATS_PERFORM: 'sport/authorizeStatsPerform',
    AUTHORIZE_SPORT_RADAR: 'sport/authorizeSportRadar',
    CREATE_AUTO_CASHOUT_OFFER: 'sport/createAutoCashoutOffer',
    LOAD_UPCOMING_EVENTS: 'sport/loadUpcomingEvents',
    GET_EVENTS_BY_QUERY: 'sport/getEventsByQuery',
    GET_EVENTS_BY_IDS: 'sport/getEventsByIds',
    GET_SINGLE_EVENT_BY_ID: 'sport/getSingleEventsById',
    GET_UPCOMING_LIST: 'sport/getUpcomingList',
    REMOVE_UPCOMING_EVENTS: 'sport/removeUpcomingEvents',
    GET_JACKPOTS: 'sport/getJackpots',
    GET_JACKPOT_POOL: 'sport/getJackpotPool',
    GET_JACKPOT_TICKET: 'sport/getJackpotTicket',
    ADD_JACKPOT_TICKET: 'sport/addJackpotTicket',
    SET_JACKPOT_ERROR: 'sport/setJackpotError',
    GENERATE_JACKPOT_UUID: 'sport/generateJackpotUUID',
    GET_LIVE_EVENTS_V2: 'sport/getLiveEventsV2',
    GET_SEARCH_RESULTS: 'sport/getSearchResults',
    GET_JACKPOT_BETSLIPS: 'sport/getJackpotBetslips',
    GET_SETTLED_BETSLIPS: 'sport/getSettledBetslips',
    GET_VIRTUAL_BETSLIPS: 'sport/getVirtualBetslips',
    GET_PENDING_BETSLIPS: 'sport/getPendingBetslips',
    RESET_MY_BETS: 'sport/resetMyBets',
    GET_SINGLE_BETSLIP: 'sport/getSingleBetslip',
    GET_DETAIL_BETSLIP: 'sport/getDetailBetslip',
    GET_IN_PLAY_LIST: 'sport/getInPlayList',
    RESET_SPORTS_ERROR: 'sport/resetSportsError',
    GET_VIRTUAL_ROUNDS: 'sport/getVirtualRounds',
    GET_VIRTUAL_LIVE_ROUND: 'sport/getVirtualLiveRound',
    GET_VIRTUAL_STANDINGS: 'sport/getVirtualStanding',
    GET_VIRTUAL_MATCHUPS: 'sport/getVirtualMatchups',
    GET_BETSLIP_AUTO_CASHOUT_OFFER: 'sport/getBetslipAutoCashoutOffer',
    REQUEST_CASHOUT_OFFER: 'sport/requestCashoutOffer',
    REQUEST_CASHOUT_OFFER_AUTO: 'sport/requestCashoutOfferAuto',
    CONFIRM_CASHOUT_OFFER: 'sport/confirmCashoutOffer',
    CHECK_CASHOUT_OFFER_STATUS: 'sport/checkCashoutOfferStatus',
    START_CASHOUT_POLLING: 'sport/startCashoutPolling',
    GET_VIRTUAL_EVENTS_BY_ROUND_ID: 'sport/getVirtualEventsByRoundId',
    GET_EVENT_WITH_PRICES_BY_ID: 'sport/getEventWithPricesById',
    UPDATE_SAVED_EVENT_FILTERS: 'sport/updateSavedEventFilters',
    UPDATE_SAVED_EVENT_SORTING: 'sport/updateSavedEventSorting',
    GET_PENDING_BETS_COUNT: 'sport/getPendingBetsCount',
    GET_BETSLIPS_AUTO_CASHOUT_DETAILS: 'sport/getBetslipsAutoCashoutDetails',
};

export const getter = {
    GET_CASHOUT_OFFER_POLLING: 'sport/getCashoutOfferPolling',
    EVENTS_COUNT_PER_TYPE: 'sport/eventsCountPerType',
    EVENTS_COUNT_BY_DAYS: 'sport/eventsCountByDays',
    EVENTS_COUNT_BY_COMPETITION_BY_DAYS: 'sport/eventsCcountByCompetitionBydays',
    GET_LIVE_EVENTS_TOTAL_COUNT: 'sport/getLiveEventsCount',
    GET_POPULAR_EVENTS: 'sport/getPopularEvents',
    GET_AUTO_CASHOUT_OFFER: 'sport/getAutoCashOutOffer',
    GET_EVENT_BY_ID: 'sport/getEventById',
    GET_MARKETS_BY_ID: 'sport/getMarketsById',
    GET_EVENT_WITH_MARKETS_BY_ID: 'sport/getEventWihMarketsById',
    GET_JACKPOT_BETS: 'sport/getJackpotBets',
    GET_PENDING_BETS: 'sport/getPendingBets',
    GET_SETTLED_BETS: 'sport/getSettledBets',
    GET_VIRTUAL_BETS: 'sport/getVirtualBets',
    GET_SPORT_TYPE: 'sport/getSportType',
    IS_VIRTUAL_SPORT: 'sport/isVirtualSport',
    GET_UPCOMING_VIRTUAL_ROUND_INDEX: 'sport/getUpcomingVirtualRoundIndex',
    GET_JACKPOT_POOL: 'sport/getJackpotPool',
    GET_JACKPOT_UUID: 'sport/getJackpotUUID',
    GET_LOCKED_MARKETS: 'sport/getLockedMarkets',
    GET_SELECTED_VIRTUAL_SEASON: 'sport/getSelectedVirtualSeason',
    GET_SPORT_RADAR_JWT: 'sport/getSportRadarJWT',
    GET_SPORT_EVENTS_COUNTER: 'sport/getSportEventsCounter',
    SAVED_EVENT_FILTERS: 'sport/savedEventFilters',
    SAVED_EVENT_SORTING: 'sport/savedEventSorting',
    GET_AUTO_CASHOUT_OFFER_ERRORS: 'sport/getAutoCashOutOfferErrors',
    GET_CASHOUT_OFFER_ERRORS: 'sport/getCashOutOfferErrors',
    GET_IS_CONFIRM_CASHOUT_READY: 'sport/getIsConfirmCashOutReady',
    GET_IS_CASHOUT_REFRESHING: 'sport/getIsCashOutRefreshing',
    GET_CASHOUT_AMOUNT_OFFER: 'sport/getCashOutAdjustedAmount',
    GET_BOOSTED_EVENTS: 'sport/getBoostedEvents',
    GET_PENDING_BETS_COUNT: 'sport/getPendingBetsCount',
};
