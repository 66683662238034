<template>
    <div data-test-id="otpVerificationStep">
        <h2 class="otp-verification-step-title">
            {{ $t(`ui.accountVerification.verificationTitle.${activeOtpOptionName}`) }}
        </h2>
        <div class="otp-verification-step-header">
            <renderer :input="header" />
        </div>

        <!-- Otp Input component-->
        <OtpInput
            ref="otpInput"
            class="otp-verification-step-input"
            :disabled="isCodeVerificationInProgress"
            :error-message="authError"
            :auto-request="isFirstAttempt"
            @auto-request="handleVerifyOtpCode"
            @input-reset="handleOtpInputErrorReset"
            @input="currentOtp = $event"
        />

        <!-- Captcha Errors -->
        <div v-if="captchaError" class="otp-verification-step-error">
            <renderer :input="captchaError" class="notify error" />
        </div>

        <!-- Resend code state -->
        <OtpVerificationResend
            :is-code-resend-in-progress="isCodeResendInProgress"
            :is-code-verification-in-progress="isCodeVerificationInProgress"
            :is-first-attempt="isFirstAttempt"
            :is-global-limit-reached="isGlobalLimitReached"
            :is-selected-limit-reached="isSelectedLimitReached"
            :is-ussd-active-method="isUssdActiveMethod"
            :code-resend-time-left="codeResendTimeLeft"
            @resend="handleResendOtpCode"
            @contact-us="trackOtpVerificationStepEvent"
        />

        <!-- Information modal for USSD method -->
        <UssdVerificationModal :change-phone-route="changePhoneRoute" :phone-number="phoneNumber" :full-phone-number="fullPhoneNumber" />

        <!-- Remaining requests -->
        <OtpVerificationRemaining
            v-if="!isUssdActiveMethod"
            :attempts-left="activeOtpOption.attemptsLeft || 0"
            @open="trackOtpVerificationStepEvent('information_button_clicked')"
        />

        <CaptchaWidget
            v-if="showCaptcha"
            ref="captchaWidget"
            :name="$options.name"
            class="otp-verification-step-captcha-widget"
            @error="trackCloudflareFailures"
        />

        <!-- Verify otp code button -->
        <Button
            v-if="!isFirstAttempt"
            class="otp-verification-step-button"
            data-test-id="otpVerificationButton"
            :disabled="isVerifyButtonDisabled"
            :loading="isCodeVerificationInProgress"
            @click="handleVerifyOtpCode"
        >
            {{ $t('ui.accountVerification.verifyButton') }}
        </Button>

        <!-- Dividing line -->
        <div class="otp-verification-step-bottom-line" />

        <!-- Select another otp option -->
        <OtpVerificationExpansion
            v-if="showVerificationOptions"
            :expanded="isGlobalLimitReached || isSelectedLimitReached"
            :otp-options="anotherUserOtpOptions"
            :selected-otp-option="selectedAnotherOption"
            :is-get-otp-code-button-disabled="isGetOtpCodeButtonDisabled"
            @change-option="handleChangeAnotherUserOtpOption"
            @resend-with-new-method="handleResendOtpCodeWithNewMethod"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { helper } from '@/modules/core';
import { normalizeOtpOptions } from '@/modules/platform/utils/verification';
import {
    otpOptionsFields,
    otpOptionsNames,
    modalNames,
    CODE_RESEND_COOL_DOWN_SECONDS,
    GtmVerificationPages,
} from '@/modules/platform/const/verification';
import { user as userEndpoints } from '@/modules/platform/endpoints';

import { getter as coreGetter } from '@/modules/core/store/const';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { action as authAction, getter as authGetter, mutation as authMutation } from '@/modules/platform/store/modules/auth/const';

import Button from '@/modules/platform/components/content/Button.vue';
import OtpInput from '@/components/OtpInput';
import UssdVerificationModal from '@/modules/platform/components/Verification/UssdVerificationModal';

import OtpVerificationResend from './OtpVerificationResend.vue';
import OtpVerificationExpansion from './OtpVerificationExpansion.vue';
import OtpVerificationRemaining from './OtpVerificationRemaining.vue';

export default {
    name: 'OtpVerificationStep',
    components: {
        Button,
        OtpInput,
        UssdVerificationModal,
        OtpVerificationResend,
        OtpVerificationExpansion,
        OtpVerificationRemaining,
    },
    props: {
        isAccountVerification: {
            type: Boolean,
        },
        changePhoneRoute: {
            type: String,
        },
        selectedOtpOption: {
            type: String,
        },
        phoneNumber: {
            type: String,
        },
        onVerificationSuccessCallback: {
            type: Function,
        },
    },
    data() {
        return {
            ussdModalName: modalNames.USSD_VERIFICATION_MODAL,

            currentOtp: '',
            userSelectedAnotherOption: '',
            otpTimerStart: '',
            timerInterval: null,
            codeResendTimeLeft: 0,

            isFirstAttempt: true,
            isResendCodeRequestIsMade: false,
            isIncorrectCodeSubmitted: false,
        };
    },
    computed: {
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            areLoading: coreGetter.ARE_LOADING,
            authError: authGetter.GET_AUTH_ERROR,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            phonePrefix: platformGetter.GET_PHONE_COUNTRY_CODE,
            otpOptions: platformGetter.GET_OTP_OPTIONS,
            token: authGetter.SECURED_TOKEN,
            captchaError: authGetter.GET_CAPTCHA_ERROR,
            userOtpOptions: authGetter.GET_USER_OTP_OPTIONS,
            isGlobalLimitReached: authGetter.GET_IS_GLOBAL_LIMIT_REACHED,
        }),
        isCodeResendInProgress() {
            return this.isLoading(authAction.REQUEST_CODE);
        },
        isCodeVerificationInProgress() {
            return this.areLoading([authAction.VERIFY_VERIFICATION_CODE, authAction.VERIFY_OTP_CODE]);
        },
        fullPhoneNumber() {
            return helper
                .formatPhoneNumber(this.phoneNumber, this.phonePrefix, this.brandPreference.phoneNumberFormat)
                .replace(this.phonePrefix, `${this.phonePrefix} `);
        },
        currentOtpOptions() {
            return this.userOtpOptions || normalizeOtpOptions(this.otpOptions);
        },
        activeOtpOption() {
            return this.currentOtpOptions.find(({ name }) => name === this.selectedOtpOption) || {};
        },
        activeOtpOptionName() {
            return otpOptionsNames[this.activeOtpOption.name];
        },
        anotherUserOtpOptions() {
            return this.currentOtpOptions.filter(({ name }) => name !== this.activeOtpOption.name) || [];
        },
        selectedAnotherOption() {
            if (this.userSelectedAnotherOption) return this.userSelectedAnotherOption;

            const firstOtpOption = this.anotherUserOtpOptions[0] || {};

            return firstOtpOption.attemptsLeft > 0 ? firstOtpOption.name : '';
        },
        otpOptionName() {
            return this.$t(`ui.accountVerification.otpOptionNames.${otpOptionsNames[this.activeOtpOption.name]}`);
        },
        header() {
            return this.$t(`ui.accountVerification.${this.isCallMethod ? 'callMessage' : 'sentMessage'}`, {
                otpOptionName: this.otpOptionName,
                fullPhoneNumber: this.fullPhoneNumber,
                phoneNumber: this.phoneNumber,
                routeName: this.changePhoneRoute,
            });
        },
        showCaptcha() {
            const { pathsWithCaptcha } = this.brandPreference;

            if (!pathsWithCaptcha) return false;

            return pathsWithCaptcha.includes(userEndpoints.updatePassword) || pathsWithCaptcha.includes('/' + userEndpoints.updatePassword);
        },
        isVerifyButtonDisabled() {
            const captcha = this.showCaptcha && !this.token;
            const otpComplete = this.currentOtp.length < 4;

            return captcha || otpComplete || !!this.authError || this.isCodeVerificationInProgress;
        },
        isGetOtpCodeButtonDisabled() {
            const captcha = this.showCaptcha && !this.token;

            return !this.selectedAnotherOption || captcha;
        },
        isSelectedLimitReached() {
            return !this.activeOtpOption.attemptsLeft || this.activeOtpOption.attemptsLeft < 1;
        },
        isCallMethod() {
            return this.activeOtpOption.name === otpOptionsFields.VOICE;
        },
        isUssdActiveMethod() {
            return this.selectedOtpOption === otpOptionsFields.USSD;
        },
        showVerificationOptions() {
            return (
                this.currentOtpOptions.length > 1 &&
                (this.isIncorrectCodeSubmitted || this.isSelectedLimitReached || this.isUssdActiveMethod)
            );
        },
    },
    mounted() {
        // There is no timer for USSD, we just show a modal
        if (this.isUssdActiveMethod) return this.openUssdModal();

        if (this.selectedOtpOption) {
            this.startTimer();
        }
    },
    beforeDestroy() {
        if (this.timerInterval) clearInterval(this.timerInterval);
    },
    methods: {
        requestOtpCode(newMethodName) {
            this.$store
                .dispatch(authAction.REQUEST_CODE, {
                    phoneNumber: this.phoneNumber,
                    resetMethodName: newMethodName || this.selectedOtpOption,
                    isVerificationCode: this.isAccountVerification,
                })
                .then(({ isGlobalLimitReached }) => {
                    this.startTimer();

                    if (this.showCaptcha) this.resetCaptchaHandler();

                    if (isGlobalLimitReached) {
                        this.trackOtpVerificationStepEvent('global_limit_reached');
                    }
                });
        },
        handleResendOtpCode() {
            this.trackOtpVerificationStepEvent('resend_code_button_clicked');

            this.resetInput();

            if (this.isUssdActiveMethod) {
                this.openUssdModal();

                return;
            }

            this.isResendCodeRequestIsMade = true;

            this.requestOtpCode();
        },
        handleResendOtpCodeWithNewMethod() {
            this.trackOtpVerificationStepEvent('get_verification_code_for_another_method_clicked');
            const selectedOption = this.selectedAnotherOption;

            this.$emit('onNewOtpOption', selectedOption);
            this.resetState();

            if (selectedOption === otpOptionsFields.USSD) {
                this.openUssdModal();
                return;
            }

            this.requestOtpCode(selectedOption);
        },
        handleVerifyOtpCode() {
            // We need this for situations when the captcha has not loaded yet
            if (this.isVerifyButtonDisabled) {
                this.isFirstAttempt = false;
                return;
            }

            if (!this.isFirstAttempt) {
                this.trackOtpVerificationStepEvent('verify_button_clicked');
            }

            const endpoint = this.isAccountVerification ? authAction.VERIFY_VERIFICATION_CODE : authAction.VERIFY_OTP_CODE;

            this.$store
                .dispatch(endpoint, {
                    phoneNumber: this.phoneNumber,
                    otpCode: this.currentOtp,
                    verificationCode: this.currentOtp,
                })
                .then(() => {
                    this.onVerificationSuccessCallback(this.currentOtp);
                })
                .catch(() => {
                    this.isIncorrectCodeSubmitted = true;

                    if (this.showCaptcha) this.resetCaptchaHandler();
                })
                .finally(() => {
                    this.isFirstAttempt = false;
                });
        },
        handleChangeAnotherUserOtpOption(optionName) {
            this.userSelectedAnotherOption = optionName;
            this.trackOtpVerificationStepEvent('another_verification_method_selected', optionName);
        },
        handleOtpInputErrorReset() {
            this.$store.dispatch(authAction.RESET_ERROR);
        },
        startTimer() {
            if (this.timerInterval || this.isSelectedLimitReached) return;

            this.otpTimerStart = Date.now();

            this.codeResendTimeLeft = CODE_RESEND_COOL_DOWN_SECONDS;

            this.timerInterval = setInterval(() => {
                const remainingTime = this.getRemainingTime();

                if (remainingTime <= 0) return this.resetTimer();

                this.codeResendTimeLeft = remainingTime;
            }, 1000);
        },
        resetTimer() {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
                this.timerInterval = null;
            }

            this.codeResendTimeLeft = 0;
            this.otpTimerStart = '';
        },
        hasTimeRemaining() {
            return this.otpTimerStart && this.getRemainingTime() > 0;
        },
        resetInput() {
            this.$refs.otpInput.resetOtp();
            this.$store.dispatch(authAction.RESET_ERROR);
        },
        resetState() {
            this.resetInput();

            this.isIncorrectCodeSubmitted = false;
            this.isFirstAttempt = true;
            this.userSelectedAnotherOption = '';

            this.resetTimer();
        },
        getRemainingTime() {
            const currentTime = Date.now();
            const elapsedTime = Math.floor((currentTime - this.otpTimerStart) / 1000);

            return CODE_RESEND_COOL_DOWN_SECONDS - elapsedTime;
        },
        trackCloudflareFailures(reason) {
            this.$gtm.query({
                event: 'verification_cloudflare_failure',
                reason,
            });
        },
        openUssdModal() {
            return this.$modal.show(this.ussdModalName);
        },
        resetCaptchaHandler() {
            if (this.$refs.captchaWidget) {
                this.$refs.captchaWidget.resetCaptcha();
                this.$store.commit(authMutation.SET_SECURITY_TOKEN, '');
            }
        },
        trackOtpVerificationStepEvent(event, otpOption) {
            this.$gtm.query({
                event,
                page: this.isAccountVerification ? GtmVerificationPages.VERIFY_VERIFICATION_CODE : GtmVerificationPages.VERIFY_OTP_CODE,
                phoneNumber: this.phoneNumber,
                selectedOtpMethod: otpOption || this.selectedOtpOption,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.otp-verification-step-title {
    text-align: center;
    margin-bottom: 8px;
}

.otp-verification-step-header {
    text-align: center;
    margin-bottom: 20px;
}

.otp-verification-step-input {
    margin-bottom: 12px;
}

.otp-verification-step-error {
    margin-bottom: 12px;

    & span {
        display: block;
    }
}

.otp-verification-step-captcha-widget {
    margin-bottom: 8px;
}

.otp-verification-step-button {
    margin-bottom: 20px;
}

.otp-verification-step-bottom-line {
    height: 1px;
    background-color: $greyish;
}
</style>
