<template>
    <Modal class="ussd-verification-modal" :name="modalName" fixed :options="{ overlay: true }" @close="close">
        <template #header>
            <div class="ussd-verification-modal-header">
                <h3>{{ $t('ui.accountVerification.getVerificationCode') }}</h3>

                <a @click="close">
                    <SvgIcon vertical-align="top" class="icon icon-size-small" icon-id="icon-close" />
                </a>
            </div>
        </template>

        <p class="ussd-verification-modal-sub-title">
            <renderer
                :input="
                    $t('ui.accountVerification.yourMobileIs', {
                        phoneNumber,
                        fullPhoneNumber,
                        routeName: changePhoneRoute,
                    })
                "
            />
        </p>

        <span class="ussd-verification-modal-label">
            {{ $t('ui.accountVerification.ussdVerificationModal.label') }}
        </span>

        <DynamicSlot :component="ussdModalSlot" />
    </Modal>
</template>
<script>
import { strapiPageSlots } from '@/modules/platform';
import { DynamicSlot } from '@/modules/platform/components';
import { modalNames } from '@/modules/platform/const/verification';
import { action as platformAction } from '@/modules/platform/store/const';

export default {
    name: 'UssdVerificationModal',
    components: { DynamicSlot },
    props: {
        phoneNumber: {
            type: String,
            required: true,
        },
        changePhoneRoute: {
            type: String,
            required: true,
        },
        fullPhoneNumber: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            modalName: modalNames.USSD_VERIFICATION_MODAL,
            ussdModalSlot: strapiPageSlots.USSD_MODAL_SLOT_NAME,
        };
    },
    created() {
        this.$store.dispatch(platformAction.GET_CONTENT, this.ussdModalSlot);
    },
    methods: {
        close() {
            this.$modal.hide(this.modalName);
        },
    },
};
</script>

<style scoped lang="scss">
.ussd-verification-modal {
    ::v-deep {
        .modal-body {
            padding: 0;
        }

        .modal-container {
            padding: 16px;
        }
    }
}

.ussd-verification-modal-header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 16px;

    h3 {
        @extend %h4-font-500;
        margin: 0;
    }
}

.ussd-verification-modal-sub-title {
    margin-bottom: 12px;
    @extend %body-normal-font-400;
}

.ussd-verification-modal-label {
    display: block;
    margin-bottom: 12px;
    @extend %small-details-font-400;
}
</style>
