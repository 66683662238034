<template>
    <div>
        <div v-if="isFeeVisible" class="deposit-fee-summary text">
            <div class="row">
                <span>{{ $t('ui.payment.deposit.chargeableFee.depositAmount') }}:</span>
                <Currency
                    class="right"
                    :amount="chargeableFee.initialAmount || amount || 0"
                    :format="currencyFormat"
                    :contrast="false"
                    :data-test-id="`depositAmount`"
                />
            </div>
            <div class="deposit-fee-summary-row">
                <DepositFeeLabel :provider-chargeable-fee="providerChargeableFee" />
                <Currency
                    class="right"
                    :amount="feeAmount"
                    :format="currencyFormat"
                    :is-loading="isLoading"
                    :contrast="false"
                    :data-test-id="`depositFee`"
                >
                    -
                </Currency>
            </div>
            <div v-if="telcoFee.compensationAmount" class="deposit-fee-summary-row">
                <span>{{ $t('ui.payment.deposit.chargeableFee.compensationBonus') }}:</span>
                <Currency
                    class="right bold bonus"
                    :amount="telcoFee.compensationAmount"
                    :format="currencyFormat"
                    :is-loading="isLoading"
                    :contrast="false"
                    :data-test-id="`depositCompensationAmount`"
                >
                    <template #amount>
                        <span>+</span>
                    </template>
                </Currency>
            </div>
            <div class="deposit-fee-summary-row">
                <span>{{ $t('ui.payment.deposit.chargeableFee.totalBalanceAdded') }}:</span>
                <Currency
                    class="right bold"
                    :amount="chargeableFee.finalAmount || telcoFee.totalAmount || 0"
                    :format="currencyFormat"
                    :is-loading="isLoading"
                    :contrast="false"
                    :data-test-id="`finalDepositAmount`"
                />
            </div>
        </div>
        <div v-else class="deposit-fee-summary-notify notify warning">
            <renderer :input="$t('ui.payment.deposit.chargeableFee.calculationFailed')" />
        </div>
    </div>
</template>

<script>
import { Currency } from '@/modules/core/components';
import { mapState } from 'vuex';
import DepositFeeLabel from './DepositFeeLabel.vue';

export default {
    name: 'DepositFee',
    components: { Currency, DepositFeeLabel },
    props: {
        amount: {
            type: Number,
            required: true,
        },
        provider: {
            type: Object,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        telcoFee: {
            type: Object,
        },
        chargeableFee: {
            type: Object,
        },
    },
    computed: {
        ...mapState({
            currencyFormat: (state) => state.platform.settings.currency,
        }),
        isFeeVisible() {
            return this.isLoading || !!this.telcoFee.totalAmount || this.chargeableFee.finalAmount;
        },
        providerChargeableFee() {
            const { chargeableFeeAmount, chargeableFeeStructure } = this.provider || {};
            return { chargeableFeeAmount, chargeableFeeStructure };
        },
        feeAmount() {
            return this.chargeableFee.feeAmount ?? this.telcoFee.feeAmount ?? 0;
        },
    },
};
</script>

<style scoped lang="scss">
.deposit-fee-summary {
    padding-bottom: 8px;

    &-row {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &-notify {
        margin: 0 0 8px 0;
    }
}

.currency {
    text-transform: uppercase;
    color: $main-text;
    white-space: nowrap;
    margin-left: 3px;

    &.bonus {
        color: $green-success;
    }
}
</style>
