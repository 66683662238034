<template>
    <div class="content" :class="{ 'is-home-page': isHomePage }">
        <div class="title">
            <Headline v-if="isHomePage" :title="title" :is-category="true" :icon="iconId" />
            <CasinoCollectionTitle
                v-else-if="title"
                :games-count="gamesCount"
                :games-limit="games.length"
                :show-view-all="showViewAll"
                :title="title"
                :icon-id="iconId"
                @casino-title-click="$emit('casino-title-click')"
            />
        </div>
        <div v-if="games.length > 0">
            <div class="casino-list-wrapper">
                <div ref="casino-list" class="casino-list" :style="casinoListStyle">
                    <div v-for="(game, index) in games" :key="game.id" :ref="`casino-tile-${index}`" class="casino-list-item">
                        <CasinoCollectionGameTile :game="game" />
                    </div>
                </div>

                <div
                    v-if="isSliderNavVisible && !isFirstSlide"
                    class="slider-nav slider-nav-prev"
                    data-test-id="casino-slider-prev"
                    @click="scrollToPrevSlide"
                >
                    <SvgIcon icon-id="arrow_left" class="icon icon-size-medium" />
                </div>
                <div
                    v-if="isSliderNavVisible && !isLastSlide"
                    class="slider-nav slider-nav-next"
                    data-test-id="casino-slider-next"
                    @click="scrollToNextSlide"
                >
                    <SvgIcon icon-id="arrow_right" class="icon icon-size-medium" />
                </div>
            </div>
            <ul class="casino-dots" role="tablist" style="">
                <li v-for="i in sliderNumberOfSlides" :key="i" :class="`pointer ${activeDot === i - 1 ? `active` : ''}`">
                    <button type="button" @click="scrollToDot(i - 1)">{{ i }}</button>
                </li>
            </ul>
        </div>
        <div v-else-if="isFavourites && games.length === 0" class="fav-empty">
            <CasinoEmptyList>
                <CasinoNoFavourites />
            </CasinoEmptyList>
        </div>
        <CasinoShowAllGames v-if="showMoreGamesOnBottom" />
    </div>
</template>

<script>
import { deviceType } from '@/modules/core';
import CasinoCollectionGameTile from '@/modules/casino/strapiComponents/components/CasinoCollectionGameTile.vue';
import CasinoCollectionTitle from '@/modules/casino/strapiComponents/components/CasinoCollectionTitle.vue';
import Headline from '@/components/Headline.vue';
import CasinoShowAllGames from '@/components/content/CasinoShowAllGames.vue';
import CasinoEmptyList from '@/modules/casino/components/CasinoEmptyList.vue';
import CasinoNoFavourites from '@/modules/casino/components/CasinoNoFavourites.vue';

export default {
    name: 'CasinoCollectionSlider',
    components: {
        CasinoNoFavourites,
        CasinoShowAllGames,
        Headline,
        CasinoEmptyList,
        CasinoCollectionTitle,
        CasinoCollectionGameTile,
    },
    props: {
        games: Array,
        gamesCount: Number,
        title: String,
        iconId: String,
        showViewAll: Boolean,
        isFavourites: Boolean,
        isHomePage: Boolean,
        showMoreGamesOnBottom: Boolean,
        columnCount: Number,
        rowsCount: Number,
    },
    data() {
        return {
            scrollPos: 0,
        };
    },
    computed: {
        sliderNumberOfSlides() {
            return Math.ceil(this.games.length / this.columnCount / this.rowsCount);
        },
        activeDot() {
            return Math.round(this.scrollPos * this.sliderNumberOfSlides);
        },
        isSliderNavVisible() {
            return !deviceType.isSmartPhone();
        },
        isFirstSlide() {
            return this.activeDot === 0;
        },
        isLastSlide() {
            return this.activeDot === this.sliderNumberOfSlides - 1;
        },
        casinoListStyle() {
            return {
                'grid-auto-columns': `calc((100% - (${this.columnCount} - 1) * var(--gap)) / ${this.columnCount})`,
                'grid-template-rows': `repeat(${this.rowsCount}, auto)`,
            };
        },
    },
    mounted() {
        const listDOM = this.$refs['casino-list'];
        if (listDOM) {
            listDOM.addEventListener('scroll', this.onScrolled, { passive: true });
        }
    },
    beforeDestroy() {
        const listDOM = this.$refs['casino-list'];
        if (listDOM) {
            this.$refs['casino-list'].removeEventListener('scroll', this.onScrolled);
        }
    },
    methods: {
        scrollToDot(dotIndex) {
            let slideIdToScrollTo = dotIndex * this.columnCount * this.rowsCount;
            const tileRefId = `casino-tile-${slideIdToScrollTo}`;
            this.$nextTick(() => {
                const [tile] = this.$refs[tileRefId] ?? [];
                if (tile) {
                    tile.scrollIntoView({ block: 'nearest', inline: 'start', behavior: 'smooth' });
                }
            });
        },
        scrollToNextSlide() {
            this.scrollToDot(this.activeDot + 1);
        },
        scrollToPrevSlide() {
            this.scrollToDot(this.activeDot - 1);
        },
        onScrolled(event) {
            const { target } = event;
            const { scrollLeft, scrollWidth } = target;
            this.scrollPos = scrollLeft / scrollWidth;
        },
    },
};
</script>

<style scoped lang="scss">
.casino-list-wrapper {
    position: relative;
}
.slider-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: $betpawa-black;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    z-index: 1;
    cursor: pointer;

    .icon {
        fill: $white-bg;
    }

    &-prev {
        left: 12px;
    }

    &-next {
        right: 12px;
    }
}
.content {
    margin-top: 16px;
    margin-bottom: 16px;

    .title {
        padding: 0 12px;
    }

    &.is-home-page {
        margin-top: 0;
        .title {
            padding: 0;
        }
    }

    .casino-list {
        padding: 0 12px 0 12px;
        margin-top: 12px;
        display: grid;
        grid-auto-flow: column;
        --gap: 12px;
        grid-gap: calc(var(--gap) - 4px);
        overflow-y: hidden;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        user-select: none;
        --columns: 2;

        @include ios_le_12 {
            scroll-snap-type: none;
        }

        .casino-list-item {
            scroll-margin: 12px;
            user-select: none;
            scroll-snap-align: center;
        }
    }

    .casino-dots {
        display: flex;
        justify-content: center;

        margin: 0;
        padding: 8px 0;

        list-style-type: none;

        li {
            margin: 0 3px;
        }

        button {
            display: block;
            width: 6px;
            height: 6px;
            padding: 0;

            border: none;
            border-radius: 100%;
            background-color: $greyish;

            text-indent: -9999px; // hides text from the dots, keeping the dots still accessible
        }

        li.active button {
            background-color: $green;
        }
    }

    .fav-empty {
        padding: 0 12px;
    }
}
</style>
