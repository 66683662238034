<template>
    <div class="bets-list">
        <Spinner v-if="!items && isLoading" :visible="true" class="align-center inset viewport-padding" />
        <div v-else-if="!items || items.length === 0" class="no-data-text">
            <slot name="empty" />
        </div>
        <template v-else>
            <div
                v-for="(bet, index) in items"
                :key="`${bet.id}-${index}`"
                class="bet"
                :data-test-id="`bet-${type}-${bet.id}`"
                :data-test-class="`bet-${type}`"
                @click="openBetslip(bet)"
            >
                <div class="bet-line bet-header">
                    <div class="header-container">
                        <div class="header-title">
                            <span class="time">{{ getTime(bet) }},&nbsp; </span>
                            <span class="date"> {{ getDate(bet) }}</span>
                            <span class="bet-live-now">
                                <SvgIcon
                                    v-if="type === myBetsType.PENDING && bet.settlementState.live > 0"
                                    :title="$t('ui.live.live')"
                                    vertical-align="baseline"
                                    class="icon-size-medium"
                                    icon-id="icon-live"
                                />
                            </span>
                        </div>

                        <div class="id" data-test-id="bet-id">
                            {{ $t('ui.myBets.id') }}: #{{ bet.id }}
                            <span v-if="bet.virtualInfo"> - {{ bet.virtualInfo.seasonName }}</span>
                        </div>
                    </div>
                </div>
                <div class="bet-line bet-status">
                    <template v-if="!isOutcome">
                        <div class="outcome">{{ bet.settlementState.settled }}/{{ bet.settlementState.total }}</div>
                        <slot name="icon" :bet="bet" />
                    </template>
                    <template v-else>
                        <div class="result-container">
                            <span class="result-text">{{ $t('ui.myBets.result') }}: </span>
                            <span v-if="bet.status === betStatus.WON || bet.status === betStatus.WIN_CUT_PLUS" class="positive status-text">
                                {{ $t('ui.myBets.won').toUpperCase() }}
                                <Badge class="badge-result" type="win" mode="medium" />
                            </span>
                            <span v-else-if="bet.status === betStatus.PENDING" class="neutral status-text">
                                {{ $t('ui.myBets.pending').toUpperCase() }}
                                <Badge class="badge-result" type="pending" mode="medium" />
                            </span>
                            <span v-else-if="bet.status === betStatus.CASHOUT" class="positive status-text">
                                {{ $t('ui.cashout.cashout').toUpperCase() }}
                                <Badge class="badge-result" type="win" mode="medium" />
                            </span>
                            <span v-else-if="bet.status === betStatus.CANCELLED" class="neutral status-text">
                                {{ $t('ui.myBets.void').toUpperCase() }}
                                <Badge class="badge-result" type="cancelled" mode="medium" icon-name="icon-close" />
                            </span>
                            <span v-else class="negative status-text">
                                {{ $t('ui.myBets.lost').toUpperCase() }}
                                <Badge class="badge-result" type="lose" mode="medium" />
                            </span>
                        </div>
                    </template>
                </div>
                <div class="bet-status-line" />

                <div class="bet-line bet-body">
                    <div class="bet-detail">
                        <span class="label">
                            {{ $t(`ui.myBets.${bet.taxInfo.stakeTaxEnabled ? 'amount' : 'stake'}`).toUpperCase() }}
                        </span>
                        <Currency class="value" :contrast="false" :amount="bet.taxInfo.stakeTaxEnabled ? bet.amount : bet.stake" />
                    </div>
                    <div class="bet-detail">
                        <span class="label">{{ $t('ui.myBets.odds').toUpperCase() }}</span>
                        <span class="value">{{ $numberFormat(bet.payout.totalOdds, undefined, undefined, ' ') }}</span>
                    </div>
                    <div class="bet-detail end">
                        <template v-if="shouldDisplayPayout(bet)">
                            <span class="label">{{ $t('ui.myBets.payoutInList').toUpperCase() }}</span>
                            <div class="currency-container bold-symbol">
                                <Currency class="value" :amount="getPayout(bet)" :format="settingCurrency" />
                            </div>
                        </template>
                    </div>
                </div>

                <slot :bet="bet" :open-betslip="openBetslip" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Spinner, Currency } from '@/modules/core/components';
import { helper } from '@/modules/core';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { routeName } from '@/router/const-name';
import Badge from '@/components/Badge.vue';
import { betStatus, myBetsType, betslipType as betslipTypeEnum } from '@/modules/sport';

export default {
    name: 'BetsList',
    components: { Badge, Spinner, Currency },
    props: {
        type: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            default: null,
        },
        hasMore: {
            type: Boolean,
            default: false,
        },
        isOutcome: {
            type: Boolean,
            default: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            betStatus,
            myBetsType,
        };
    },
    computed: {
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
        }),
        ...mapState({
            decimalBetPrecision: (state) => state.platform.settings.betting.real.precision,
            settingCurrency: (state) => state.platform.settings.currency,
        }),
    },
    methods: {
        openBetslip({ id, betslipType }) {
            const betslipTypeParam =
                betslipTypeEnum.VIRTUAL === betslipType.toLowerCase() ? betslipTypeEnum.VIRTUAL : betslipTypeEnum.REGULAR;
            this.$router.push({ name: routeName.BETSLIP, params: { id, betslipType: betslipTypeParam } });
        },
        getTime(bet) {
            return helper.formatDateTime(bet.placed, {
                needDate: false,
                ...this.dateOptions,
                isUtc: true,
            });
        },
        getDate(bet) {
            return helper.formatDateTime(bet.placed, {
                needTime: false,
                ...this.dateOptions,
                isUtc: true,
            });
        },
        getPayout({ status, cashoutInfo, payout: { payout }, taxInfo: { compensationAmount } }) {
            if (status === betStatus.CASHOUT) {
                return cashoutInfo.result.net + cashoutInfo.result.whtCompensation;
            }
            return payout + (compensationAmount || 0);
        },
        shouldDisplayPayout(bet) {
            const betStatusWin = [betStatus.WON, betStatus.WIN_CUT_PLUS].includes(bet.status);
            const cashoutInfo = bet.cashoutInfo && bet.cashoutInfo.result;
            return !this.isOutcome || betStatusWin || cashoutInfo;
        },
        $numberFormat: helper.numberFormat,
    },
};
</script>

<style lang="scss" scoped>
.no-data-text {
    @extend %body-big-font-400;
    margin: 14px;
    text-align: center;
}

.bets-list {
    padding: 20px 12px;
}

.bet {
    @extend %small-details-font-400;
    border: $single-betslip-border;
    color: $main-text;
    margin-bottom: 12px;

    &-status-line {
        border-bottom: $single-betslip-border;
        margin-right: 12px;
        margin-left: 12px;
    }

    &-live-now {
        width: 16px;
        height: 16px;
        margin-left: 8px;

        svg {
            width: 100%;
            height: 100%;
            fill: $countdown-refresh-icon-color;
        }
    }

    &-line {
        width: 100%;
        padding: 12px;

        &.bet-body {
            display: flex;
            flex-wrap: wrap;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.bet-header {
            display: flex;
            @extend %body-normal-font-500;
            align-items: flex-start;
            background-color: $light-grey;
            border-bottom: 1px solid $medium-grey;
            padding: 10px 12px;

            .header-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                flex-grow: 1;
            }

            .date {
                @extend %body-normal-font-500;
            }

            .time {
                @extend %body-normal-font-400;
            }

            .id {
                @extend %body-normal-font-500;
                color: $disabled-text;
                text-align: right;
            }
        }

        &.bet-status {
            color: $betline-header-status-color;
            font-weight: 700;
            text-align: right;
            white-space: nowrap;
            fill: $betline-header-status-color;
            display: flex;
            padding: 10px 12px;
            justify-content: space-between;

            .result-container {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                justify-content: space-between;
                align-items: center;
            }

            .result-text {
                color: $dark-grey-3;
                @extend %body-normal-font-500;
            }

            .outcome {
                @extend %body-normal-font-700;
            }
        }

        .bet-detail {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;
            font-weight: 700;
            padding-right: 4px;

            &.end {
                align-items: flex-end;
                padding-right: 0;
            }

            &.success {
                .value {
                    color: $green-success;
                }
            }

            .label {
                @extend %small-details-font-500;
                color: $disabled-text;
            }

            .value {
                @extend %body-normal-font-400;
            }

            .bold-symbol .value {
                @extend %body-normal-font-700;
            }

            .currency {
                &-container {
                    display: flex;
                    align-items: center;
                }

                &.value {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    ::v-deep {
                        .symbol {
                            font-size: $small-link-font-size;
                            color: $dark-grey-2;
                        }
                    }
                }
            }
        }

        .positive {
            color: $green-success;
        }

        .neutral {
            color: $disabled-text;
        }

        .negative {
            color: $error-red;
        }

        .status-text {
            @extend %body-normal-font-700;
        }
    }

    .header-title {
        display: flex;
        align-items: center;
    }
}
</style>
