export const CASHOUT_OFFER_LOCKED_ERRORS = [
    'FOB_WRONG_CASHOUT_VALUE',
    'FOB_CASHOUT_OFFLINE_PRICES',
    'FOB_ALREADY_CASHOUT',
    'CASHOUT_OFFLINE_PRICES',
    'CASHOUT_WRONG_CASHOUT_VALUE',
    'CASHOUT_ODDS_CHANGED',
    'CASHOUT_UNKNOWN_SERVER_ERROR',
    8053,
    8054,
    8055,
];
export const CASHOUT_OFFER_NOT_CASHOUTABLE = [
    'FOB_WRONG_STATUS_FOR_CASHOUT',
    'FOB_BETSLIP_LOST_CANT_CASHOUT',
    'FOB_BETSLIP_WON_CANT_CASHOUT',
    'FOB_BETSLIP_NOT_CASHOUTABLE',
    'CASHOUT_BETSLIP_NOT_CASHOUTABLE',
    'CASHOUT_BETSLIP_WON_CANT_CASHOUT',
    'CASHOUT_BETSLIP_LOST_CANT_CASHOUT',
    'CASHOUT_WRONG_STATUS_FOR_CASHOUT',
    'CASHOUT_INVALID_BETSLIP',
    'CASHOUT_INVALID_ID',
    'CASHOUT_IS_DISABLED',
    'CASHOUT_ALL_ITEMS_SETTLED',
    'CASHOUT_OFFER_EXCEEDED_ALLOWED_CHANGE',
    'CASHOUT_OFFER_LESS_THAN_MINIMUM_OFFER',
    8049,
    8050,
    8051,
    8052,
    'CASHOUT_BETSLIP_SETTLED',
];
export const CASHOUT_OFFER_DISABLED_ERRORS = ['CASHOUT_OFFER_EXPIRED'];
export const CASHOUT_OFFER_ERRORS = [...CASHOUT_OFFER_NOT_CASHOUTABLE, ...CASHOUT_OFFER_LOCKED_ERRORS, ...CASHOUT_OFFER_DISABLED_ERRORS];
export const CASHOUT_CONFIRM_OFFER_RUN_POLLING_ERRORS = [
    'CASHOUT_IS_DISABLED',
    'CASHOUT_OFFLINE_PRICES',
    'CASHOUT_WRONG_CASHOUT_VALUE',
    8055,
    8054,
    8053,
];
export const CASHOUT_OFFER_DEAD_ERRORS = [8056, ...CASHOUT_OFFER_NOT_CASHOUTABLE];
export const TICKET_AUTH_ERRORS = ['POOL_USER_NOT_FOUND', 'POOL_TOKEN_IS_NULL', 5021, 5023];
export const TICKET_FUNDS_ERRORS = ['POOL_INSUFFICIENT_FUNDS', 5043];

export const AUTO_CASHOUT_OFFER_UNAVAILABLE_ERRORS = ['AUTO_CASHOUT_UNAVAILABLE_IN_CURRENT_LOCATION'];

export const AUTO_CASHOUT_OFFER_DEAD_ERRORS = [
    'AUTO_CASHOUT_ALL_ITEMS_SETTLED',
    'AUTO_CASHOUT_NOT_AVAILABLE',
    'AUTO_CASHOUT_BETSLIP_LOST_CANT_CASHOUT',
    'AUTO_CASHOUT_OFFER_LESS_THAN_MINIMUM_OFFER',
    'AUTO_CASHOUT_IS_DISABLED',
];

export const AUTO_CASHOUT_OFFER_OUT_OF_RANGE_ERROR = 'AUTO_CASHOUT_ACCEPTABLE_AMOUNT_IS_OUT_OF_RANGE';

export const AUTO_CASHOUT_OFFER_LOCKED_ERRORS = ['AUTO_CASHOUT_OFFLINE_PRICES', 'AUTO_CASHOUT_UNKNOWN_SERVER_ERROR'];

export const AUTO_CASHOUT_OFFER_INVALID_REQUEST_ERRORS = ['AUTO_CASHOUT_INVALID_BETSLIP_ID', 'AUTO_CASHOUT_INVALID_REQUEST'];

export const AUTO_CASHOUT_OFFER_ERRORS = [
    ...AUTO_CASHOUT_OFFER_UNAVAILABLE_ERRORS,
    ...AUTO_CASHOUT_OFFER_DEAD_ERRORS,
    ...AUTO_CASHOUT_OFFER_LOCKED_ERRORS,
    ...AUTO_CASHOUT_OFFER_INVALID_REQUEST_ERRORS,
    AUTO_CASHOUT_OFFER_OUT_OF_RANGE_ERROR,
];
