<template>
    <div class="left-menu-header">
        <router-link
            class="logo-link"
            data-test-id="left-menu-logo"
            :to="{ name: routeName.HOMEPAGE }"
            @click.native="$emit('hideLeftMenu')"
        >
            <SvgIcon icon-id="logo" icon-fallback="logo" class="logo" vertical-align="top" />
        </router-link>
        <span class="close" data-test-id="left-menu-close" @click="$emit('hideLeftMenu')">
            <SvgIcon icon-id="icon-cross-squared" />
        </span>
    </div>
</template>

<script>
import { routeName } from '@/router/const-name';

export default {
    name: 'LeftMenuHeader',
    computed: {
        routeName() {
            return routeName;
        },
    },
};
</script>

<style scoped lang="scss">
.left-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $left-menu-header-height;
    padding: 8px 4px;
    background: $dark-green;
    position: sticky;
    top: 0;
    z-index: 2;

    .logo-link {
        display: flex;

        .logo {
            width: $header-brand-logo-medium-width;
            height: $header-brand-logo-medium-height;
        }
    }

    .close {
        cursor: pointer;
        svg {
            fill: $white-text;
            height: 18px;
            width: 18px;
        }
    }
}
</style>
