import { Userbalance } from '@/modules/platform/components';

export function createNode({ h, mark, text, type = 'text' }) {
    const tag = getTagByType(type);
    switch (mark.type) {
        case 'link':
            return h('a', { attrs: mark.attrs || {}, style: { 'text-decoration': `underline` } }, text);
        case 'bold':
            return h('strong', text);
        case 'italic':
            return h('i', text);
        case 'strike':
            return h('strike', text);
        case 'textStyle':
            return h('span', { style: mark.attrs || {} }, text);
        case 'userBalance':
            const getRender = (domNode) => {
                if (domNode) {
                    const { tag, children, data } = domNode;
                    const { style } = data || {};
                    if (children) {
                        return h(tag, { style }, [getRender(children[0])]);
                    }
                    return h(Userbalance);
                }
                return h(Userbalance);
            };

            if (text) {
                return getRender(text[0]);
            }

            return h(Userbalance);
        default:
            return h(tag, text);
    }
}

export function createNodeWithMarks(h, item) {
    const initialNode = [createNode({ h, mark: item.marks[0], text: item.text, type: item.type })];
    const marksArray = item.marks.slice(1);

    return marksArray.reduce(
        (accumulator, mark) => [
            createNode({
                h,
                mark,
                text: accumulator,
                type: item.type,
            }),
        ],
        initialNode
    );
}

export function getTagByType(type) {
    switch (type) {
        case 'text':
            return 'span';
        case 'br':
        case 'hardBreak':
            return 'br';
        default:
            return 'span';
    }
}

export function createNodes(h, content) {
    let children = [];
    (content || []).forEach((item) => {
        const tag = getTagByType(item.type);
        if (!item.marks) {
            children.push(h(tag, item.text));
        } else {
            const node = createNodeWithMarks(h, item);
            children.push(node[0]);
        }
    });

    return children;
}
