import { widgetType } from '@/modules/sport/const/widget-const';
import { getObjectField } from '@/modules/core/utils/helper';

const defaultConfig = {
    widgetPriority: {
        upcoming: widgetType.SPORT_RADAR,
        live: widgetType.GENIUSSPORTS,
    },
    convertIdToNumber: true,
    defaultValue: {},
    useFallbackValue: true,
};

function formatWidget(widget, convertIdToNumber) {
    return {
        ...widget,
        id: convertIdToNumber ? Number(widget.id) : widget.id,
    };
}

export function getEventWidget(event, config = defaultConfig) {
    const { wantedWidgetType, widgetPriority, convertIdToNumber, defaultValue, useFallbackValue } = { ...defaultConfig, ...config };

    if (!event || !Array.isArray(event.widgets)) {
        return defaultValue;
    }

    const fallbackWidgetType = getObjectField(event, 'additionalInfo.live', false) ? widgetPriority.live : widgetPriority.upcoming;
    const desiredWidgetType = wantedWidgetType || (useFallbackValue && fallbackWidgetType);
    const foundWidget = event.widgets.find(({ type }) => type === desiredWidgetType);

    if (foundWidget) {
        return formatWidget(foundWidget, convertIdToNumber);
    }

    if (!useFallbackValue) return defaultValue;

    const fallbackWidget =
        event.widgets.find(({ type }) => type === widgetType.SPORT_RADAR) ||
        event.widgets.find(({ type }) => type === widgetType.GENIUSSPORTS);

    if (fallbackWidget) {
        return formatWidget(fallbackWidget, convertIdToNumber);
    }

    return defaultValue;
}
