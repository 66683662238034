<template>
    <ErrorPage
        v-bind="image"
        :title="errorMessage.title"
        :section-list="[errorMessage.description]"
        :button="{ text: buttonText, emit: true }"
        @button:click="returnToHome()"
    />
</template>

<script>
import { routeName } from '@/router/const-name';
import { ERROR_IMAGES } from '@/components/content/content-const';
import { mapGetters } from 'vuex';
import { getter as generalGetter, mutation } from '@/store/const';
import { SPORT_CATEGORY } from '@/modules/sport/const/events-const';
import { getter as sportGetter } from '@/modules/sport/store/const';
import ErrorPage from '@/components/Pages/ErrorPage.vue';

export default {
    name: 'NoEvents',
    components: { ErrorPage },
    computed: {
        ...mapGetters({
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            eventsCountPerType: sportGetter.EVENTS_COUNT_PER_TYPE,
        }),
        isLive() {
            return this.$route.name === routeName.LIVE;
        },
        hasLiveEvents() {
            return this.eventsCountPerType.live[this.currentCategoryId] > 0;
        },
        image() {
            if (!this.isLive) {
                return {
                    imgSrc: ERROR_IMAGES.errorNoMatchesMarket,
                };
            }
            if (this.hasLiveEvents) {
                return {
                    icon: 'icon-live',
                    iconClass: 'icon-gray',
                };
            }
            return {
                icon: this.$t(`project.sports.${this.currentCategoryId}.noEvent.icon`),
            };
        },
        activeCategoryId() {
            return [SPORT_CATEGORY.FOOTBALL, SPORT_CATEGORY.BASKETBALL, SPORT_CATEGORY.TENNIS].find(
                (categoryId) => this.eventsCountPerType.live[categoryId] > 0
            );
        },
        errorMessage() {
            if (!this.isLive) {
                return {
                    title: this.$t('ui.eventPage.error.title.noMatchesMarkets'),
                    description: this.$t('ui.eventPage.error.description.noActiveMarkets'),
                };
            }
            if (this.hasLiveEvents) {
                return {
                    title: this.$t('ui.eventPage.error.title.liveEventsEnded'),
                    description: this.$t('ui.eventPage.error.description.liveEventsEnded'),
                };
            }
            return {
                title: this.$t(`project.sports.${this.currentCategoryId}.noEvent.title`),
                description: this.activeCategoryId
                    ? this.$t(`project.sports.${this.activeCategoryId}.noEvent.description`)
                    : this.$t(`project.sports.${this.currentCategoryId}.liveEventsEnded`),
            };
        },
        buttonText() {
            if (!this.isLive) {
                return this.$t('ui.common.returnToHome');
            }
            if (this.hasLiveEvents) {
                return;
            }
            if (this.activeCategoryId) {
                return this.$t(`project.sports.${this.activeCategoryId}.noEvent.buttonText`);
            }
            return this.$t('ui.boosted.browseUpcoming');
        },
    },
    methods: {
        returnToHome() {
            if (!this.isLive) {
                this.$router.push({ name: routeName.HOMEPAGE });
                return;
            }
            if (this.activeCategoryId) {
                this.$router.push({ name: this.$route.name, params: { categoryId: this.activeCategoryId } }).then(() => {
                    this.$store.commit(mutation.SET_CURRENT_CATEGORY, this.activeCategoryId);
                });
            } else {
                this.$router.push({ name: routeName.UPCOMING });
            }
        },
    },
};
</script>
