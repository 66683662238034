<template>
    <Modal :class="MODAL_NAME" :name="MODAL_NAME" :options="{ overlay: true }" is-highest fixed @close="closeModal" @opened="openedModal">
        <template #header-text>{{ $t('ui.cutPlus.cutPlus') }}</template>
        <template #header>
            <a class="modal-header-close" @click="closeModal">
                <SvgIcon vertical-align="top" class="icon icon-size-small" icon-id="icon-close" />
            </a>
        </template>
        <template #default>
            <div v-if="pageContent" class="modal-body-content">
                <DynamicComponent v-for="(item, index) in pageContent" :key="MODAL_NAME + index" :data="item" :path="'content'" />
            </div>
            <Spinner v-if="pageIsLoading" class="align-center transparent" :visible="true" />
        </template>
        <template #footer>
            <span class="button button-submit button-full" @click="addMoreLegs">
                {{ $t('ui.cutPlus.addMoreLegs') }}
            </span>
        </template>
    </Modal>
</template>

<script>
import { action as platformAction } from '@/modules/platform/store/const';
import { getter as betslipGetter } from '@/modules/sport/store/modules/betslip/const';
import { action } from '@/store/const';
import { mapGetters } from 'vuex';
import { routePath } from '@/router/const-path';
import { getter as coreGetter } from '@/modules/core/store/const';

const MODAL_NAME = 'cut-plus-modal';

export default {
    name: 'CutPlusModal',
    data() {
        return {
            MODAL_NAME,
            pageContent: null,
        };
    },
    computed: {
        ...mapGetters({
            isVirtualBetslip: betslipGetter.IS_VIRTUAL_BETSLIP,
            isLoading: coreGetter.IS_LOADING,
        }),
        pageIsLoading() {
            return this.isLoading(platformAction.GET_PAGE);
        },
    },
    methods: {
        closeModal() {
            this.$modal.hide('cut-plus-modal');
        },
        openedModal() {
            this.$store.dispatch(platformAction.GET_PAGE, 'cut-plus').then((data) => {
                this.pageContent = data.content;
            });
        },
        addMoreLegs() {
            this.$modal.hide('cut-plus-modal');
            this.$store.dispatch(action.SET_BETSLIP_STATE, false);
            this.$router.push(this.isVirtualBetslip ? routePath.VIRTUAL_SPORTS : routePath.UPCOMING);
        },
    },
};
</script>

<style scoped lang="scss">
.cut-plus-modal {
    ::v-deep .modal-container {
        border-radius: 4px 4px 0 0;
        border: 1px solid $cut-plus-modal-border;

        .modal-header {
            height: 48px;
            background: $cut-plus-modal-header;

            .modal-header-text {
                display: flex;
                padding: 16px 12px;
                @extend %body-normal-font-500;
            }
            .modal-header-close {
                padding: 16px 12px;
                display: flex;

                svg {
                    height: 12px;
                    width: 12px;
                }
            }
        }

        .modal-body {
            height: 70vh;
        }
    }
}
</style>
