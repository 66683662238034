<template>
    <Modal class="self-exclusion-modal" :name="modalName" fixed :options="{ overlay: true }" @close="close">
        <h3 class="self-exclusion-title">{{ $t('ui.accountVerification.selfExclusionModal.title') }}</h3>

        <p class="self-exclusion-message">
            <renderer :input="$t(`ui.accountVerification.selfExclusionModal.${pinOrPassword}.message`)" />
        </p>
    </Modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { modalNames } from '@/modules/platform/const/verification';
import { getter as translationsGetter } from '@/store/modules/translations/const';

export default {
    name: 'SelfExclusionModal',
    data() {
        return {
            modalName: modalNames.SELF_EXCLUSION_MODAL,
        };
    },
    computed: {
        ...mapGetters({
            pinOrPassword: translationsGetter.PIN_OR_PASSWORD,
        }),
    },
    methods: {
        close() {
            this.$modal.hide(this.modalName);
        },
    },
};
</script>

<style scoped lang="scss">
.self-exclusion-modal {
    text-align: center;
    ::v-deep {
        .modal-body {
            padding: 0;
        }

        .modal-container {
            padding: 16px;
        }
    }
}

.self-exclusion-title {
    @extend %h4-font-700;
    margin: 0;
    margin-bottom: 4px;
}

.self-exclusion-message {
    @extend %body-normal-font-400;
    margin-bottom: 12px;
}
</style>
