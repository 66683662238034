<template>
    <div class="back-to-all-button" @click="trackClick">
        <span class="button-wrapper">
            <SvgIcon class="arrow-left icon-size-very-small" icon-id="arrow_left" />
            <span>{{ $t('ui.eventFilters.backToAll', { sportName }) }}</span>
        </span>
        <span class="counter">{{ totalEventCount }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getter as generalGetter } from '@/store/const';
import { SPORT_CATEGORY_MAP } from '@/modules/sport';
import { getObjectField } from '@/modules/core/utils/helper';

export default {
    name: 'BackToAllButton',
    props: {
        totalEventCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapGetters({
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
        }),
        sportName() {
            return this.$t(`project.sports.${this.currentCategoryId}.text`).toLowerCase();
        }
    },
    methods: {
        trackClick() {
            const sportType = getObjectField(SPORT_CATEGORY_MAP.find(
                (sport) => sport.key === Number(this.currentCategoryId)
            ), 'name', this.currentCategoryId);
            this.$gtm.query({
                event: `back_to_all_${sportType}`,
                click_text: 'button',
            });
        }
    }
};
</script>
<style scoped lang="scss">
.back-to-all-button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-bottom: 1px solid $light-grey;
    @extend %body-normal-font-400;

    .button-wrapper {
        display: flex;
        gap: 8px;
        align-items: center;

        .arrow-left {
            margin: 4px;
        }
    }
}
</style>
