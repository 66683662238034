export const getter = {
    GET_FAVORITE_GAMES_IDS: 'casino/getFavoriteGamesIds', // used in new
    GET_GAMES: 'casino/getGames', // used in new
    GET_GAMES_COUNT: 'casino/getGamesCount', // used in new
    GET_GAME_CATEGORIES: 'casino/getGameCategory', // used in new
    GET_FAVORITE_GAMES: 'casino/getFavoriteGames', // used in old
    GET_GAME_CATEGORY_BY_NAMES: 'casino/getGameCategoryByNames', // used in old
    GET_GAME_COLLECTION: 'casino/getGameCollection', // used in old
    GET_GAME_COLLECTION_BY_CATEGORIES: 'casino/getGameCollectionByCategories', // used in old
    GET_CASINO_RECENT_SEARCHES: 'casino/getCasinoRecentSearches', // used in new
};
export const action = {
    ADD_FAVORITE_GAME: 'casino/addFavoriteGame', // used in both
    GET_CASINO_GAMES: 'casino/getCasinoGames', // used in old
    GET_CASINO_GAMES_NEW: 'casino/getCasinoGamesNew', // used in new
    OPEN_CASINO_GAME: 'casino/openCasinoGame', // used in both
    ADD_TO_CASINO_RECENT_SEARCH: 'casino/addToCasinoRecentSearch', // used in new
    REMOVE_FROM_CASINO_RECENT_SEARCH: 'casino/removeFromCasinoRecentSearch', // used in new
    PUT_CASINO_RECENT_SEARCH_IN_DERIVED_DATA: 'casino/putCasinoRecentSearchInDerivedData', // used in new
    SAVE_CASINO_RECENT_SEARCH: 'casino/saveCasinoRecentSearch', // used in new
};
export const mutation = {
    SET_CASINO_LAYOUT: 'casino/setCasinoLayout', // used in old
    SET_GAMES_COLLECTION: 'casino/setGamesCollection', // used in old
    SET_GAMES_SORT_BY: 'casino/setGamesSortBy', // used in both
    SET_FAVOURITE_GAMES_SORT_BY: 'casino/setFavouriteGamesSortBy', // used in new
    SET_GAME_CASINO_LOAD_ERROR: 'casino/setGameCasinoLoadError', // used in old
    SET_GAMES: 'casino/setGames', // used in new
    SET_GAME_LAUNCH_ERROR: 'casino/setGameLaunchError', // used in both
    SET_CASINO_RECENT_SEARCHES: 'casino/setCasinoRecentSearches', // used in new
};
