import { widgetType } from '@/modules/sport';
import { getEventWidget } from '@/modules/sport/utils/event/get-event-widget';

export const isEventStatsPerformStreamable = (event) => {
    return [widgetType.STAT_PERFORM_LIVE_STREAM, widgetType.STAT_PERFORM_FIXTURE_DATA].every(
        (widgetType) =>
            !!getEventWidget(event, {
                wantedWidgetType: widgetType,
                defaultValue: undefined,
                convertIdToNumber: false,
                useFallbackValue: false,
            })
    );
};
