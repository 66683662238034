<template>
    <div class="empty-betslip-open">
        <SvgIcon class="empty-betslip-icon" icon-id="icon-betslip-illustration" />
        <span class="caption">{{ $t(tabConfig.displayTextKey) }}</span>
        <div class="empty-betslip-button">
            <router-link
                :to="tabConfig.redirectLink"
                data-test-id="emptyBetslipBtn"
                class="empty-betslip-button-link"
                @click="handleBetslipClick"
            >
                {{ $t(tabConfig.buttonTextKey) }}
            </router-link>
        </div>
    </div>
</template>

<script>
import { myBetsType } from '@/modules/sport';
import { routeName as routeNameMap } from '@/router/const-name';

const tabSettings = {
    [myBetsType.VIRTUAL]: {
        displayTextKey: 'ui.myBets.noBets.virtual',
        redirectLink: { name: routeNameMap.VIRTUAL_SPORTS },
        buttonTextKey: 'ui.common.betOnVirtuals',
        gtmEvent: { event: 'empty_betslip_virtual_click' },
    },
    [myBetsType.JACKPOT]: {
        displayTextKey: 'ui.myBets.emptyData.jackpots',
        redirectLink: { name: routeNameMap.JACKPOT_PAGE },
        buttonTextKey: 'ui.common.playJackpots',
        gtmEvent: { event: 'empty_betslip_jackpot_click' },
    },
    [myBetsType.SETTLED]: {
        displayTextKey: 'ui.myBets.emptyData.settledBet',
        redirectLink: { name: routeNameMap.UPCOMING },
        buttonTextKey: 'ui.common.browseMatches',
        gtmEvent: { event: 'browse_matches_click', from: 'SETTLED' },
    },
    [myBetsType.PENDING]: {
        displayTextKey: 'ui.myBets.emptyData.openBet',
        redirectLink: { name: routeNameMap.UPCOMING },
        buttonTextKey: 'ui.common.browseMatches',
        gtmEvent: { event: 'browse_matches_click', from: 'OPEN' },
    },
    default: {
        displayTextKey: 'ui.myBets.emptyData.defaultMessage',
        redirectLink: { name: routeNameMap.HOMEPAGE },
        buttonTextKey: 'ui.myBets.emptyData.defaultMessage',
        gtmEvent: { event: 'default_event_click' },
    },
};

export default {
    name: 'EmptyBets',
    props: {
        tabName: {
            type: String,
            default: myBetsType.VIRTUAL,
        },
    },
    computed: {
        tabConfig() {
            return tabSettings[this.tabName] || tabSettings.default;
        },
    },
    methods: {
        handleBetslipClick() {
            if (this.tabConfig.gtmEvent) {
                this.$gtm.query(this.tabConfig.gtmEvent);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.empty-betslip-open {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 40px;

    .empty-betslip-icon {
        width: 100px;
        height: 80px;
        margin-bottom: 4px;
    }

    .empty-betslip-button {
        display: flex;
        align-items: center;
        justify-content: center;

        &-link {
            @extend %body-normal-font-700;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            user-select: none;
            cursor: pointer;
            width: 100%;
            padding: 7px 12px;
            justify-content: center;
            color: $betpawa-black;
            gap: 8px;
            align-self: stretch;
            background-color: $greenish;
        }
    }
}
</style>
