<template>
    <div class="otp-input-wrapper" :class="{ complete: isFullyPopulated, error: errorMessage && !disabled }">
        <div class="otp-input-group">
            <div v-for="(_, index) in otp" :key="`otp-input-${index}`">
                <input
                    ref="inputs"
                    :value="otp[index]"
                    :data-test-id="`otpInput${index}`"
                    type="text"
                    maxlength="1"
                    class="otp-input"
                    inputmode="numeric"
                    :disabled="disabled"
                    @input="handleInput(index, $event)"
                    @keydown="handleArrowNavigation(index, $event)"
                    @keydown.backspace="handleBackspace(index)"
                    @paste.prevent="handlePaste"
                    @focus="({ target }) => target.select()"
                />
            </div>
        </div>

        <renderer v-if="errorMessage" :input="errorMessage" class="notify error otp-input-error" />
    </div>
</template>

<script>
import { numeric } from 'vuelidate/lib/validators';
import { VALIDATORS } from '@/modules/core';

const ARROW_KEYS = { ARROW_LEFT: 'ArrowLeft', ARROW_RIGHT: 'ArrowRight', ARROW_UP: 'ArrowUp', ARROW_DOWN: 'ArrowDown' };

export default {
    name: 'OtpInput',
    props: {
        otpLength: {
            type: Number,
            default: 4,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        autoRequest: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            otp: Array(this.otpLength).fill(''),
        };
    },
    validations() {
        return {
            code: {
                code: VALIDATORS.verificationCode,
                numeric,
            },
        };
    },
    computed: {
        isFullyPopulated() {
            if (this.disabled || this.errorMessage) return false;

            return this.otp.every(({ length }) => length);
        },
    },
    methods: {
        /**
         * Handles user input in the OTP field.
         *
         * - If there is an error message, notifies the parent component by emitting the "onInputReset" event.
         * - Clears the current OTP input if the value is not a single digit.
         * - Moves focus to the next input field if applicable.
         * - If the OTP is complete and hasn't been submitted yet, triggers the submission.
         */
        handleInput(index, event) {
            if (this.errorMessage) this.emitInputReset();

            const value = event.target.value;

            if (!/^\d$/.test(value)) {
                this.setValue(index, '');
                return;
            }
            this.setValue(index, value);

            if (index < this.otpLength - 1) {
                this.$refs.inputs[index + 1].focus();
            }

            if (this.isFullyPopulated && this.autoRequest) {
                this.emitAutoRequest();
            }
        },
        /**
         * Handles paste event for OTP input.
         *
         * - Notifies the parent component if there is an error message by emitting the "onInputReset" event.
         * - Retrieves and trims the pasted text to match the OTP length.
         * - Prevents pasting if the data contains non-digit characters.
         * - Assigns each digit from the pasted data to the corresponding position in the OTP array.
         * - Moves focus to the next empty input or the last input field if the OTP is fully pasted.
         * - Emits an "input" event with the updated OTP value.
         * - If the OTP is fully pasted and hasn't been submitted, triggers the submission.
         */
        handlePaste(event) {
            if (this.errorMessage) this.emitInputReset();

            const pasteData = event.clipboardData.getData('text').slice(0, this.otpLength);

            if (!/^\d+$/.test(pasteData)) return;

            for (let i = 0; i < this.otpLength; i++) {
                this.$set(this.otp, i, pasteData[i] || '');
            }

            if (pasteData.length < this.otpLength) {
                this.$refs.inputs[pasteData.length].focus();
            } else {
                this.$refs.inputs[this.otpLength - 1].focus();
            }

            this.$emit('input', this.otp.join(''));

            if (pasteData.length === this.otpLength && this.autoRequest) {
                this.emitAutoRequest();
            }
        },
        handleBackspace(index) {
            if (!this.otp[index] && index > 0) {
                this.$refs.inputs[index - 1].focus();
            }
        },
        handleArrowNavigation(index, event) {
            if (Object.values(ARROW_KEYS).includes(event.key)) {
                event.preventDefault();

                if (event.key === ARROW_KEYS.ARROW_LEFT && index > 0) {
                    this.$refs.inputs[index - 1].focus();
                }

                if (event.key === ARROW_KEYS.ARROW_RIGHT && index < this.otpLength - 1) {
                    this.$refs.inputs[index + 1].focus();
                }
            }
        },
        setValue(index, value) {
            this.$set(this.otp, index, value);
            this.$emit('input', this.otp.join(''));
        },
        resetOtp() {
            for (let i = 0; i < this.otpLength; i++) {
                this.$set(this.otp, i, '');
            }

            this.$emit('input', this.otp.join(''));
        },
        emitInputReset() {
            this.$emit('input-reset');
        },
        emitAutoRequest() {
            this.$emit('auto-request', this.otp.join(''));
        },
    },
};
</script>
<style lang="scss" scoped>
.otp-input-wrapper {
    &.complete {
        input:not(:focus) {
            border-color: $green;
        }
    }
    &.error {
        input {
            border-color: $dark-red;
        }
    }
}

.otp-input-group {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.otp-input {
    @extend %h1-font-700;

    width: 42px;
    height: 32px;
    text-align: center;
    border: none;
    border-bottom: 1px solid $dark-grey-2;
    outline: none;

    &:focus {
        border-color: #22bfdb;
    }
    &:disabled {
        background-color: transparent;
        color: $dark-grey-2;
        border-color: $dark-grey;
    }
}

.otp-input-error {
    display: block;
    margin-top: 12px;
}
</style>
