import { jackpotStatus, EventType } from '@/modules/sport';

export function processSportsResponse(data, livePopularCount = Infinity) {
    let liveEvents = {};
    let events = {};

    let result = {
        events: [],
        livePopular: [],
        boosted: [],
        eventsByCategoryId: {},
        priority: null,
        resultsName: null,
        regionId: null,
    };

    const allLiveEvents = data
        .filter((item) => item.request.query.eventType === EventType.LIVE && item.responses.length)
        .map((item) => item.responses)
        .flat();
    const sortedAllLiveEvents = Array.from(allLiveEvents).sort((a, b) => Number(a.category.id) - Number(b.category.id));
    const livePopular = sortByScore(sortedAllLiveEvents).slice(0, livePopularCount);

    data.forEach((item) => {
        const { boosted } = item.request.query;
        const categoryId = Number(item.request.query.categories[0]);
        if (item.request.query.eventType === EventType.UPCOMING && boosted) {
            result.boosted = item.responses;
        }
        if (item.request.query.eventType === EventType.UPCOMING && !boosted) {
            events = { ...events, ...{ [categoryId]: sortMarkets(item.responses) } };
            result.events = [];
            result.eventsByCategoryId = events;
        }
        if (item.request.query.eventType === EventType.LIVE) {
            const livePopularIds = livePopular.filter((event) => Number(event.category.id) === categoryId).map((event) => event.id);
            liveEvents[categoryId] = sortByDate([...item.responses].filter((event) => !livePopularIds.includes(event.id)));
            result.liveEvents = liveEvents;
        }
    });

    result.livePopular = sortByDate(livePopular);
    return result;
}

export const processRawEvent = (newVal, oldVal) => {
    const { markets = [] } = oldVal;
    const { eventId, marketId, data } = newVal;

    if (!data?.markets?.length) {
        return {
            eventId,
            marketId,
            event: data,
            markets: [],
        };
    }

    const tempMarkets = [...data.markets].map((market) => ({
        ...market,
        marketType: { ...market.marketType, id: Number(market.marketType.id) },
    }));
    data.markets = [...tempMarkets];

    const uniqueMarket = (list, market) => (list.includes(market.marketType.id) && list) || [...list, market.marketType.id];
    const newMarketIds = data.markets.reduce(uniqueMarket, []);
    const newEvent = { ...data, markets: newMarketIds };
    const newMarkets = [...markets.filter((market) => !newMarketIds.includes(market.marketType.id)), ...data.markets].sort(
        (a, b) => newMarketIds.indexOf(a.marketType.id) - newMarketIds.indexOf(b.marketType.id)
    );

    return {
        eventId,
        marketId,
        event: newEvent,
        markets: newMarkets,
    };
};

export const groupMarkets = (list) => {
    if (!list?.length) return [];

    const markets = [...list];
    const groupedMarkets = [];

    markets.forEach((market, index) => {
        const lastMarketGroup = groupedMarkets[groupedMarkets.length - 1];
        const lastPriceGroup = lastMarketGroup && lastMarketGroup[lastMarketGroup.length - 1];
        const lastMarket = lastPriceGroup && lastPriceGroup[lastPriceGroup.length - 1];

        if (!lastPriceGroup) {
            groupedMarkets.push([[market]]);
        } else if (market.marketType.id === lastMarket.marketType.id) {
            lastPriceGroup.push(market);
            if (!markets[index + 1]) {
                lastPriceGroup.sort((a, b) => a.marketType.priority - b.marketType.priority || a.handicap - b.handicap);
            }
        } else {
            lastPriceGroup.sort((a, b) => a.marketType.priority - b.marketType.priority || a.handicap - b.handicap);
            groupedMarkets.push([[market]]);
        }
    });

    return groupedMarkets;
};

export const sortByDate = (events) => {
    return events.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
};

export const sortByScore = (events) => {
    return events.sort((a, b) => b.score - a.score);
};

// TODO: need to remove after endpoint integration
export const sortMarkets = (events) => {
    for (const event of events) {
        event.markets.sort((a, b) => a.marketType.priority - b.marketType.priority || a.handicap - b.handicap);
    }
    return events;
};

/**
 * get an array of integers from a string
 *
 * Example:
 *
 *    var arr = convertMarketParamString('1, 2,3;4; 0; t,;');
 *    console.log('arr==' + arr) // arr==['1', '2', '3', '4', '0'];
 *
 * @param {String} string - string with numbers separated by "," or ";"
 * @param {Boolean} [convertToNumbers=false] - convert array items to numbers
 * @returns {(String|Number)[]} array of integers (represented by string or digits, depends on the convertToNumbers parameter)
 */
export const convertStringToArrayOfIntegers = (string, convertToNumbers = false) => {
    return string
        .split(/,|;/)
        .map((item) => {
            const newItem = item.trim();
            return convertToNumbers && newItem ? Number(newItem) : newItem;
        })
        .filter((item) => {
            if (typeof item === 'number') {
                return Number.isInteger(item);
            }
            return item && Number.isInteger(Number(item));
        });
};

export const filterHiddenJackpots = (list, id) =>
    list.filter(({ presentation }) => {
        const [, params = ''] = (presentation || '').split(' ');
        const options = params && Object.fromEntries(new URLSearchParams(params));
        return !(options.hide && options.hide.includes(id));
    });

export const processJackPotPool = (pool) => {
    const { settlementInfo, confirmed } = pool || {};
    pool.isPaid = (settlementInfo?.settled && confirmed) || false;
    return pool;
};

/**
 * Adding additional business logic for JP ticket.
 * NOTE: This is necessary because the new Pool API endpoints v1
 * do not provide some of the information we need in different places
 * @param {Array} list
 * @param {String} id
 */
export const processJackpotList = (list, id) => {
    const processedList =
        list?.map((it) => ({
            ...it?.pool,
            ...it?.marketing,
            ...it?.user,
            priorityOrder: Number.isInteger(it?.marketing?.priorityOrder) ? it.marketing.priorityOrder : null,
        })) || [];
    const filteredList = filterHiddenJackpots(processedList, id);
    return filteredList.map((pool) => processJackPotPool(pool));
};

/**
 * Adding additional business logic for JP ticket.
 * NOTE: This is necessary because the new Pool API endpoints v1
 * do not provide some of the information we need in different places
 * @param {Object} ticket
 */
export const processJackPotTicket = (ticket) => {
    const getRowPick = (row, outcomes) => {
        return outcomes ? outcomes.map((index) => row.outcomes[index - 1]?.text).join(' / ') : '';
    };

    if (ticket.pool) {
        ticket.pool = processJackPotPool(ticket.pool);
        ticket.pool.isPaid = ticket.pool.isPaid || !!ticket?.payoutDetails?.payout;
        ticket.isWinner = ticket.pool.isPaid && ticket?.status.toUpperCase() === jackpotStatus.WON;
        ticket.isOngoing = !ticket.pool.isPaid && [jackpotStatus.PENDING, jackpotStatus.ONGOING].includes(ticket?.status.toUpperCase());

        if (ticket.pool?.rows) {
            ticket.pool.rows = ticket.pool.rows.map((row, index) => {
                const ticketRow = ticket.rows.find((r) => r.position === row.position);
                return {
                    ...row,
                    pick: getRowPick(row, ticketRow?.outcomes),
                    hasWon: ticketRow?.outcomes?.includes(row.result?.positionWon),
                };
            });
            ticket.eventRows = ticket.pool.rows.filter((row) => row.event);
            ticket.jackpotRows = ticket.pool.rows.filter((row) => !row.event);
        }
        if (ticket.pool?.tiebreakers) {
            ticket.pool.tiebreakers.tiebreakers = ticket.pool.tiebreakers.tiebreakers?.map((tiebreaker, index) => {
                const answer = ticket?.tiebreaker?.answers.find((tie) => tie.id === tiebreaker.id)?.answer;
                return {
                    ...tiebreaker,
                    answer,
                    isAnswerCorrect: answer === tiebreaker?.correctAnswer,
                };
            });
        }
    }
    if (Array.isArray(ticket.rows)) {
        ticket.totalTickets = ticket.rows.reduce((acc, value) => acc * value?.outcomes?.length, 1);
    }
    return ticket;
};

/**
 * Transform booking selection
 * @param {Array} selections
 */
export const processBookingSelection = (selections, minimumOddsForBonus) => {
    return selections.map((it) => {
        const {
            event: {
                id: eventId,
                name: eventName,
                startTime: eventStartTime,
                additionalInfo: { live },
            },
            market: {
                additionalInfo: { cashoutable, twoUp },
                marketType: { name: marketName, displayName },
                handicapType,
            },
            price: { name: selectionName, id: selectionId, typeId: marketTypeId, handicap: hcp, price, probability },
        } = it;

        return {
            eventId: Number(eventId),
            name: eventName,
            rawDate: eventStartTime,
            isLive: live,
            isVirtual: false,
            market: {
                name: marketName,
                displayName,
                handicapType,
                cashoutable,
                twoUp,
            },
            price: {
                Name: selectionName,
                Id: Number(selectionId),
                Type: marketTypeId,
                Price: price && Number(price).toFixed(2),
                PriceRaw: price,
                EligibleForBonus: price >= minimumOddsForBonus,
                Hcp: hcp,
                probability,
            },
        };
    });
};
