export const getter = {
    GET_SELECTED_LANGUAGE: 'translations/getSelectedLanguage',
    GET_SELECTED_LANGUAGE_OR_DEFAULT: 'translations/getSelectedLanguageOrDefault',
    LANGUAGE: 'translations/getLanguage',
    GET_NEXT_AVAILABLE_LANGUAGE: 'translations/getNextAvailableLanguage',
    LANGUAGE_LIST: 'translations/getLanguageList',
    GET_CONFIGURATION: 'translations/getConfiguration',
    PIN_OR_PASSWORD: 'translations/getPinOrPasswordTranslations',
    CASINO_NAME: 'translations/getCasinoName',
    TRANSLATIONS: 'translations/getTranslations',
};
export const action = {
    SWITCH_LANGUAGE: 'translations/switchLanguage',
    GET_CRITICAL_TRANSLATIONS: 'translations/getCriticalTranslations',
    GET_TRANSLATIONS: 'translations/getTranslations',
};
export const mutation = {
    SET_LOCALES: 'translations/setLocales',
    SET_LOCALE: 'translations/setLocale',
    SET_LANGUAGE: 'translations/setLanguage',
    SET_CLIENT_LANGUAGE: 'translations/setClientLanguage',
    SET_CONFIGURATION: 'translations/setConfiguration',
};
