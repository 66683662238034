const PHONE_NUMBER_VALIDATION_NAME = {
    PHONE_NUMBER_LENGTH: 'phoneNumberLength',
    PHONE_NUMBER_FORMAT: 'phoneNumberFormat',
};

const validationByJurisdiction = {
    BJ: {
        placeholder: '0112345678',
        validationActions: [],
    },
    DEFAULT: {
        placeholder: '',
        validationActions: [],
    },
};

export const getPhoneNumberValidation = (countryCode, { numberLength, isNewNumberFormat }) => {
    const validationList = [];
    const availableValidationActions = {
        [PHONE_NUMBER_VALIDATION_NAME.PHONE_NUMBER_LENGTH]: {
            validator: (v) => new RegExp(`^\\d{${numberLength}}$`).test(v.replace(/\s/g, '')),
            errorMessageKey: 'ui.common.phoneNumber.lengthError',
        },
        [PHONE_NUMBER_VALIDATION_NAME.PHONE_NUMBER_FORMAT]: {
            validator: (v) => v.replace(/\s/g, '').startsWith('01'),
            errorMessageKey: 'ui.common.form.error.beninPhoneNumberFormat',
        },
    };

    if (numberLength) {
        validationList.push(PHONE_NUMBER_VALIDATION_NAME.PHONE_NUMBER_LENGTH);
    }
    if (isNewNumberFormat) {
        validationList.push(PHONE_NUMBER_VALIDATION_NAME.PHONE_NUMBER_FORMAT);
    }

    const jurisdictionSpecificValidation = validationByJurisdiction[countryCode]
        ? validationByJurisdiction[countryCode]
        : validationByJurisdiction.DEFAULT;

    const mergedValidationList = [...jurisdictionSpecificValidation.validationActions, ...validationList];

    return {
        placeholder: jurisdictionSpecificValidation.placeholder,
        validations: mergedValidationList.map((key) => ({
            [key]: availableValidationActions[key],
        })),
    };
};
