<template>
    <div v-if="isAuthenticated" class="self-exclusion">
        <div class="self-exclusion-title">{{ $t('ui.selfExclusionPeriod.title') }}</div>
        <div class="self-exclusion-caption">{{ $t('ui.selfExclusionPeriod.caption', { duration: captionDurationText }) }}</div>
        <form class="self-exclusion-form" @submit.prevent>
            <InputField
                class="self-exclusion-form-item self-exclusion-form-period"
                type="number"
                name="period"
                :form-name="formName"
                :attrs="{
                    placeholder: periodPlaceholder,
                    inputMode: 'numeric',
                }"
                :value="form.period"
                :v="$v.form.period"
                :error-messages="errorMessages"
                :help-text="reactivationMessage"
                data-test-id="self-exclusion-form-period"
                @value="setPeriod"
            >
                <template #label>{{ $t('ui.selfExclusionPeriod.inputLabel') }}</template>
            </InputField>

            <Password
                class="self-exclusion-form-item"
                name="self-exclusion-password"
                autocomplete="new-password"
                :form-name="formName"
                :value="form.password"
                help-disabled
                :v="$v.form.password"
                data-test-id="self-exclusion-form-password"
                @value="setPassword"
            />
            <div v-if="!isProcessing && authError" class="notify error">
                <renderer :input="authError" />
            </div>
            <button
                class="self-exclusion-form-submit button button-full button-primary"
                :disabled="$v.$invalid || isProcessing"
                data-test-id="self-exclusion-open-confirmation-modal-button"
                @click.prevent="openModal"
            >
                {{ buttonText }}
            </button>

            <div class="form-hint">{{ $t('ui.selfExclusionPeriod.formNextStep') }}</div>

            <Modal
                is-highest
                :name="confirmationModalId"
                width="312px"
                :options="{ overlay: true, backDrop: true }"
                :fixed="true"
                @close="cancelSelfExclusion"
            >
                <template #default>
                    <h4 class="modal-title text-mid">{{ $t('ui.selfExclusionPeriod.confirmModalTitle') }}</h4>
                    <div class="text-mid">{{ reactivationMessage }}</div>
                </template>

                <template #footer>
                    <div class="modal-actions">
                        <button
                            class="button button-primary button-full modal-button"
                            :disabled="isProcessing"
                            data-test-id="self-exclusion-confirmation-modal-accept"
                            @click="exclude"
                        >
                            {{ acceptModalTitle }}
                        </button>
                        <button
                            class="button button-accent button-full modal-button"
                            data-test-id="self-exclusion-confirmation-modal-cancel"
                            @click="cancelSelfExclusion"
                        >
                            {{ $t('ui.common.cancel') }}
                        </button>
                    </div>
                </template>
            </Modal>
        </form>
    </div>
</template>
<script>
import InputField from '@/modules/core/components/molecules/InputField.vue';
import { durationUnitsType } from '@/modules/core/utils/duration';
import Password from '@/components/Password.vue';
import { integer, required, minLength, numeric } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter, action as authAction } from '@/modules/platform/store/modules/auth/const';
import { helper } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';

const PERIOD_UNITS = {
    [durationUnitsType.DAY]: {
        min: 1,
        max: 90,
    },
    [durationUnitsType.WEEK]: {
        min: 1,
        max: 12,
    },
    [durationUnitsType.MONTH]: {
        min: 1,
        max: 3,
    },
};
const unit = durationUnitsType.DAY;

export default {
    name: 'SelfExclusion',
    components: { Password, InputField },
    data() {
        return {
            formName: 'self-exclusion-form',
            form: {
                period: null,
                unit,
                password: null,
            },
            isExcluded: false,
            confirmationModalId: 'self-exclusion-confirmation-modal',
        };
    },
    computed: {
        ...mapGetters({
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            authError: authGetter.GET_AUTH_ERROR,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            dateOptions: platformGetter.GET_DATE_OPTIONS,
            isLoading: coreGetter.IS_LOADING,
        }),
        isProcessing() {
            return this.isLoading(authAction.SELF_EXCLUDE) || this.isExcluded;
        },
        unitsTextParams() {
            const { min, max } = PERIOD_UNITS[unit];
            return {
                min: {
                    [unit]: helper.renderUnitDurationText(min, unit, unit === durationUnitsType.DAY),
                },
                max: {
                    [unit]: helper.renderUnitDurationText(max, unit),
                },
            };
        },
        errorMessages() {
            return {
                minValue: this.$t('ui.selfExclusionPeriod.inputMinValidationText', this.unitsTextParams.min),
                maxValue: this.$t('ui.selfExclusionPeriod.inputMaxValidationText', this.unitsTextParams.max),
                integer: this.$t('ui.common.form.error.integer', {
                    down: Math.floor(this.form.period),
                    up: Math.floor(this.form.period) + 1,
                }),
            };
        },
        estimatedReactivationDate() {
            if (!this.$v.form.period.$invalid) {
                return helper.addDurationToDate({ date: new Date(), unit, value: Number(this.form.period) });
            }
            return '';
        },
        reactivationMessage() {
            if (this.estimatedReactivationDate && !this.$v.form.period.$invalid) {
                const { date, month, year } = helper.formatDateTime(this.estimatedReactivationDate, {
                    ...this.dateOptions,
                    isUtc: true,
                    toObject: true,
                });
                return this.$t('ui.selfExclusionPeriod.reactivationText', {
                    date: `${date}.${month}.${year}`,
                    period: helper.renderUnitDurationText(this.form.period, unit),
                });
            }
            return '';
        },
        captionDurationText() {
            return helper.renderUnitDurationText(PERIOD_UNITS[unit].max, unit);
        },
        buttonText() {
            if (this.isProcessing) {
                return this.$t('ui.common.loading');
            }
            return this.$t('ui.selfExclusionPeriod.ctaButtonText');
        },
        periodPlaceholder() {
            const { min, max } = PERIOD_UNITS[unit];
            return this.$t('ui.selfExclusionPeriod.inputPlaceholder', { min, max });
        },
        acceptModalTitle() {
            return this.$t('ui.selfExclusionPeriod.confirmModalAccept', { period: helper.renderUnitDurationText(this.form.period, unit) });
        },
    },
    validations() {
        const isPin = this.brandPreference.pin;
        const unitLimit = PERIOD_UNITS[unit];
        return {
            form: {
                period: {
                    integer,
                    minValue: (value) => value >= unitLimit.min,
                    maxValue: (value) => value <= unitLimit.max,
                },
                password: { required, minLength: minLength(4), ...(isPin && { numeric }) },
            },
        };
    },
    methods: {
        openModal() {
            this.$modal.show(this.confirmationModalId);
            this.$gtm.query({
                event: 'self_exclusion_begin',
                period: this.form.period,
                unit: this.form.unit,
            });
        },
        cancelSelfExclusion() {
            this.closeModal();
            this.$gtm.query({
                event: 'self_exclusion_cancel',
                period: this.form.period,
                unit: this.form.unit,
            });
        },
        closeModal() {
            this.$modal.hide(this.confirmationModalId);
        },
        exclude() {
            const selfExcludedUntil = new Date(this.estimatedReactivationDate).toISOString();

            this.$store
                .dispatch(authAction.SELF_EXCLUDE, {
                    password: this.form.password,
                    selfExcludedUntil,
                })
                .then((isExcluded) => {
                    this.isExcluded = isExcluded;
                    this.$gtm.query({
                        event: 'self_exclusion_confirm',
                        wrongPinOrPass: !!this.authError,
                        selfExcludedUntil,
                        period: this.form.period,
                        unit: this.form.unit,
                    });
                });

            this.closeModal();
        },
        setPassword(value) {
            this.$v.$touch();
            this.form.password = value;
        },
        setPeriod(value) {
            this.form.period = value;
        },
    },
};
</script>

<style lang="scss">
.self-exclusion {
    padding: 16px 12px;
    margin: 16px 0;
    border-radius: 4px;
    border: 1px solid $medium-grey;
    background: $white-bg;

    .input-field {
        margin-bottom: 0;
    }

    &-title {
        @extend %body-big-font-500;
        color: $main-text;
        margin-bottom: 4px;
    }

    &-caption {
        @extend %body-normal-text;
    }

    &-form {
        &-item {
            margin-top: 12px;
        }
        &-submit {
            margin-top: 8px;
        }
    }

    &-units {
        &-container {
            display: flex;
            justify-content: space-between;
        }

        &-error {
            @extend %form-validation-error;
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 0 0;
            border-radius: 4px;
            background: $light-grey;
            @extend %body-normal-text;
            text-align: center;
            padding: 7px;
            margin-right: 10px;
            border: 1px solid transparent;
            transition: all 200ms ease-in-out;
            text-transform: capitalize;

            &:last-of-type {
                margin-right: 0;
            }

            &.active {
                border: 1px solid $light-green;
                background: #f4fae8;
            }
        }
    }
}

.modal-title {
    @extend %h4-font-700;
    margin-bottom: 4px;
}
.modal-actions {
    flex-grow: 1;
}
.modal-button {
    margin-top: 8px;
}

.form-hint {
    @extend %small-details-font-400;
    color: $dark-grey-3;
    margin-top: 4px;
}
</style>
