// Global XOR key in hexadecimal form:
const GLOBAL_XOR_KEY_HEX = '9E3779B97F4A7C15';

/**
 * Decodes the obfuscated double value back to a normal JavaScript number.
 *
 * @param {number|null} obfuscatedValueDecimal - The obfuscated value.
 * @param {number} requestXorKeyDecimal   - The XOR key from the server.
 * @returns {number|null} - The decoded double as a JS number, or null if input is null.
 */
export function decodeObfuscatedValue(obfuscatedValueDecimal, requestXorKeyDecimal) {
    if (obfuscatedValueDecimal === null) {
        return null;
    }

    // 1) Convert decimal strings to 64-bit hexadecimal format
    const obfHex = decimalStringToHex64(obfuscatedValueDecimal.toString());
    const keyHex = decimalStringToHex64(requestXorKeyDecimal.toString());

    // 2) XOR with the global key:
    //    resultHex = obfHex ^ GLOBAL_XOR_KEY_HEX ^ keyHex
    //    (bitwise XOR, nibble by nibble)
    const resultHex = hexXor64(obfHex, GLOBAL_XOR_KEY_HEX, keyHex);

    // 3) Convert the resulting 64-bit hex back into a float64 (IEEE 754)
    return hex64ToFloat64(resultHex);
}

// -------------------------------------------------------------
// Helper functions for 64-bit operations using hex strings
// -------------------------------------------------------------

/**
 * Converts a decimal string (which may exceed 2^53 or be negative)
 * into a 16-digit hexadecimal string (64 bits).
 * We take it modulo 2^64, and handle sign.
 */
function decimalStringToHex64(decString) {
    decString = decString.trim();
    let negative = false;
    if (decString[0] === '-') {
        negative = true;
        decString = decString.slice(1);
    }

    // 2^64 as a decimal string
    const TWO_64_STR = '18446744073709551616';
    // Take mod 2^64
    let modVal = modDecimalStringBy64Bits(decString);

    // If negative, compute (2^64 - modVal) mod 2^64
    if (negative) {
        if (modVal !== '0') {
            modVal = subtractDecimalStrings(TWO_64_STR, modVal);
        }
    }

    // Now modVal is in [0..2^64-1].
    // Convert modVal (decimal) to a hex string (no leading zeros).
    let hex = decimalStringToHex(modVal);
    // Zero-pad to exactly 16 hex digits.
    while (hex.length < 16) {
        hex = '0' + hex;
    }
    return hex.toUpperCase();
}

/**
 * Returns (a % 2^64) as a decimal string in [0..2^64-1].
 */
function modDecimalStringBy64Bits(decString) {
    const TWO_64_STR = '18446744073709551616';
    if (compareDecimalStrings(decString, TWO_64_STR) < 0) {
        return decString;
    }
    return modDecimalStrings(decString, TWO_64_STR);
}

/**
 * Compares two non-negative decimal strings a,b.
 * Returns -1 if a<b, 0 if a==b, +1 if a>b.
 */
function compareDecimalStrings(a, b) {
    a = a.replace(/^0+/, '');
    b = b.replace(/^0+/, '');
    if (a.length < b.length) return -1;
    if (a.length > b.length) return 1;
    return a === b ? 0 : a < b ? -1 : 1;
}

/**
 * Returns a mod m (both as decimal strings).
 * Uses manual base-10 division to find the remainder.
 */
function modDecimalStrings(a, m) {
    let remainder = 0;
    const mInt = parseInt(m, 10);
    for (let i = 0; i < a.length; i++) {
        remainder = remainder * 10 + (a.charCodeAt(i) - 48);
        remainder = remainder % mInt;
    }
    return remainder.toString();
}

/**
 * Subtracts decimal strings a-b, assuming a >= b >= 0.
 * Returns the result as a string.
 */
function subtractDecimalStrings(a, b) {
    a = a.replace(/^0+/, '');
    b = b.replace(/^0+/, '');
    if (compareDecimalStrings(a, b) === 0) return '0';

    let carry = 0;
    let result = [];
    let i = 0;

    let A = a.split('').reverse();
    let B = b.split('').reverse();

    while (i < A.length || i < B.length) {
        let x = i < A.length ? A[i].charCodeAt(0) - 48 : 0;
        let y = i < B.length ? B[i].charCodeAt(0) - 48 : 0;
        let diff = x - y - carry;
        if (diff < 0) {
            diff += 10;
            carry = 1;
        } else {
            carry = 0;
        }
        result.push(diff);
        i++;
    }

    // Remove trailing zeros
    while (result.length > 1 && result[result.length - 1] === 0) {
        result.pop();
    }
    return result.reverse().join('');
}

/**
 * Converts a positive decimal string to a hexadecimal string (no leading zeros).
 */
function decimalStringToHex(decString) {
    if (decString === '0') return '0';
    let hex = '';
    let current = decString;
    while (compareDecimalStrings(current, '0') > 0) {
        let remainder = modDecimalStrings(current, '16'); // in [0..15]
        current = subtractDecimalStrings(current, remainder);
        current = divideDecimalStringBy16(current);
        hex = parseInt(remainder, 10).toString(16) + hex;
    }
    return hex;
}

/**
 * Integer division of a decimal string by 16.
 */
function divideDecimalStringBy16(decString) {
    let result = [];
    let val = 0;
    for (let i = 0; i < decString.length; i++) {
        val = val * 10 + (decString.charCodeAt(i) - 48);
        let digit = Math.floor(val / 16);
        val = val % 16;
        if (!(result.length === 0 && digit === 0)) {
            result.push(digit);
        }
    }
    return result.length ? result.join('') : '0';
}

/**
 * Performs XOR of three 64-bit hex strings (each 16 hex digits).
 * Returns a 16-digit hex string.
 */
function hexXor64(hex1, hex2, hex3) {
    let result = '';
    for (let i = 0; i < 16; i++) {
        const n1 = parseInt(hex1[i], 16);
        const n2 = parseInt(hex2[i], 16);
        const n3 = parseInt(hex3[i], 16);
        const r = (n1 ^ n2 ^ n3) & 0xf;
        result += r.toString(16).toUpperCase();
    }
    return result;
}

/**
 * Interprets a 64-bit hex string (16 hex characters) as a float64 (IEEE 754).
 */
function hex64ToFloat64(hexStr) {
    const high = parseInt(hexStr.slice(0, 8), 16);
    const low = parseInt(hexStr.slice(8, 16), 16);

    const buffer = new ArrayBuffer(8);
    const view = new DataView(buffer);
    // Big-endian
    view.setUint32(0, high, false);
    view.setUint32(4, low, false);

    return view.getFloat64(0, false);
}
