<template>
    <StickyScrollElement>
        <VirtualKickOffTimer :season-title="seasonTitle" is-dark />
    </StickyScrollElement>
</template>

<script>
import StickyScrollElement from '@/modules/sport/components/StickyScrollElement.vue';
import VirtualKickOffTimer from '@/modules/sport/components/Pages/Virtual/VirtualKickOffTimer.vue';

export default {
    name: 'VirtualStickyHeader',
    components: { VirtualKickOffTimer, StickyScrollElement },
    props: {
        seasonTitle: String,
    },
};
</script>
