const name = 'intersection-observer';
const handleIntersect = function (entries, observer) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            this.callback && this.callback();
            if (this.once) {
                observer.unobserve(entry.target);
            }
        }
    });
};

const Intersection = {
    name,
    install(Vue) {
        Vue.directive(name, {
            bind(el, binding) {
                el.intersect = new IntersectionObserver(handleIntersect.bind(binding.value || {}), binding.value?.options || {});
                el.intersect.observe(el);
            },

            unbind(el, binding) {
                if (el.intersect) {
                    el.intersect.unobserve(el);
                }
            },
        });
    },
};

export default Intersection;
