<template>
    <div class="landing-page">
        <section class="intro" />
        <div class="gallery">
            <h3>{{ $t('ui.landingPage.selectYourCountry') }}</h3>
            <div class="container">
                <a v-for="jurisdiction in activeJurisdictions" :key="jurisdiction.id" :href="jurisdiction.url" class="thumbnail">
                    <CountryFlag :iso-code="jurisdiction.countryCode" is-squared class="flag-icon" />
                    <p class="country-name">{{ jurisdiction.countryName }}</p>
                </a>
            </div>
        </div>
        <footer>
            <img src="@/assets/images/logo_monochrome.png" alt="logo" />
            <p>&copy; betPawa {{ copyRightYear }}. {{ $t('ui.common.copyRight') }}</p>
        </footer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { action, getter as platformGetter } from '@/modules/platform/store/const';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import CountryFlag from '@/modules/platform/components/CountryFlag.vue';

export default {
    name: 'LandingPage',
    components: { CountryFlag },
    mixins: [PageMixin, SEOMixin],
    data() {
        return {
            copyRightYear: new Date().getFullYear(),
        };
    },
    computed: {
        ...mapGetters({
            activeJurisdictions: platformGetter.GET_ACTIVE_JURISDICTIONS,
        }),
    },
    created() {
        this.$nextTick(() => {
            this.setSEOTags(
                {
                    meta: {
                        seo: {
                            template: 'landing',
                        },
                    },
                },
                { brandCountry: '' }
            );
        });
        this.$store.dispatch(action.GET_ALL_ACTIVE_JURISDICTIONS);
    },
};
</script>

<style scoped lang="scss">
.landing-page {
    background-color: #eee;
    overflow-y: auto;
    height: 100%;

    .flag-icon {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        box-shadow: 0 0 0 0.5px rgba(170, 174, 176, 0.5);
    }

    .intro {
        background-color: #575755;
        background-image: url(../../assets/images/backgrounds/my-betpawa.jpg);
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        min-height: 20%;

        @media (max-width: 796px) and (min-width: 320px) {
            min-height: 32%;
        }

        @media (min-width: 796px) {
            min-height: 50%;
        }

        @media (min-width: 129px) and (max-width: 320px) {
            height: 20%;
            min-height: 80px;
        }
    }

    .gallery {
        flex: 1;

        h3 {
            @extend %h3-font-700;
            text-align: center;
            margin: 10px 0 0 0;
            color: #636363;
        }

        .country-name {
            @extend %body-big-font-700;
            text-align: center;
            margin: 7px 0 5px;
        }

        .container {
            height: 100%;
            width: 100%;
            margin: 0 auto;
            max-width: 1480px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .thumbnail {
                width: 23%;
                text-align: center;
                margin-top: 15px;
                background-color: #fff;
                padding: 13px 0 10px;
                margin-left: 1%;
                margin-right: 1%;
                border-radius: 5px;

                @media (max-width: 796px) and (min-width: 320px) {
                    width: 46%;
                    margin-left: 2%;
                    margin-right: 2%;
                }

                @media (min-width: 796px) {
                    width: 23%;
                    margin-left: 1%;
                    margin-right: 1%;
                }

                @media (min-width: 129px) and (max-width: 320px) {
                    width: 80%;
                    margin-left: 10%;
                    margin-right: 10%;
                    margin-top: 15px;
                }
            }
        }
    }

    footer {
        padding: 60px 0 20px;
        text-align: center;
        p {
            color: $dark-grey-2;
            font-size: 13px;
            margin: 0 10px;
        }

        img {
            width: 100%;
            max-width: 90px;
        }
    }
}
</style>
