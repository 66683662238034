<template>
    <div v-if="itemsCount > 1" class="clear-all" @click="$emit('clearAll')">
        {{ $t('ui.eventFilters.clearAll') }}
    </div>
</template>

<script>
export default {
    name: 'ClearAllButton',
    props: {
        itemsCount: {
            type: Number,
            default: 0,
        }
    }
}
</script>

<style scoped lang="scss">
.clear-all {
    @extend %body-normal-font-500;
    flex-shrink: 0;
    white-space: nowrap;
    padding: 6px;
    cursor: pointer;
    text-decoration: underline;
    display: flex;
    align-items: center;
}
</style>
