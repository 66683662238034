/**
 * This function normalizes and processes a list of OTP (One-Time Password) options:
 * 1. Merges base options with user options (if any).
 * 2. Sorts the resulting options by their `position` property.
 * 3. Splits the options into two groups: available and limitReached (where `attemptsLeft === 0`).
 * 4. If there are more than two available options, marks the first one (by sorted order) as recommended.
 * 5. Returns a combined array: available options first, then limitReached options.
 *
 * @param {Array} otpOptions - The base OTP options.
 * @param {Object} [userOtpOptions={}] - User-defined options (keys are the `name` of the options).
 * @returns {Array} An array of all options, taking into account user overrides and the availability split.
 */
export function normalizeOtpOptions(otpOptions, userOtpOptions = {}) {
    const mergedOptions = otpOptions.map((option) => {
        const userOption = userOtpOptions[option.name];

        return userOption ? { ...option, ...userOption } : option;
    });

    const sortedOptions = [...mergedOptions].sort((a, b) => a.position - b.position);

    const processedOptions = sortedOptions.reduce(
        (acc, obj) => {
            const updatedObj = { ...obj };

            if (obj.attemptsLeft < 1) {
                updatedObj.limitReached = true;
                acc.limitReached.push(updatedObj);
            } else {
                acc.available.push(updatedObj);
            }

            return acc;
        },
        { available: [], limitReached: [] }
    );

    if (processedOptions.available.length > 2) {
        processedOptions.available[0].recommended = true;
    }

    return [...processedOptions.available, ...processedOptions.limitReached];
}

export function getLimitsForOtpOptions({ limit, otherLimits }) {
    const isSelectedLimitReached = limit.attemptsLeft < 1;

    const isGlobalLimitReached = isSelectedLimitReached && Object.values(otherLimits).every(({ attemptsLeft }) => attemptsLeft <= 0);

    return { isSelectedLimitReached, isGlobalLimitReached };
}
