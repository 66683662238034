<template>
    <div class="search">
        <div class="search-container">
            <input
                ref="searchInput"
                type="text"
                data-test-id="casinoSearchInput"
                class="search-input"
                :placeholder="$t('ui.casinoLobby.search.placeholder')"
                :value="value"
                @focus="$emit('focus')"
                @blur="$emit('blur')"
                @input="onInput"
            />
            <SvgIcon
                class="clear-button icon-size-medium"
                data-test-id="clearSearchInput"
                icon-id="icon-cross-circled-fill"
                vertical-align="top"
                @click.native="clearInput"
            />
        </div>
        <p class="input-hint">{{ $t('ui.casinoLobby.search.inputHint') }}</p>
    </div>
</template>

<script>
import { helper } from '@/modules/core';

const DEBOUNCE_INPUT_TIMEOUT = 1000;

export default {
    name: 'CasinoSearchInput',
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    mounted() {
        this.focus();
    },
    created() {
        this.emitInput = helper.debounce((value) => {
            this.$emit('input', value);
        }, DEBOUNCE_INPUT_TIMEOUT);
    },
    methods: {
        clearInput() {
            this.$emit('input', '');
        },
        onInput(event) {
            this.emitInput(event.target.value);
        },
        focus() {
            this.$refs.searchInput.focus();
        },
    },
};
</script>

<style scoped lang="scss">
.search {
    padding: 0 12px;

    &-container {
        display: flex;
        align-items: center;
        background-color: $light-grey;
        border-radius: 8px;
        padding: 10px 12px;
        width: 100%;
        position: relative;

        .search-input {
            flex: 1;
            border: none;
            padding: 0;
            @extend %small-details-font-400;
            background: transparent;
            color: $main-text;
            outline: none;

            &::placeholder {
                color: $dark-grey-2;
            }
        }

        .clear-button {
            fill: $dark-grey-2;
        }
    }

    .input-hint {
        @extend %small-details-font-400;
        color: $neutral-medium;
        margin-top: 2px;
    }
}
</style>
