<template>
    <transition name="slide">
        <div
            v-if="isSideBarOpen"
            class="side-bar content"
            :style="{ width }"
            :class="[
                className,
                right && 'side-bar-right',
                isSideBarOpen ? 'open' : 'close',
                {
                    overlap: isOverlaps,
                    transition: isReady,
                    'full-screen': isFullScreen,
                    'side-bar-with-delay': hasTransitionDelay,
                },
            ]"
        >
            <slot />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'SideBar',
    props: {
        className: {
            type: String,
            required: false,
        },
        buttonClassName: {
            type: [String],
            required: false,
            default: 'side-bar-button',
        },
        isMobile: {
            type: Boolean,
            required: false,
            default: false,
        },
        isOverlaps: {
            type: Boolean,
            required: false,
            default: false,
        },
        isOpen: {
            type: Boolean,
            required: false,
        },
        canClose: {
            type: Boolean,
            required: false,
            default: true,
        },
        right: {
            type: Boolean,
            required: false,
            default: false,
        },
        width: {
            type: [String],
            required: false,
        },
        toolbar: {
            type: Boolean,
            required: false,
            default: false,
        },
        isFullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasTransitionDelay: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isReady: false,
            isSideBarOpen: false,
        };
    },
    watch: {
        isOpen: {
            deep: true,
            immediate: true,
            handler(newValue, oldValue) {
                if (!oldValue && newValue) {
                    this.setOpen(true);
                }
                if (oldValue && !newValue) {
                    this.setOpen(false);
                }
            },
        },
    },
    mounted() {
        this.isReady = !this.isReady;
    },
    methods: {
        closeMenuOnEsc(e) {
            e = e || window.event;
            if ((e.key === 'Escape' || e.keyCode === 27) && this.isMobile) {
                this.setOpen(false);
            }
        },
        setOpen(isOpen) {
            this.isSideBarOpen = isOpen;
            this.$emit('isOpen', isOpen);
        },
    },
};
</script>

<style lang="scss" scoped>
.toolbar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .cross-style {
        cursor: pointer;
    }

    .bm-cross-button {
        align-self: flex-end;
        height: 24px;
        width: 24px;

        .bm-cross {
            background: $sideBar-toolbar-cross-background;
        }
    }
}

.side-bar {
    width: 100%;
    overflow: auto;
    position: relative;
    background: $white-bg;
    transition-property: width, -webkit-transform;
    transition-property: transform, width;
    transition-property: transform, width, -webkit-transform;
    transform: translateX(0);

    @include hide_scrollbar;

    &.slide-enter,
    &.slide-leave-to {
        transform: translateX(-100%);
    }

    &-right {
        right: 0;

        &.slide-enter,
        &.slide-leave-to {
            transform: translateX(100%);
        }
    }

    &.overlap {
        position: fixed;
        height: 100%;
        z-index: 10000;

        &.open {
            @include mq-md-up {
                box-shadow: 5px 0 8px rgba(0, 0, 0, 0.33);
            }
        }

        &.full-screen {
            height: 100%;
            top: 0;
        }
    }

    &.transition.overlap {
        transition: transform 0.35s ease-in;
        &.side-bar-with-delay {
            transition-delay: 0.3s;

            &.close,
            &.slide-enter,
            &.slide-leave-to {
                transition-delay: 0s;
            }
        }
    }
}

.content {
    overflow-x: hidden;
    background-color: $white-bg;
    height: 100%;
}

h3 {
    display: block;
}
</style>
