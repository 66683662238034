<template>
    <span class="radio-button">
        <input :id="id" class="radio-button-input" type="radio" :checked="checked" :disabled="disabled" />

        <label :class="['radio-button-label', { disabled }]" :for="id" @click.stop>
            <SvgIcon class="icon-size-medium" :icon-id="iconId" :vertical-align="iconVerticalAlign" />
        </label>
    </span>
</template>

<script>
export default {
    name: 'RadioButton',
    props: {
        id: {
            type: String,
            default: 'radiobutton',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: 'light',
        },
        iconVerticalAlign: {
            type: String,
            default: 'text-top',
        },
    },
    data: () => ({
        isChecked: false,
    }),
    computed: {
        iconId() {
            return this.checked ? 'icon-radio-circle-selected' : 'icon-radio-circle';
        },
    },
    mounted() {
        this.isChecked = this.checked;
    },
};
</script>

<style lang="scss" scoped>
.radio-button {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.radio-button-label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin: 0;

    svg {
        color: $dark-grey-2;
    }

    &.disabled {
        svg {
            color: $disabled-bg;
            cursor: default;
        }
    }
}

.radio-button-input {
    display: none;
}
</style>
