<template>
    <div class="recent-searches">
        <p class="recent-title">{{ $t('ui.casinoLobby.search.recentSearches') }}</p>
        <ul class="search-list">
            <li
                v-for="(search, index) in recentSearches"
                :key="index"
                :data-test-id="`selectRecentSearch-${index}`"
                class="search-item"
                @click="onSelect(search)"
            >
                <span class="search-icon"><SvgIcon class="icon-size-medium" icon-id="icon-recent" /></span>
                <span class="search-text">{{ search }}</span>
                <span data-test-id="removeRecentSearch" class="remove-search" @click.stop="onRemove(search)">
                    <SvgIcon class="icon-size-little" icon-id="icon-close" />
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'CasinoRecentSearches',
    props: {
        recentSearches: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        onSelect(search) {
            this.$emit('select', search);
        },
        onRemove(search) {
            this.$emit('remove', search);
        },
    },
};
</script>

<style scoped lang="scss">
.recent-searches {
    margin-top: 16px;
    position: absolute;
    left: 0;
    padding: 0 12px;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: $white-bg;

    .recent-title {
        @extend %body-normal-font-700;
        color: $neutral-medium;
        margin-bottom: 10px;
    }

    .search-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .search-item {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        cursor: pointer;

        .search-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                fill: $dark-grey-2;
            }
        }

        .search-text {
            flex: 1;
            @extend %body-normal-font-400;
            color: $main-text;
        }

        .remove-search {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                fill: $dark-green;
            }
        }
    }
}
</style>
