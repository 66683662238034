<template>
    <div>
        <div v-if="!marketUnavailable" class="event-bets">
            <div class="chunks">
                <div v-for="(chunk, chunkIndex) in pricesChunks" :key="`chunk-${eventId}-${chunkIndex}`" class="chunk">
                    <BetPrice
                        v-for="(price, index) in chunk"
                        :key="`${eventId}-${index}`"
                        :price="price"
                        :is-locked="false"
                        :selected="price && isInBetslip(Number(price.id))"
                        :is-event-route="isEventRoute"
                        :is-multiline="isMultiline"
                        :market-type-id="String(market.marketType.id)"
                        :is-hot="price && isHot(price.additionalInfo.hot)"
                        class="bet-price"
                        @onPriceClick="toggleBet(price, market)"
                    />
                    <slot :chunk-index="chunkIndex" />
                </div>
            </div>
        </div>
        <div v-else class="event-bets">
            <div class="chunks">
                <div class="chunk">
                    <BetPrice
                        v-for="(item, index) in lockedList"
                        :key="`locked-item-${index}`"
                        :price="item"
                        :is-locked="true"
                        class="bet-price"
                    />
                    <slot :chunk-index="0" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { helper } from '@/modules/core';
import { betslipType, SPORT_CATEGORY_MAP } from '@/modules/sport';
import { mutation as sportMutation } from '@/modules/sport/store/const';
import { action as betslipAction, getter as betslipGetter, mutation as betslipMutation } from '@/modules/sport/store/modules/betslip/const';
import { getter as platformGetter } from '@/modules/platform/store/const';

import BetPrice from './BetPrice.vue';
import { routeName } from '@/router/const-name';
import { priceSortingStrategy } from '@/modules/sport/utils/event/priceSortingStrategy';
import MQConstants from '@/js/directives/media-query/MediaQuery-const';

export default {
    name: 'Betline',
    components: { BetPrice },
    props: {
        rawDate: String,
        market: Object,
        fullname: String,
        eventId: Number,
        sportId: Number,
        isLive: Boolean,
        exId: Number,
        type: {
            type: String,
            default: betslipType.REGULAR,
            validator: (type) => {
                return [betslipType.REGULAR, betslipType.VIRTUAL].indexOf(type) !== -1;
            },
        },
        season: {
            type: Object,
            default: () => ({}),
        },
        availableOpenBetslip: {
            type: Boolean,
            default: false,
        },
        marketUnavailable: {
            type: Boolean,
            default: false,
        },
        isFirstTab: {
            type: [Boolean, undefined],
        },
    },
    computed: {
        ...mapState({
            betslipStatus: (state) => state.sport.betslip.regular.status, // getter BP-16141
            virtualBetslipStatus: (state) => state.sport.betslip.virtual.status, // getter BP-16141
            selectedPriceId: (state) => state.sport.betslip.selectedPriceId,
            containsHot: (state) => state.sport.containsHot,
            currentSeason: (state) => state.sport.betslip.virtual.season, // getter BP-16141
        }),
        ...mapGetters({
            betslip: betslipGetter.GET_SELECTED_BETSLIP,
            selectionIds: betslipGetter.GET_SELECTION_IDS_BY_TYPE,
            minimumOddsForBonus: platformGetter.GET_MINIMUM_ODDS_FOR_BONUS,
        }),
        showOpenBetslip() {
            return this.market?.prices.find((price) => Number(price.id) === this.selectedPriceId);
        },
        lockedList() {
            const { markets } = SPORT_CATEGORY_MAP.find(({ key }) => key === this.sportId) || {};
            const needMap = typeof this.isFirstTab === 'undefined' || this.isFirstTab;
            return (needMap && markets) || ['placeholder'];
        },
        isEventRoute() {
            return this.$route.name === routeName.EVENT;
        },
        isVirtual() {
            return this.type === betslipType.VIRTUAL;
        },
        prices() {
            const unOrderedPrices = [...(this.market.prices || [])];
            return unOrderedPrices.sort(priceSortingStrategy[this.priceSortingMethod] || priceSortingStrategy.DEFAULT);
        },
        priceSortingMethod() {
            return this.market.marketType.priceSortingMethod;
        },
        pricesChunkSize() {
            return this.market.marketType.priceColumnCount;
        },
        pricesChunks() {
            return helper.chunk(this.prices, this.pricesChunkSize, true);
        },
        isMultiline() {
            const columnCount = this.pricesChunkSize;
            const BRACKET_LENGTH = 3;

            const longestPriceName = this.market.prices.reduce((maxLength, price) => {
                const priceNameLength = price.name.length + (price.handicap ? price.handicap.length + BRACKET_LENGTH : 0);
                return Math.max(maxLength, priceNameLength);
            }, 0);

            const conditions = [
                { sizes: [MQConstants.SM, MQConstants.MD, MQConstants.LG], columnCount: 3, maxLength: 9 },
                { sizes: [MQConstants.XS], columnCount: 3, maxLength: 4 },
                { sizes: [MQConstants.XS], columnCount: 2, maxLength: 9 },
                { sizes: [MQConstants.XXS], columnCount: 3 },
                { sizes: [MQConstants.XXS], columnCount: 2, maxLength: 9 },
            ];
            for (let condition of conditions) {
                const sizeMatches = condition.sizes.includes(this.$mq.size);
                const maxLengthCondition = condition.maxLength === undefined || longestPriceName > condition.maxLength;
                const columnCountCondition = condition.columnCount === undefined || columnCount === condition.columnCount;

                if (sizeMatches) {
                    return maxLengthCondition && columnCountCondition;
                }
            }
            return true;
        },
    },
    beforeDestroy() {
        if (this.containsHot) {
            this.$store.commit(sportMutation.CONTAINS_HOT, false);
        }
    },
    methods: {
        ...mapMutations({
            setSeason: betslipMutation.SET_VIRTUAL_BETSLIP_SEASON,
        }),
        isInBetslip(targetId) {
            return this.selectionIds(this.type).includes(targetId) && !this.betslip.status.Placed;
        },
        toggleBet(price, market) {
            if (price.suspended) return;
            const [splitMarketTypeName] = this.market.marketType.name.split(' - ');
            this.$emit('select:price', price);
            if (this.isRemoveAllBet()) {
                this.$store.dispatch(betslipAction.REMOVE_ALL_BETS, this.type); // remove param BP-16141
            }
            if (this.isVirtual && !this.currentSeason.id) {
                this.setSeason(this.season);
            }
            this.$store.dispatch(betslipAction.TOGGLE_BET, {
                marketPrices: market.prices.map(({ id }) => id),
                rawDate: this.rawDate,
                market: {
                    name: this.market.marketType.name,
                    displayName: this.market.marketType.displayName,
                    groupName: splitMarketTypeName,
                    groupedMarketName: this.market.marketType.groupedName,
                    handicapType: this.market.handicapType,
                    cashoutable: this.market.additionalInfo.cashoutable,
                    twoUp: this.market.additionalInfo.twoUp,
                },
                price: {
                    Id: Number(price.id),
                    Name: price.name,
                    Price: this.$numberFormat(price.price),
                    PriceRaw: price.price,
                    EligibleForBonus: price.price >= this.minimumOddsForBonus,
                    Hcp: price.handicap,
                    probability: price.probability,
                },
                name: this.fullname,
                eventId: this.eventId,
                exId: this.exId,
                isLive: this.isLive,
                type: this.type,
            });
            this.$gtm.query({ event: 'bet_item_selected', fullName: this.fullname });
        },
        isRemoveAllBet() {
            return (this.isVirtual && this.virtualBetslipStatus.Placed) || (!this.isVirtual && this.betslipStatus.Placed);
        },
        isHot(value) {
            if (!this.containsHot && value) {
                this.$store.commit(sportMutation.CONTAINS_HOT, value);
            }
            return value;
        },
        $numberFormat: helper.numberFormat,
    },
};
</script>

<style lang="scss" scoped>
.event-bets {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex: 1;
    line-height: 16px;
    text-align: center;
    margin: 0 -4px;
    flex-wrap: nowrap;
}

.event-bets-line {
    margin: 5px 0 7px;
}

.chunks {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

.chunk {
    display: flex;
    flex-grow: 1;
    width: 100%;
}
.bet-price {
    &-size-3 {
        width: 33.3%;
    }
    &-size-2 {
        width: 50%;
    }
    &-size-1 {
        width: 100%;
    }
}
</style>
