<template>
    <div v-if="message" class="site-message">
        <div class="site-message-close-wrapper">
            <SvgIcon icon-id="icon-close" class="site-message-close" vertical-align="top" @click.native="onCloseClick" />
        </div>

        <div v-if="messageIcon" class="site-message-icon-wrapper">
            <SvgIcon :icon-id="messageIcon" class="site-message-icon icon-message" />
        </div>

        <div class="site-message-body">
            <div class="site-message-date">{{ formatDateTime(message.startTime) }}</div>
            <div class="site-message-text">{{ message.messageText }}</div>
            <div v-if="message.ctaLink && message.ctaText" class="site-message-action-wrapper">
                <LinkWrapper class="button button-primary site-message-action" :link="message.ctaLink" @clicked="onCtaClick">
                    {{ message.ctaText }}
                </LinkWrapper>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { helper } from '@/modules/core';
import { MESSAGE_ICONS_MAP } from '@/modules/platform';
import { getter as platformGetter } from '@/modules/platform/store/const';
import LinkWrapper from './LinkWrapper';

export default {
    name: 'OnSiteMessage',
    components: { LinkWrapper },
    props: ['message'],
    computed: {
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS,
            country: platformGetter.GET_COUNTRY,
        }),
        messageIcon() {
            return this.message?.messageType ? MESSAGE_ICONS_MAP[this.message.messageType] : null;
        },
    },
    mounted() {
        this.$gtm.query({
            event: 'on_site_message_appearance',
            jurisdiction: this.country,
        });
    },
    methods: {
        formatDateTime(timestamp) {
            const { day, date, month, time } = helper.formatDateTime(timestamp, {
                toObject: true,
                ...this.dateOptions,
            });
            return `${time} ${day} ${date}/${month}`;
        },
        onCloseClick() {
            this.$gtm.query({
                event: 'on_site_message_close_click',
            });

            this.$emit('onCloseClick', this.message.id);
        },
        onCtaClick() {
            this.$gtm.query({
                event: 'on_site_message_cta_click',
            });

            this.$emit('onCtaClick', this.message.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.site-message {
    display: flex;
    width: 100%;
    position: relative;
    padding: 12px;
    background: $light-grey-bg;

    &-date {
        font-size: 12px;
        line-height: 14px;
        margin-top: 6px;
        color: $disabled-text;
    }

    &-text {
        font-size: 14px;
        line-height: 20px;
        color: $main-text;
        margin-top: 4px;
    }

    &-icon-wrapper {
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 8px;
    }

    &-icon {
        margin-top: 4px;
        width: 37px;
        height: 37px;

        @include mq-xxs {
            width: 27px;
            height: 27px;
        }
    }

    &-body {
        flex-grow: 1;
    }

    &-action-wrapper {
        margin-top: 12px;
        text-align: right;
    }

    &-action {
        line-height: 17px;
        padding: 6.5px 12px;
    }

    &-close-wrapper {
        position: absolute;
        top: 12px;
        right: 12px;
        line-height: 12px;
    }

    &-close {
        width: 12px;
        height: 12px;
        cursor: pointer;
        fill: $disabled-text;
    }
}
</style>
