/**
 * Replicates the functionality of CutPlusCalculator.calculate in JavaScript
 *
 * @param {object} bet - Expected shape:
 *   {
 *     stake: number,
 *     winBonusPercentage: number,
 *     items: Array<{
 *       price: number,
 *       winProbability: number,
 *       voidProbability?: number | null
 *     }>
 *   }
 *
 * @param {object} configuration - Expected shape:
 *   {
 *     evCurveSteepness: number,
 *     evCurveReductionFactor: number,
 *     minimumOfferAmount: number,
 *     minimumHouseEV: number,
 *     minimumPercentageOfStakeForCut1: number,
 *     minimumPercentageOfStakeForCut2Plus: number,
 *     maxCutsForPreferredOffer: number
 *   }
 *
 * @returns {object} - An object mirroring JSAPIResponse:
 *   {
 *     hasError: boolean,
 *     errorText: string | null,
 *     modelRevision: number | null,
 *     allOffers: Array<{
 *       maxCuts: number,
 *       preferred: boolean,
 *       amountForEachCut: Array<{ misses: number, offeredAmount: number }>
 *     }>
 *   }
 */
const CUT_PLUS_MODEL_REVISION = 5;

/* eslint-disable-next-line complexity */
export function calculateCutPlus(bet, configuration) {
    // ==============================
    // 1. Validate inputs
    // ==============================

    if (typeof bet !== 'object' || bet === null) {
        return {
            hasError: true,
            errorText: 'Invalid bet: bet must be a non-null object.',
            modelRevision: CUT_PLUS_MODEL_REVISION,
            offerWithMostCuts: [],
            allOffers: [],
        };
    }

    const { stake, winBonusPercentage, items } = bet;
    if (typeof stake !== 'number' || typeof winBonusPercentage !== 'number' || !Array.isArray(items)) {
        return {
            hasError: true,
            errorText: 'Invalid bet properties: stake and winBonusPercentage must be numbers; items must be an array.',
            modelRevision: CUT_PLUS_MODEL_REVISION,
            offerWithMostCuts: [],
            allOffers: [],
        };
    }

    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (
            typeof item.price !== 'number' ||
            typeof item.winProbability !== 'number' ||
            (item.voidProbability !== null && item.voidProbability !== undefined && typeof item.voidProbability !== 'number')
        ) {
            return {
                hasError: true,
                errorText: `Invalid bet.items[${i}] properties: price and winProbability must be numbers; voidProbability must be null, undefined, or a number.`,
                modelRevision: CUT_PLUS_MODEL_REVISION,
                offerWithMostCuts: [],
                allOffers: [],
            };
        }
    }

    if (typeof configuration !== 'object' || configuration === null) {
        return {
            hasError: true,
            errorText: 'Invalid configuration: must be a non-null object.',
            modelRevision: CUT_PLUS_MODEL_REVISION,
            offerWithMostCuts: [],
            allOffers: [],
        };
    }

    const {
        evCurveSteepness,
        evCurveReductionFactor,
        minimumOfferAmount,
        minimumHouseEV,
        minimumPercentageOfStakeForCut1,
        minimumPercentageOfStakeForCut2Plus,
    } = configuration;

    if (
        typeof evCurveSteepness !== 'number' ||
        typeof evCurveReductionFactor !== 'number' ||
        typeof minimumOfferAmount !== 'number' ||
        typeof minimumHouseEV !== 'number' ||
        typeof minimumPercentageOfStakeForCut1 !== 'number' ||
        typeof minimumPercentageOfStakeForCut2Plus !== 'number'
    ) {
        return {
            hasError: true,
            errorText: 'Invalid configuration properties: all fields must be numbers.',
            modelRevision: CUT_PLUS_MODEL_REVISION,
            offerWithMostCuts: [],
            allOffers: [],
        };
    }

    // ==============================
    // 2. Inlined "getIntermediateCalculations"
    // ==============================

    // (1) Calculate combinedOdds = product of all item prices
    let combinedOdds = 1.0;
    for (let i = 0; i < items.length; i++) {
        combinedOdds *= items[i].price;
    }

    // (2) Calculate betWinProbability = product of all adjusted item probabilities
    // Adjusted probability = (if no voidProbability) => 1 / price
    //                      = (if voidProbability exists) => winProb + voidProb / price
    let betWinProbability = 1.0;
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        let p;
        if (item.voidProbability === null || item.voidProbability === undefined) {
            p = 1.0 / item.price;
        } else {
            p = item.winProbability + item.voidProbability / item.price;
        }
        betWinProbability *= p;
    }

    // (3) Calculate totalOdds (including win bonus)
    const winBonusOdds = winBonusPercentage * (combinedOdds - 1.0);
    const totalOdds = combinedOdds + winBonusOdds;

    // (4) House EV pre-CutPlus
    const houseEVPreCutPlus = 1.0 - totalOdds * betWinProbability;

    // (5) Percentage of stake available for CutPlus before min EV constraint
    let percentageOfStakeAvailableForCutPlusBeforeMinEV;
    if (houseEVPreCutPlus < 0) {
        percentageOfStakeAvailableForCutPlusBeforeMinEV = 0;
    } else {
        percentageOfStakeAvailableForCutPlusBeforeMinEV = Math.log10(evCurveSteepness * houseEVPreCutPlus + 1.0) / evCurveReductionFactor;
    }

    // (6) House EV post-CutPlus (before ensuring minimumHouseEV)
    const houseEVPostCutPlusBeforeMinEV = houseEVPreCutPlus - percentageOfStakeAvailableForCutPlusBeforeMinEV;

    // (7) Adjust to ensure minimumHouseEV
    let percentageOfStakeAvailableForCutPlus;
    if (houseEVPostCutPlusBeforeMinEV < minimumHouseEV) {
        // only allow as much as houseEVPreCutPlus - minimumHouseEV
        const possible = houseEVPreCutPlus - minimumHouseEV;
        percentageOfStakeAvailableForCutPlus = Math.max(0, possible);
    } else {
        percentageOfStakeAvailableForCutPlus = percentageOfStakeAvailableForCutPlusBeforeMinEV;
    }

    // (8) Final available amount for CutPlus
    const availableForCutPlus = stake * percentageOfStakeAvailableForCutPlus;
    // ==============================
    // 3. Calculate minimum offer amounts
    // ==============================
    const cut1MinAmount = Math.max(0, minimumOfferAmount, minimumPercentageOfStakeForCut1 * stake);
    const cut2PlusMinAmount = Math.max(0, minimumOfferAmount, minimumPercentageOfStakeForCut2Plus * stake);

    // ==============================
    // 4. Build itemProbabilities (for Bernoulli distribution)
    // ==============================
    const itemProbabilities = items.map((item) => {
        if (item.voidProbability === null || item.voidProbability === undefined) {
            return 1.0 / item.price;
        } else {
            return item.winProbability + item.voidProbability / item.price;
        }
    });

    // ==============================
    // 5. Compute distribution of "exactly i successes" for i=0..n
    //    (Equivalent to BernoulliDistribution.calculateLikelihoods)
    // ==============================
    const n = itemProbabilities.length;
    const hitProbabilities = [];
    for (let successes = 0; successes <= n; successes++) {
        // dynamic programming
        const f = new Array(successes + 1).fill(0);
        f[0] = 1.0;
        for (let i = 0; i < n; i++) {
            const p = itemProbabilities[i];
            for (let j = Math.min(i + 1, successes); j > 0; j--) {
                f[j] = f[j] * (1 - p) + f[j - 1] * p;
            }
            f[0] *= 1 - p;
        }
        hitProbabilities.push(f[successes]);
    }

    // Probability of (k - i) misses is hitProbabilities[i], so reversing gives p(0 misses), p(1 miss), ...
    const missProbabilities = [...hitProbabilities].reverse();

    // ==============================
    // 6. Generate offers based on # of max cuts
    // ==============================
    const offers = [];
    for (let maxCuts = 1; maxCuts < items.length; maxCuts++) {
        // subList(1, maxCuts+1) => slice(1, maxCuts+1)
        const relevantMissProbs = missProbabilities.slice(1, maxCuts + 1);

        // For each probability => offered amount = floor(prob-safe division * 100) / 100
        const cutList = relevantMissProbs.map((prob) => {
            const divisor = maxCuts * prob;
            const adjustmentFactor = 1 / divisor;
            const amt = adjustmentFactor * availableForCutPlus;
            return Math.floor(amt * 100) / 100; // round down to 2 decimals
        });

        // Check if first cut >= cut1MinAmount and subsequent >= cut2PlusMinAmount
        if (cutList[0] >= cut1MinAmount && cutList.slice(1).every((amount) => amount >= cut2PlusMinAmount)) {
            // Build a CutPlusOffer-like object
            const amountForEachCut = cutList.map((value, index) => ({
                misses: index + 1,
                offeredAmount: value,
            }));
            offers.push({ maxCuts, preferred: false, amountForEachCut });
        }
    }

    if (offers.length > 0) {
        let maxObj = offers[0];
        for (let i = 1; i < offers.length; i++) {
            if (offers[i].maxCuts > maxObj.maxCuts && offers[i].maxCuts <= configuration.maxCutsForPreferredOffer) {
                maxObj = offers[i];
            }
        }
        maxObj.preferred = true;
    }

    // ==============================
    // 8. Return final result
    // ==============================
    return {
        hasError: false,
        errorText: null,
        modelRevision: CUT_PLUS_MODEL_REVISION,
        allOffers: offers,
    };
}
